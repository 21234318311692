<template>

	<div id="notfound">
		<div class="notfound">
			<img src="../assets/check.png" alt="" style="width:50%;padding-bottom: 20px;">
      <br>

 
            <h1 style="color:#0ea2bd;" >Success! Food is on your way</h1>
			
      <p style="font-size:20px; color:gray;">Check out details to get more information about delivery.</p><br>

      <template v-if="form.vrsta=='restoran'">
        <button  @click="$router.push('/delivery/')" class="btn btn-primary" style="border:none">Continue</button>

      </template>

     
    
		</div>
	</div>


</template>

<script>
import axios from 'axios'
export default {
  name: 'Info',
  data(){
    return{
      email:"",
      sat:"",
      datum:"",
      cartid:"",
      vrsta:"",
      platform:"",
      qnt:"",
      form:{
        ime:"",
        prezime:"",
        telefon:"",
        email:"",
        adresa:"",
        broj:"",
        grad:"",
        zipkod:"",
        drzava:"",
        vrsta:"",
        cartid:"",
        qnt:""
      }
    }
  },
  mounted(){
    console.log(this.$route.params)

    this.form.qnt = this.$route.query.qnt;
    this.form.ime = this.$route.query.CustomerFirstname;
    this.form.prezime = this.$route.query.CustomerSurname;
    this.form.adresa = this.$route.query.CustomerAddress;
    this.form.vrsta = this.$route.params.vrsta;
    console.log(this.form.vrsta)
    this.form.drzava = this.$route.query.Lang
    this.form.zipkod = this.$route.query.CustomerZIP
    this.form.grad = this.$route.query.CustomerCity
    this.form.email = this.$route.query.CustomerEmail
    this.form.telefon = this.$route.query.CustomerPhone

    console.log(this.form)
    this.platform = this.$route.params.platform;
    this.form.qnt = this.$route.params.qnt;
    this.form.cartid = this.$route.query.ShoppingCartID;


    this.emailposalji()
    



  },
  methods:{
    async emailposalji(){
        this.buttonDisabled = true
        
          var apilink = '/placanje/sportplaceno/'+this.form.vrsta+'/'
        
          axios.post(apilink, this.form)
                   .then((res) => {
                    
                    if(this.form.vrsta=="restoran"){
                  this.$root.toastnoti(this.$t('success'), "Press continue to see details of your delivery in app", "uspjeh")
                }
                else{
                  this.$root.toastnoti(this.$t('success'), "Email with more info sent", "uspjeh")
                }
                   })
                   .catch((error) => {
                 
                   })
     
  
      },

  }

  
  
}
</script>
<style scoped>

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1>span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search>input {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search>input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search>button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search>button>span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}


@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}


</style>