<template><br>

    <div>
      <Navigation yvisina=-200 @reload-parent="methodThatForcesUpdate">
        <template #atag>
          <a></a>
      
        </template>
        <template #content>

          <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con  text-center wow bounceInUp" data-wow-duration="1s">
              <p class="position-relative oasisnaslov text-white" style="    font-size: 0.5rem !important;font-weight: 500 !important;">
             
                </p>
           
             
               <h1 class="position-relative lead oasisnaslov text-white" style="font-size: 2.5rem !important;font-weight: 700 !important;">Hairdresser</h1>
               <p class="position-relative oasisnaslov text-white" style="    font-size: 1.25rem;font-weight: 700 !important;"><i class="fa fa-location-dot"> </i>&nbsp;Oasis Wellnes & Spa<br><br>
              
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;"  @click="this.$root.scrollToSection('rez')">Your Reservations</button></p>
               


            </div>
         </div>
      </div>
        

   </section>
<br><br>


      
          <div class="darken-image  image-container">
        
       
</div>

      <section class="menu">
        <div class="container">
        <div class="row">
        <div class="col-md-12">
      
        </div>
        </div>
        <div class="food-menu wow fadeInUp">
        <div class="row">
        <div class="col-md-12"> 
    
        <div class="menu-tags" style="padding-left: 20px;padding-right: 20px;">
          <select class="form-select" aria-label="Hairdresser" @change="filter($event.target.value)" >
     
    <option value="21">MEN'S HAIRCUT - Clippers</option>
    <option value="22">MEN'S CLASSIC HAIRCUT</option>
    <option value="23">CHILDREN'S HAIRCUT</option>
    <option value="24">WOMEN'S SHORT HAIRCUT</option>
    <option value="25">WOMEN'S MEDIUM LENGTH HAIRCUT</option>
    <option value="26">WOMEN'S LONG HAIRCUT</option>
    <option value="27">HAIR COLORING</option>
    <option value="28">LONG HAIR COLORING</option>
    <option value="29">HIGHLIGHTS</option>
    <option value="30">LONG HAIR HIGHLIGHTS</option>
    <option value="31">HAIR BLOWOUT</option>
    <option value="32">NOURISHING SCALP TREATMENT</option>
    <option value="33">NOURISHING HAIR MASK</option>
    <option value="34">SCALP MASSAGE (15 minutes)</option>
    <option value="35">HAIR WASH</option>
    <option value="36">HAIR PACKAGE (wash, haircut, blowout)</option>

</select>
<!--
          <span  id="21" class=" kategorija"  @click="filter(21)"><br><span>MEN'S HAIRCUT - Clippers</span></span>
        <span id="22" class=" kategorija" @click="filter(22)"><br><span >MEN'S CLASSIC HAIRCUT</span></span>
        <span id="23" class=" kategorija" @click="filter(23)"><br><span >CHILDREN'S HAIRCUT</span></span>
        <span id="24" class=" kategorija" @click="filter(24)"><br><span >WOMEN'S SHORT HAIRCUT</span></span>
        <span id="25" class=" kategorija" @click="filter(25)"><br><span >WOMEN'S MEDIUM LENGTH HAIRCUT</span></span>
        <span id="26" class=" kategorija" @click="filter(26)"><br><span >WOMEN'S LONG HAIRCUT</span></span>
        <span id="27" class=" kategorija" @click="filter(27)"><br><span >HAIR COLORING</span></span>
        <span id="28" class=" kategorija" @click="filter(28)"><br><span >LONG HAIR COLORING</span></span>
        <span id="29" class=" kategorija" @click="filter(29)"><br><span >HIGHLIGHTS</span></span>
        <span id="30" class=" kategorija" @click="filter(30)"><br><span >LONG HAIR HIGHLIGHTS</span></span>
        <span id="31" class=" kategorija" @click="filter(31)"><br><span >HAIR BLOWOUT</span></span>
        <span id="32" class=" kategorija" @click="filter(32)"><br><span >NOURISHING SCALP TREATMENT</span></span>
        <span id="33" class=" kategorija" @click="filter(33)"><br><span >NOURISHING HAIR MASK</span></span>
        <span id="34" class=" kategorija" @click="filter(34)"><br><span >SCALP MASSAGE (15 minutes)</span></span>
        <span id="35" class=" kategorija" @click="filter(35)"><br><span >HAIR WASH</span></span>
        <span id="36" class=" kategorija" @click="filter(36)"><br><span >HAIR PACKAGE (wash, haircut, blowout)</span></span>
-->
        </div>

        </div>
    
        </div>
<br>
        <div class="row menu-items">
            <div :class="i.kategorija + ' card col-md-4 sve'" v-for="(i, key) in info">
  <img class="card-img-top" :src="i.img_path" alt="Card image cap" >
  <div class="card-body">
    <h5 class="card-title" > <span class="card-text vrsta" style="padding-right:5px;">{{i.vrsta}} </span> {{i.naslov}}</h5>
    <p class="card-text" v-html="i.kratakopis"></p>
    <p style="color:gray;opacity:0.8;cursor: pointer;" :id="'vise'+key.id" @click="viseopisa(key)">{{ $t('showmore') }}</p>
    <p class="card-text" :class="'opis'+key" style="display:none" v-html="i.opis"></p>
    <p class="card-text" :class="'opis'+key" style="display:none;font-weight:600;font-size: 16px;" v-html="i.dodatno"></p>
    

  </div>
</div>



</div>
        
        

        </div>
        </div>
        </section>
      
      <div class="container">
      <form style="padding-left:50px;padding-right:50px;" v-on:submit.prevent="getSport">
        <input type="date" class="form-control" name="date" :min="new Date().toISOString().substr(0, 10)"  v-model="form.datum" v-on:input="getSport"><br>
    
      </form>
    
    
      <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message">Available appointments:</div>
      
      
          <div class="row">
            <div  class="col-md-4 col-xl-3" v-if="areKeysEmpty()">
                  <div class="card bg-c-tenis order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                              <h2 class="text-right">{{ $t('x45') }}</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
              
           
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke[getTimeValue(this.form.vrsta)]">
                  <div class="card bg-c-tenis order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
                        
                          <span>
                           

                            <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, this.getTimeValue(this.form.vrsta))}}h<span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>    
                          
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
         
           
              
          <section id="rez">
              <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message" v-if="prijava">Your reservations:</div>
            </section>
              <div class="col-md-4 col-xl-3" style="padding-top:5px;" v-if="prijava && !rezervacije.length">
                  <div class="card bg-c-green order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                            <h2 class="text-right">You have no reservations</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      
              <div class="col-md-4 col-xl-3"  style="padding-top:5px;" v-for="i in rezervacije">
                  <div class="card bg-c-green order-card" >
               
      
                      <div class="card-block">
                          <i class="fa fa-times-circle f-right"  @click="cancelpotvrda(i[2])"  style="cursor:pointer;color:red;"></i>
                        
                         
                          <span>
                            {{ tretmani[i[3]] }}
                            <h2 class="text-right">{{ new Date(i[1]).toLocaleDateString('en-UK', {day: 'numeric', month: 'numeric'}) }} &nbsp;  {{TimeString(i[0])}}h -  {{TimeString2(i[0], this.getTimeValue(i[3]))}}h  </h2>
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      <div style="height:200px;"></div>
        </div>
      </div>
    
    
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
    
    <div class="footer-content">
      <div class="container">
        <div class="row">
    
          <div class="col-lg-12 col-md-12">
            <div class="footer-info">
              <h3>San polo massage tent next to Babaloo bar</h3>
              <p>
       

            <a href="https://www.monperin.com/experiences/massage">Where?</a><br>
            
              </p>
            </div>
          </div>
    
    
          
    
          <div class="col-lg-4 col-md-12 footer-newsletter">
    
    
    
    
    </div>
    
    
    
    
        </div>
      </div>
    </div>
    
    
    </footer><!-- End Footer -->
    
        </template>
    
      </Navigation >
    
      <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">Reserve Massage</h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')" ></i>


        </div>
        <div class="modal-body">
        
          <form>
          <div v-if="prijava"><p>Fee can be payed at spot<br>You chose {{TimeString(form.terminsat)}}h - {{TimeString2(form.terminsat, this.getTimeValue(this.form.vrsta))}}h</p>
         </div>
         <div v-if="!prijava">
<p>Since you are not logged in, pelase fill this information about you</p>
<input type="text" placeholder="Your name" class="form-control" v-model="form.ime"><br>
<input type="email" placeholder="Email where we will send you confirmation" class="form-control" v-model="form.email">
          </div><br>

        </form>

        </div>
        <div class="modal-footer d-flex justify-content-center">
 
           <button type="button" @click="rezerviraj" :disabled="buttonDisabled" class="btn btn-info f-left modalbutton" style="border:none;">Submit</button>
           <div style="padding-top:120px;" class="prazanprostor"></div>
      
        </div>
      </div>
    </div>
  </div>


      <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('cancelres') }}</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>

            </div>
            <div class="modal-body">
              <p>{{ $t('cancelres2') }}</p>
            </div>
            <div class="modal-footer d-flex justify-content-center">
            <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;background-color: #dc3545;">Cancel</button>
            <div style="padding-top:120px;" class="prazanprostor"></div>
            </div>
          </div>
        </div>
      </div>
      

    

      </div>
        </template>
        
     
        <script>
        import axios from 'axios'
        import Navigation from '../components/Navigation.vue'
        export default {
          name: 'Info',
          components: {
            Navigation
          },
        
          data(){
            return{
              datoteke:[],
              rezervacije:[],
              id:"",
              canceldatum:"",
              cancelsat:"",
              navBarKey:0,
              buttonDisabled: false,
              prijava:false,
              email:"",

              form:{
                datum:new Date().toISOString().substr(0, 10),
                email:"",
                terminsat:"",
                vrsta:"",
                trideset:false,
                lokacija:"frizer",
                ime:""
    
              },
              cijene:{
                21:15,
                22:22,
                23:15,
                24:18,
                25:22,
                26:25,
                27:75,
                28:100,
                29:90,
                30:120,
                31:35,
                32:30,
                33:10,
                34:30,
                35:6,
                36:60,
              },

              tretmani:{
                21:"MEN'S HAIRCUT - Clippers",
                22:"MEN'S CLASSIC HAIRCUT",
                23:"CHILDREN'S HAIRCUT",
                24:"WOMEN'S SHORT HAIRCUT",
                25:"WOMEN'S MEDIUM LENGTH HAIRCUT",
                26:"WOMEN'S LONG HAIRCUT",
                27:"HAIR COLORING",
                28:"LONG HAIR COLORING",
                29:"HIGHLIGHTS",
                30:"LONG HAIR HIGHLIGHTS",
                31:"HAIR BLOWOUT",
                32:"NOURISHING SCALP TREATMENT",
                33:"NOURISHING HAIR MASK",
                34:"SCALP MASSAGE (15 minutes)",
                35:"HAIR WASH",
                36:"HAIR PACKAGE (wash, haircut, blowout)",


      
              }
            }
          },
          mounted(){
   
            this.filter(21)
            this.platform = Capacitor.getPlatform()
            
            const preloader = document.querySelector('#preloader');
            this.prijava = this.$store.getters.isAuth;
            if(this.prijava){
              this.mojiTermini()
            }
    
            
            this.getSport()
          
        
          },
        
          methods:{
            async togglepolicy(tekst){
              $("#"+tekst).toggle();
            },
    
            async plati(){
            if(!document.getElementById('flexCheckDefault').checked){
              this.$root.toastnoti(this.$t('error'), "Confirm sales policy to proceed...", "greska")
              return;
            }
              $("#preloader").show();
              $('#rezervirajModal').modal('hide')
              await axios
              
              .get("placanje/stvorihash/sport/"+this.form.datum+"/"+this.form.terminsat)
              .then(response => {
                this.$root.toastnoti(this.$t('success'), "Redirecting...", "uspjeh")
                this.openPaymentForm(response.data.hash, response.data.cartid)
      
      
        
              })
              .catch(error => {
                this.$root.toastnoti(this.$t('error'), "There has been error with our Payment service, please try to contact us because app might not be working properly", "greska")
                $("#preloader").hide();
               
              })
            },

    
            methodThatForcesUpdate(key){
              
              this.mojiTermini()
              this.getSport()
        
          },
    
          async cancelpotvrda(id){
      
              this.id = id
        
              this.$root.otvoriform('deleteConfirmModal')
      
          },
    
    
           moguceodgoditi(datum, sat){
            const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
            const now = new Date();
            const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));
            
    
            if (diffInHrs > 2 && targetDate > now) {
              return true
            } else {
              return false
            }
    
      
          },
          async rezervirajpotvrda(sat){
              this.form.terminsat = sat

             
              this.$root.otvoriform('rezervirajModal')
      
          },
          async cancel(){ 
              console.log(this.id)
                var apilink = '/mpguest/odgodimasaza/'+this.id
               
    
      
              await axios
              
                .get(apilink)
                .then(response => {
                  this.$root.toastnoti(this.$t('success'), this.$t('odgodatermina'), "uspjeh")
                  this.rezervacije = this.rezervacije.filter(arr => arr[2] !== this.id);
                  $('#deleteConfirmModal').modal('hide')
        
          
                })
                .catch(error => {
                  this.$root.toastnoti(this.$t('error'), this.$t('greska'), "uspjeh")
                 
                })
      
          },
          async rezerviraj(){
            this.buttonDisabled = true
            
          
              axios.post('/mpguest/rezervirajmasaza/', this.form)
            .then((res) => {
    
              this.$root.toastnoti(this.$t('success'), this.$t('reserved'), "uspjeh")
           
                
                $("#termin"+this.form.terminsat).remove();
                this.buttonDisabled = false;
                this.getSport()
                this.mojiTermini()
                this.$root.scrollToSection('rez')
                    
              })
            .catch((error) => {
              console.log(error)
              this.$root.toastnoti(this.$t('error'), this.$t('greska'), "error")
              this.buttonDisabled = false;
            })
    
            $('#rezervirajModal').modal('hide')
      
          },
         
            async getSport() {
              $("#preloader").show()
              this.datoteke = {}
                  const inputDate = new Date(this.form.datum); // replace with your input date
                  const dayOfWeek = inputDate.getDay();
                  console.log("dayofsweek", dayOfWeek)
                  if([1, 2, 3, 4, 5, 0].includes(dayOfWeek)){
                    this.$root.toastnoti("Info", "Hairdresser works on saturday", "info")
                    $("#preloader").hide()
                    return false
                  }
        const now = new Date();
    
        const hasAlreadyPassed = inputDate < now &&
                                (inputDate.getFullYear() < now.getFullYear() ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));
    
    
              if (hasAlreadyPassed) {
              this.$root.toastnoti(this.$t('error'), this.$t('prosaodatum'), "error")
              $("#preloader").hide();
              return false;
            }
            if (!this.form.datum) {
              this.$root.toastnoti(this.$t('error'), this.$t('unesitedatum'), "error")
              return false;
            }
            const targetDate = new Date('2024-06-04');
            if (inputDate.toDateString() === targetDate.toDateString()) {
                    this.$root.toastnoti("Info", "We are closed on 4th of june", "info")
                    $("#preloader").hide()
                    return false;
                    }
              //$("#preloader").show();
        
              await axios
                .get('/mpguest/masazatermini/frizer/'+this.form.datum)
                .then(response => {
                  this.datoteke = response.data
                  console.log(this.datoteke)
                  $("#preloader").hide();
        
          
                })
                .catch(error => {
                  this.$root.toastnoti(this.$t('error'), this.$t('greska'), "greska")
                  $("#preloader").hide();
                
                 
                })
        
            },
            async mojiTermini() {
              await axios
                .get('/mpguest/mojiterminimasaza/frizer')
                .then(response => {
                  this.rezervacije = response.data.termini
                  console.log(this.rezervacije)
                  $("#preloader").hide();
        
          
                })
                .catch(error => {
                  //this.$root.toastnoti('Log in', "You are not logged in, but you can still", "greska")
                  $("#preloader").hide();
                 
                })
        
            },
            getTimeValue(value) {
            const myDict = {};

// Populate the dictionary with keys from 21 to 35 with the value 30
for (let i = 21; i <= 35; i++) {
  myDict[i] = 30;
}
myDict[36] = 50;
      return myDict[value] || null; // Return null if the value is not found
    },
    areKeysEmpty(obj) {
                if(obj) {
                    const keys = [30, 50, 120, 80, 20, 15, 25, 45, 60];

return keys.every(key => Array.isArray(obj[key]) && obj[key].length === 0);
                }
                else{
                    return false
                }
        
},
            filter(vrsta){
  
        $('.kategorija').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
        this.form.vrsta = vrsta
        if(vrsta != 0 && vrsta!=1){
          $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
          $('#pedeset').addClass('tagsort-active').css('opacity', '1');
          this.form.trideset = false
        }

        $('.'+vrsta ).show()


    },
    minute(vrsta){
        $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
  if(vrsta=="trideset"){
    this.form.trideset = true
  }
  else{
    this.form.trideset=false
  }

  $('.'+vrsta ).show()


},
vrstaime(vrsta){
        switch (vrsta) {  

      case 0 : return 'Back, neck, head and shoulders';
      case 1 : return 'Foot Reflexolog';
      case 2: return 'Coconut Massage';
      case 3: return 'Aroma massage';
      case 4: return 'Thai oil Massage';
      case 5: return 'Thai traditional massage';
      case 6: return 'Organic olive pulp massage';
      default: return 'Foot Reflexolog';
    }
    





},
nemasdostupno(){
  if (this.form.trideset){
    if(!this.datoteke.tri || this.datoteke.tri.length === 0){
      return true
    }
    return false
  }
  else{
    if(!this.datoteke.pet || this.datoteke.pet.length === 0){
      return true
    }
    return false
  }
 
},
TimeString(time){

var time = new Date('2023-01-01T' + time);
var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
},
TimeString2(time, broj){

var time = new Date('2023-01-01T' + time);
time.setMinutes(time.getMinutes() + broj);

var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
}
          }
        }
        </script>
      
      
      <style>
  
  .image-container img {

    display: block;
    width: 100%;
    min-height: 300px;
    max-height: 5%;
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
  
  }
.darken-image {
  
    position: relative;
    display: inline-block;
  }

  .darken-image img {
    display: block;
  }
  .darken-image .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
      
      .btn-getstarted {
          font-size: 16px;
          color: var(--color-white);
          background: var(--color-primary);
          padding: 8px 23px;
          border-radius: 4px;
          transition: 0.3s;
          font-family: var(--font-secondary);
          border:none;
    
      
      
      }
    
      
      .order-card {
          color: #fff;
      }
      
      .bg-c-blue {
          background: linear-gradient(45deg,#4099ff,#73b4ff);
      }
      
      .bg-c-green {
      
          background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
          
      }
      
      .bg-c-yellow {
          background: linear-gradient(45deg,#FFB64D,#ffcb80);
      }
      
      .bg-c-pink {
          background: linear-gradient(45deg,#FF5370,#ff869a);
      }
      
      
      .card {
          border-radius: 5px;
          -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          border: none;
          margin-bottom: 30px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
      }
      
      .card .card-block {
          padding: 25px;
      }
      
      .order-card i {
          font-size: 26px;
      }
      
      .f-left {
          float: left;
      }
      
      .f-right {
          float: right;
      }
      
      
      .bg-c-tenis {
          background: linear-gradient(45deg,#bed6db,#44a3b6);
      }
      </style>
      
      <style scoped>
        
      .hero-animated {
          padding: 50px 0 0px; 
          min-height: 30vh;
      }
      
      section {
          padding: 0px 0; 
      
      }
    
      .hero-animated p {
        color: rgba(var(--color-secondary-rgb),.8);
        margin: 0 0 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    
    @media (max-width: 640px){
      .hero-animated p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    }
    .menu-tags span{
      display: inline-block;
    }
    
      
      </style>
      
      <style scoped>
      .btn-outline-light:hover {
  
      background-color: transparent;
      border-color: #f8f9fa;
  }
  
      .oasisnaslov{
        font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: normal;
      }
      
      .sub-banner-sec {
    background: url(../assets/frizer.jpg) no-repeat center;
    
    background-size: cover;
    height: 561px;
  
  
  }
  
      .hero-animated{
        min-height: 15vh;
        padding: 0px
      }
      </style>