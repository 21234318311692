<template>

<div style="z-index: 1050;" class="toast-container position-fixed bottom-0 end-0 p-3 d-none" data-autohide="true">
  <div id="liveToast" class="toast " role="alert" aria-live="assertive" aria-atomic="true"  data-delay="3500">
    <div class="toast-header " style="color:white; font-weight:bold; background: linear-gradient(45deg,#2ed8b6,#59e0c5);">

      <i class="fa fa-info-circle rounded  me-2"></i>
      <strong style="cursor: pointer;" id="naslov" class="me-auto">Notifications</strong>
      <strong>Now</strong>
      <button type="button"  style="font-weight:bold;" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>


    </div>
    <div class="toast-body" style="color:rgb(106, 105, 105);">

      <span id="tekst">Sucess</span>
    </div>
  </div>

 
</div>

<router-view/>

</template>


<style>

</style>

<script>
import axios from 'axios'
import { PushNotifications } from '@capacitor/push-notifications';
export default{

  data(){
    return {
    username:"",
    }

  },
  

  
beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token     
    } else {
        axios.defaults.headers.common['Authorization'] = ""

    }
  },

  mounted(){
    
    if(Capacitor.getPlatform() == 'ios'){$('head').append(`<style>.header{padding: 0px 0 !important;padding-top:55px !important;padding-bottom:15px !important;}.mobile-nav-toggle {padding-top:40px;} #navbarurl {padding-top:40px !important;}</style>`);}
  
    PushNotifications.addListener('pushNotificationActionPerformed', this.handleNotificationClick);
    PushNotifications.removeAllDeliveredNotifications()
    PushNotifications.addListener('pushNotificationReceived',notification => {

  window.location.reload()

  });


  },
  methods:{

    scrollToSection(sectionId) {
      setTimeout(() => {
        const section = document.getElementById(sectionId);
      if (section) {
        // Scroll to the section smoothly
        section.scrollIntoView({ behavior: 'smooth' });
      }
      }, 1000); // 500ms delay
   
    },

    handleNotificationClick(notification) {
      const page = notification.notification.data.page// get the page from the notification payload
      this.$router.push(page);
 
    },
    otvoriform(modalname){
    $('#' + modalname + ' ' +  '.modal-content').removeClass('animate-down').addClass('animate-bottom')
    $("#"+modalname).modal('show');   

  },
  zatvoriform(modalname){

    $('#' + modalname + ' ' +  '.modal-content').removeClass('animate-bottom').addClass('animate-down');
     $("#"+modalname).modal('hide');   


  
  },
  

    toastnoti(naslov, tijelo, vrsta){
    
      if(vrsta=="uspjeh"){
      $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#2ed8b6,#59e0c5)');
    }
    else if(vrsta=="info"){
      $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#1ec3e0,#0ea2bd)');
    }
    else{
      $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#FF5370,#ff869a)');
      
    }
    $('.toast-container').removeClass('d-none');
    const toastLiveExample = document.getElementById('liveToast')
    
    $('#liveToast #tekst').text(tijelo);
    $('#liveToast #naslov').text(naslov);
    const toast = new bootstrap.Toast(toastLiveExample)
    toast.show()
    
  

  },
  }
}
</script>