export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli u  "])},
  "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učinite vaš boravak još boljim"])},
  "reportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavi kvar"])},
  "reporttekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikom na gumb Stvori u kartici Prijavi moći ćete prijaviti kvar. Obavijest odmah dolazi do našeg osoblja koje će pokušati što je prije moguće ukloniti kvar."])},
  "infonaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije"])},
  "infotekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite najnoviju knjižicu s informacijama koja sadrži događaje, raspored vožnje turističkog vlakića i radno vrijeme objekata. <br>Pregledajte aktivnosti u kampu i okolici te primajte obavijesti."])},
  "deliverynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostave"])},
  "deliverytekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite jela iz restorana, a mi ćemo ih dostaviti do vaše mobilne kućice."])},
  "rezervacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervacije sportskih terena"])},
  "rezervacijetekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite sport, vrijeme i uživajte."])},
  "morenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I više..."])},
  "moretekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroz ankete izrazite svoje mišljenje ili pregledajte naše suvenire"])},
  "getaccnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Još nemate svoj račun?"])},
  "getaccopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na kartici Kreni ispunite tražene podatke kako biste dobili korisničko ime i lozinku. Svi podaci nalaze se na prijavnom listiću."])},
  "getaccbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreni"])},
  "footercontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte nas"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruke"])},
  "Souvenirs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suveniri"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavi kvar"])},
  "Restaurnats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restorani"])},
  "Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Košarica"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijava"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija"])},
  "poruke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruke"])},
  "startmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilna kućica"])},
  "startpc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcela"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna"])},
  "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostava"])},
  "logininfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemate podatke za prijavu?"])},
  "downloadare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi"])},
  "downloadopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednim klikom udaljeni ste od svih važnih informacija."])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmske večeri, zabave na bazenu i još mnogo toga."])},
  "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanice i raspored vožnje turističkog vlakića koji vozi unutar kampa i do Bala."])},
  "trainnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turistički vlakić  "])},
  "workingtimenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radna vremena i informacije"])},
  "workingtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radna vremena objekata"])},
  "freshmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fresh Market & Farma"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa kampa"])},
  "farewell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidimo se"])},
  "farewell_opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izgleda da je vaš boravak prišao kraju. Hvala Vam još jednom što ste bili naši gosti. Nadamo se da ćemo se vidjeti opet u skorijoj budućnosti."])},
  "farewell_gumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijava"])},
  "profiledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji profila"])},
  "notifikacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nove poruke bit će prikazane ovdje. "])},
  "suveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupite suvenire na recepciji kampa ili u Paleo Parku."])},
  "noreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaši prijavljeni kvarovi bit će ovdje prikazani<br>Nešto ne radi ispravno? <br>Kreirajte zadatak za naše osoblje koje će što prije doći do Vas."])},
  "searchjela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pretraži jela"])},
  "restoranopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besplatna i brza dostava do vaše kućice dok Vi uživate u odmoru."])},
  "cartgumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavi"])},
  "deliveryinformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji Dostave"])},
  "deliverydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostava za"])},
  "Preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremanje jela"])},
  "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelo je spremno"])},
  "Taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostavlja se"])},
  "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostavljeno"])},
  "Restaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostave"])},
  "sportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Višenamjenska sportska igrališta"])},
  "sportopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum i vrijeme te zaigrajte."])},
  "extrainfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne informacije"])},
  "reportnapomena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzet ćemo u obzir željeni termin dostave i potrudit ćemo se udovoljiti Vašem zahtjevu."])},
  "extrainfosuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne informacije"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspjeh!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
  "loggedsucess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavljeni ste"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjavljeni ste"])},
  "loggeror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogrešni podaci"])},
  "greska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ispričavamo se, došlo je do greške."])},
  "profilesave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil je spremljen"])},
  "incorrectnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provjerite je li broj telefona točan"])},
  "verifynumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite broj telefona"])},
  "profilerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška prilikom potvrde broja telefona"])},
  "krivikod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogrešan kod, pokušajte ponovno"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obavijesti"])},
  "availabletimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobodni termini za odabrani datum:"])},
  "nemaslobodnog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema slobodnih termina..."])},
  "nemarezerviranog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemate rezerviranih termina..."])},
  "rezervacijesporta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje rezervacije termina:"])},
  "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin je rezerviran"])},
  "prosaodatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum koji ste unijeli je prošao."])},
  "odgodatermina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin je otkazan"])},
  "zdstatus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primljen"])},
  "zdstatus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzet"])},
  "zdstatus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzet"])},
  "zdstatus4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrađen"])},
  "izbrisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbriši"])},
  "izbrisizadatak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeste li sigurni da želite izbrisati ovaj zadatak?"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavite zadatak"])},
  "reportinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne informacije"])},
  "reportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdi"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stvori"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Želim primati novosti na email adresu."])},
  "jelokategorija1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predjela"])},
  "jelokategorija2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručak"])},
  "jelokategorija3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glavna jela"])},
  "jelokategorija4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deserti"])},
  "jelokategorija5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pića"])},
  "razumijem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razumijem"])},
  "extrainfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne informacije"])},
  "suvenirdetalji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji dostave suvenira"])},
  "nemasuvenira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema aktivne dostave, odaberite suvenire iz trgovine"])},
  "taskreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na putu"])},
  "checkboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označite kućicu"])},
  "dostavavecpostoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Već imate aktivnu dostavu"])},
  "uspjehsuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša dostava je na putu"])},
  "emptycart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša košarica je prazna"])},
  "taskdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadatak je izbrisan"])},
  "unesitedatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvo upišite datum"])},
  "breakfast1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrok"])},
  "breakfast2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevarage"])},
  "breakfast3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaci"])},
  "cartsuvenir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Košarica suvenira"])},
  "cancelres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otkaži rezervaciju"])},
  "cancelres2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeste li sigurni da ju želite otkazati?"])},
  "reservesport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezerviraj sportski teren"])},
  "reservesport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeste li sigurni da želite napraviti rezervaciju?"])},
  "tenisnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenisko igralište"])},
  "tenissport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum i vrijeme koji Vam odgovara."])},
  "main1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predjela"])},
  "main2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glavna jela"])},
  "main3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deserti"])},
  "main4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pića"])},
  "credentialsmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite podatke za prijavu (mobilna kućica)"])},
  "credentialspc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite podatke za prijavu (parcela)"])},
  "howcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije možete pronaći na prijavnom listiću"])},
  "mh1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj mobilne kućice *"])},
  "pc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj parcele*"])},
  "zona1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona *"])},
  "camp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp *"])},
  "od1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum dolaska *"])},
  "do1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum odlaska *"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavite lozinku ili ostavite prazno polje kako biste lozinku automatski generirali *"])},
  "submitcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdi"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavi se"])},
  "logout1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjavi se"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisničko ime"])},
  "password1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])},
  "tel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonski broj *"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremi"])},
  "notifikacije": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obavijesti"])},
  "notifikacijeopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osim obavijesti, preuzmite i važne informacije o Camping Resortu"])},
  "reportmodal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavite zadatak"])},
  "reportmodal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta zadatka"])},
  "reportmodal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["npr. željeno vrijeme"])},
  "sport1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervacije se mogu otkazati do 2 sata prije početka"])},
  "sport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gostima kampa trošak se sprema na boravak te ga plaćaju pri odlasku. Osobe koje nisu prijavljene u kampu mogu kartično platiti"])},
  "multi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite jedno ili više polja"])},
  "multi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno"])},
  "kreiranzd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadatak je napravljen"])},
  "surveynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankete"])},
  "surveyopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše su povratne informacije važne kako bismo bili sigurni da pružamo uslugu koju trebate.<br> Cijenili bismo ako biste ispunili anketu o svom boravku kod nas."])},
  "s1naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boravak u mobilnim kućicama"])},
  "s1opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recite nam više o svom boravku u mobilnoj kući."])},
  "s2naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boravak na parceli"])},
  "s2opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recite nam nešto više o svom boravku na parceli."])},
  "s3naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakon boravaka"])},
  "s3opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recite nam nešto više o svom boravku općenito."])},
  "Booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brošura"])},
  "payement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostava će započeti čim primimo uplatu"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisnik"])},
  "Dodano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelo je dodano u košaricu"])},
  "loginbitno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za izradu rezervacija morate biti prijavljeni"])},
  "pickfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najprije izaberite polje"])},
  "Maknuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbačeno"])},
  "foodcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Košarica jela"])},
  "emptydeliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše isporuke pojavit će se ovdje, stvorite je tako što ćete odabrati stavke iz restorana i potom slijedite korake u košarici"])},
  "passwordunos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neobavezno* Odaberite lozinku ili dobivate automatski generiranu"])},
  "activity1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sve"])},
  "activity2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabava"])},
  "activity3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kultura"])},
  "activity4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "activity5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tečajevi"])},
  "activity6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
  "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivnosti"])},
  "exploreactivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istražite aktivnosti unutar Campin Resorta i u obližnjim Balama"])},
  "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prikaži više..."])},
  "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prikaži manje..."])},
  "profil1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smještaj*"])},
  "profil2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime*"])},
  "deliveryopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možete zakazati dostavu za kasnije"])},
  "bikepaths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biciklističke staze"])},
  "monperin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Perin"])},
  "bale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bale/Valle"])},
  "paleopark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paleo Park"])},
  "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping Resort – Camping Home i Camping Villa"])},
  "bale1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posjetite Bale, osvojit će vas netaknutom prirodom, mirom, kristalno čistim morem, ali i brojnim događanjima"])},
  "camping1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istražite naše vile"])},
  "paleopark1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cjenik za Paleo park (za goste uključen u cijenu boravka)"])},
  "bikepaths1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bale su pravi izazov ili avantura za sve one koji žele uživati u aktivnoj rekreaciji."])},
  "monperin1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saznajte više o Mon Perinu"])},
  "cijenik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cijena: 4€ po satu<br>Teniski reketi i loptice dostupne su za najam na terenima"])},
  "cijenik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cijena: 4€ po satu<br>Oprema dostupna za najam na terenima"])},
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite vrijeme kada želite započeti dostavu, ovisno o dobi dana, samo će jedan restoran biti dostupan"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restorani trenutno ne rade"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite buduće vrijeme dostave"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite vrijeme između 7-21"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimalan broj dana unaprijed za dostavu je 1 dan"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo Vas da prvo unesete podatke o telefonu na stranici profila"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možemo Vas kontaktirati na broj telefona koji ste ispunili na stranici profila."])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi ćemo izvršiti dostavu do Vaše mobilne kućice"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema odabranog ručka u Vašoj košarici"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema odabranog doručka u Vašoj košarici"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kada želite započeti dostavu?"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakaži dostavu za kasnije?"])},
  "salespolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupac mora izvršiti rezervaciju sport terena putem odgovarajućeg kanala, kao što su telefonski poziv, internetska platforma ili osobni dolazak u prodajni objekt.Rezervacija sport terena mora biti potvrđena od strane prodavatelja ili odgovorne osobe zadužene za rezervacije.Kupac se obvezuje platiti odgovarajući iznos za rezervaciju sport terena prema dogovorenoj cijeni.Načini plaćanja mogu uključivati gotovinsko plaćanje, kartično plaćanje ili elektroničko plaćanje prema pravilima prodavatelja.Kupac mora izvršiti plaćanje unaprijed ili u dogovorenom roku prije datuma rezervacije sport terena.U slučaju nedostatka plaćanja u dogovorenom roku, rezervacija sport terena može biti poništena.Opis načina i roka isporuke proizvoda ili pružanja usluge:Sport teren će biti dostupan za korištenje na dogovoreni datum i vrijeme.Kupac će dobiti potvrdu rezervacije koja će sadržavati točne podatke o datumu, vremenu i barcodu za otvaranje sport terena.Troškovi dostave:U slučaju rezervacije sport terena, troškovi dostave se ne primjenjuju jer se rezervacija odnosi na korištenje prostora, a ne fizičku dostavu proizvoda.Ugovorne odredbe:Rezervacija sport terena smatra se pravovaljanom ugovornom obvezom između kupca i prodavatelja.Kupac se obvezuje poštivati pravila i uvjete korištenja sport terena navedene od strane prodavatelja.Opći uvjeti poslovanja:Opći uvjeti poslovanja, ako su sastavni dio ugovora, primjenjuju se na rezervaciju sport terena i obvezujuće su za obje strane."])},
  "conversionpolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sva plaćanja će se vršiti u hrvatskoj valuti. Iznos za koji će vaša kreditna kartica biti terećena dobiva se konverzijom cijene u drugoj valuti u EUR prema važećem tečaju Hrvatske narodne banke. Prilikom terećenja vaše kreditne kartice, isti se iznos preračunava u vašu lokalnu valutu prema tečaju kartičarskih udruga. Kao rezultat ove pretvorbe postoji mogućnost neznatne razlike u odnosu na izvornu cijenu navedenu na našoj web stranici."])},
  "datapolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest se obvezuje čuvati osobne podatke kupaca, na način da prikuplja samo nužne, osnovne podatke o kupcima/korisnicima koji su nužni za ispunjenje naših obveza; informira kupce o načinu korištenja prikupljenih podataka, redovito daje kupcima mogućnost izbora korištenja njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s popisa koji se koriste za marketinške kampanje. Svi korisnički podaci se strogo čuvaju i dostupni su samo zaposlenicima kojima su ti podaci nužni za obavljanje posla. Svi djelatnici Terra Vallis i poslovni partneri odgovorni su za poštivanje načela zaštite privatnosti"])},
  "securitypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest koristi Monri WSPay za online plaćanja. Monri WSPay je siguran sustav za online plaćanje, plaćanje u realnom vremenu, kreditnim i debitnim karticama te drugim načinima plaćanja. Monri WSPay kupcu i trgovcu osiguravaju siguran upis i prijenos upisanih podataka o karticama što podvrđuje i PCI DSS certifikat koji Monri WSPay ima. Monri WSPay koristi SSL certifikat 256 bitne enkripcije te TLS 1.2 kriptografski protokol kao najviše stupnjeve zaštite kod upisa i prijenosa podataka."])},
  "salespolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvjeti prodaje"])},
  "conversionpolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izjava o konverziji"])},
  "datapolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izjava o zaštite osobnih podataka"])},
  "securitypolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izjava o sigurnosti"])},
  "tenisopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niste prijavljeni, ali svejedno možete rezervirati sportske terene putem online plaćanja <br>Cijena po satu za teniski teren je 13€"])},
  "multiopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niste prijavljeni, ali svejedno možete rezervirati sportske terene putem online plaćanja<br>Cijena 1 terena po satu je 13€"])},
  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaštita podataka sukladno Općoj uredbi o zaštiti podataka Europskog parlamenta i Vijeća br. 2016/679- Uredba i provedba GDPR-a WSPay, kao izvršitelj obrade autorizacije i plaćanja kreditnim karticama, koristi osobne podatke kao izvršitelj obrade sukladno Općoj uredbi o zaštiti podataka Europskog parlamenta i Vijeća br. 2016/679 i usklađen s PCI DSS Regulativom razine 1 za prijenos podataka. WSPay koristi 256-bitnu SSL enkripciju i TLS 1.2 kriptografski protokol kao najviše standarde zaštite za unos i prijenos podataka. Osobni podaci koji se koriste u svrhu autorizacije i plaćanja smatraju se povjerljivim podacima. Za ispunjenje Ugovora (autorizacija i plaćanje) potrebni su sljedeći osobni podaci kupca: Ime i prezime E-mail Broj telefona Adresa Grad Poštanski broj Država Vrsta kreditne kartice Broj kreditne kartice Datum isteka (kreditne kartice) CVV broj kreditne kartice WSPay čini neće obrađivati ​​niti koristiti ove osobne podatke osim u svrhu ispunjenja Ugovora, autorizacije i plaćanja. WSPay osigurava ispunjavanje zahtjeva utvrđenih važećim propisima o zaštiti osobnih podataka, za izvršitelje obrade osobnih podataka, posebno poduzimanje svih potrebnih tehničkih, organizacijskih ili sigurnosnih mjera potvrđenih PCI DSS Level 1 certifikatom."])},
  "salespolicy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.Naručivanje: Doručak i ručak mogu se naručiti u zadano vrijeme. Dostave mogu biti unaprijed zakazane za buduće vrijeme. 2.Vrijeme isporuke: Dostava će biti isporučena najkasnije unutar jednog sata od vremena početka dostave. Ako su dostave zakazane za buduće vrijeme, nastojat ćemo ih isporučiti tada. 3. Načini plaćanja: Prihvaćamo plaćanje karticama (debitne/kreditne) prilikom početka dostave. Moguće je plaćanje putem mobilnih aplikacija ili internetskog bankarstva. 4.Opis isporuke: Hrana će biti isporučena unutar kampa direktno do mobilne kućice gosta. Dostava će biti obavljena od strane našeg osoblja. 5.Troškovi dostave: Troškovi dostave će biti jasno naznačeni prilikom narudžbe. U nekim slučajevima, dostava može biti besplatna ovisno o minimalnom iznosu narudžbe. 6.Ugovorne odredbe: Svaka narudžba predstavlja sklopljen ugovor između kupca i dostavne službe. Kupac prihvaća uvjete dostave prilikom potvrde narudžbe. 7.Pravo kupca na raskid ugovora: Kupac nema pravo na raskid ugovora, ukoliko dođe do greške prilikom dostave, kupcu će biti vraćen novac."])},
  "x1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostava ukusne hrane u vaš smještaj. "])},
  "x2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ručak"])},
  "x3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isporuke"])},
  "x10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite za preusmjeravanje na naš jelovnik!"])},
  "x11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručak"])},
  "x4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razmišljate o izvrsnom doručku za sutra? "])},
  "x5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite za preusmjeravanje na prednarudžbu kutije za doručak!"])},
  "x6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervirajte svoj sportski termin sada i pripremite se za stvaranje nezaboravnih uspomena sa svakim zamahom, udarcem ili golom. "])},
  "x7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportske rezervacije"])},
  "x8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervacije za masažu"])},
  "x9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neka sve tvoje brige nestanu. Nudimo razne vrste masaža prilagođene Vašim potrebama. Rezervirajte ovdje!"])},
  "x12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 € po satu, bit će dodato ukupnom iznosu boravka i može se platiti prilikom odjave. <br><span >Može se otkazati do 2 sata prije početka. <br>Pomaknite se prema dolje do svojih rezervacija i kliknite gumb X da biste je otkazali</span>"])},
  "x13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naše Višenamjensko polje nudi široki raspon aktivnosti za sve. "])},
  "x14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte nas! "])},
  "x15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakon dana provedenog u sportu i zabavi, opustite se uz ugodnu masažu. <br>Naknada se može platiti na licu mjesta"])},
  "x16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šator za masažu smješten u San Polo pored Babaloo bara. <br>Naknada se može platiti na licu mjesta"])},
  "x17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razmišljate o izvrsnom doručku za sutra? "])},
  "x18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutija za doručak sastoji se od:"])},
  "x19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predbilježbe za sljedeće jutro dostupne su svaki dan do"])},
  "x20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostava 8-9 h."])},
  "x21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koliko kutija želite naručiti? "])},
  "x22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za sada su dostupna samo gotovinska plaćanja osoblju."])},
  "x23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikom na Kreiraj potvrđujete primitak "])},
  "x24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kutija za doručak"])},
  "x25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sutra, ujutro"])},
  "x26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ako želite otkazati narudžbu ili promijeniti broj kutija, nemojte se ustručavati kontaktirati nas putem chata. <br>Za sada prihvaćamo samo gotovinska plaćanja dostavljačima"])},
  "x27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite dostavu doručka"])},
  "x28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite gumb Zakaži kasnije da biste ga unaprijed naručili"])},
  "x29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razmišljate o izvrsnom obroku?  "])},
  "x30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prihvaćam"])},
  "x31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrolajte prema dolje do svojih rezervacija i kliknite ikonu bar koda da biste dobili jedinstveni bar kod za ulaz na teren"])},
  "x32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručak"])},
  "x33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostava hrane"])},
  "x34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelovnik za ručak"])},
  "x35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostave"])},
  "x36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenis"])},
  "x37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Višenamjenski teren"])},
  "x38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masaža"])},
  "x39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobodni termini:"])},
  "x40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše rezervacije:"])},
  "x41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barkod za ulaz"])},
  "x42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13€ bit će dodano na vaš ukupni boravak"])},
  "x43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napravi dostavu ujutro na "])},
  "x44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Započni isporuku"])},
  "x45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedostupno"])},
  "x46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepcija"])},
  "freetenis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besplatno za goste"])},
  "myrez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje Rezervacije"])}
}