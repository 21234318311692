<template><br>

    <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
  <template #atag><a></a></template>
  <template #content>
    <div style="padding-top: 60px;"></div>
    <div class="darken-image  image-container">
        
        <img src="../assets/exploremp.jpg" class="img-fluid darkenimg" style="width:100%;">
<div class="text-overlay">
  <h2><span>{{ $t('explore') }}</span></h2>
  
</div>
</div>
    
    <section id="hero-animated" class="hero-animated d-flex align-items-center">
    <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">

      <p>{{ $t('exploreactivities') }}</p>
     
   
    </div>

</section>
        
        <section class="menu">
        <div class="container">
        <div class="row">
        <div class="col-md-12">
  
        </div>
        </div>
        <div class="food-menu wow fadeInUp">
        <div class="row">
        <div class="col-md-12"> 
        <div class="menu-tags" >
        
        <span id="sve" class="tagsort-active kategorija" @click="filter('sve')"><i class="fa-solid fa-umbrella-beach" style="color:gray;font-size:30px;padding-bottom:5px;"></i><br><span >{{ $t('activity1') }}</span></span>
        <span id="fun" class=" kategorija" @click="filter('fun')"><i class="fa-solid fa-masks-theater" style="color:gray;font-size:30px;padding-bottom:5px;"></i><br><span >{{ $t('activity2') }}</span></span>
        <span  id="culture" class="kategorija" @click="filter('culture')"><i class="fa-solid fa-landmark" style="color:gray;font-size:30px;padding-bottom:5px;opacity: 0.5;"></i><br><span>{{ $t('activity3') }}</span></span>
        <span  id="sport" class="kategorija"  @click="filter('sport')"><i class="fa-solid fa-volleyball" style="color:gray;font-size:30px;padding-bottom:5px;opacity: 0.5;"></i><br><span>{{ $t('activity4') }}</span></span>
        <span id="courses"  class="kategorija"  @click="filter('courses')"><i class="fa-solid fa-sailboat" style="color:gray;font-size:30px;padding-bottom:5px;opacity: 0.5;"></i><br><span >{{ $t('activity5') }}</span></span>
        <span id="extra"  class="kategorija"  @click="filter('extra')"><i class="fa-solid fa-plus" style="color:gray;font-size:30px;padding-bottom:5px;opacity: 0.5;"></i><br><span >{{ $t('activity6') }}</span></span>
        </div>
        </div>
        </div>
<br>
        <div class="row menu-items">
            <div :class="i.kategorija + ' card col-md-4 sve'" v-for="(i, key) in info">
  <img class="card-img-top" :src="i.img_path" alt="Card image cap" >
  <div class="card-body">
    <h5 class="card-title" > <span class="card-text vrsta" style="padding-right:5px;">{{i.vrsta}} </span> {{i.naslov}}</h5>
    <p class="card-text" v-html="i.kratakopis"></p>
    <p style="color:gray;opacity:0.8;cursor: pointer;" :id="'vise'+key.id" @click="viseopisa(key)">{{ $t('showmore') }}</p>
    <p class="card-text" :class="'opis'+key" style="display:none" v-html="i.opis"></p>
    <p class="card-text" :class="'opis'+key" style="display:none;font-weight:600;font-size: 16px;" v-html="i.dodatno"></p>
    

  </div>
</div>



</div>
        
        

        </div>
        </div>
        </section>
        </template>

        
  </Navigation>

  
  
  </template>
  
  <script>
  
  import axios from 'axios'
  import Navigation from '../components/Navigation.vue'
  export default {
  
    name: 'Activities',
    components: {
      Navigation
    },
  
    data(){
      return{
        info:[]
      }
    },
  
    mounted(){
      $('#preloader').remove();
    
        this.getInfo();

    },
  
  
    methods:{
        viseopisa(id){

            $('.opis'+id).toggle()
            if ($(".opis"+id).is(":hidden")) {
                $('#vise'+id).text(this.$t('showmore'))
            }
            else{
                $('#vise'+id).text(this.$t('showless'))
            }
 

        },

        async getInfo() {
        var lang = localStorage.getItem('lang')
        

await axios
  .get('/mpguest/activity/'+lang)
  .then(response => {
    this.info = response.data
    

    $('#preloader').hide()
  })
  .catch(error => {
    this.$root.toastnoti(this.$t('error'), error, "greska")
   
  })},


        filter(vrsta){
  
            $('.kategorija').css('opacity', '0.5').removeClass('tagsort-active');
            $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
            $('.card').hide()
            $('.'+vrsta ).show()


        },
        methodThatForcesUpdate(){
            
        }
    
    }
  }
  </script>

<style scoped>

.menu-tags span{
    display: inline-block;
}
.hero-animated {
    padding: 50px 0 0px; 
    min-height: 5vh;
}

section {
    padding: 0px 0; 

}

.vrsta{
    opacity: 0.7;
    color:var(--color-primary);
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
}

.card-title{

    font-weight: 800;
    color:#6c757d;
}

@media (min-width: 992px) {
 
}


</style>