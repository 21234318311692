<template><br>

    <div>
      <Navigation yvisina=-200 @reload-parent="methodThatForcesUpdate">
        <template #atag>
          <a></a>
      
        </template>
        <template #content>

          <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con  text-center wow bounceInUp" data-wow-duration="1s">
              <p class="position-relative oasisnaslov text-white" style="    font-size: 0.5rem !important;font-weight: 500 !important;">
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;border-radius: 20px;font-size: 15px"  @click="  this.$router.push('/oasis/')"> <i class="fa fa-arrow-left"></i> Back to Oasis</button>
                </p>
           
             
               <h1 class="position-relative lead oasisnaslov text-white" style="font-size: 2.5rem !important;font-weight: 700 !important;">Saltwater Oasis Wellness&Spa</h1>
               <p class="position-relative oasisnaslov text-white" style="    font-size: 1.25rem;font-weight: 700 !important;">Fitness<br><br>
              
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;"  @click="this.$root.scrollToSection('rez')">Your Reservations</button></p>
               


            </div>
         </div>
      </div>
        

   </section>
<br><br>


      
          <div class="darken-image  image-container">
        
       
</div>

<p style="font-size: 14px;padding-left:15px;padding-right: 15px;padding-bottom: 20px;" class="text-center"> <i class="fa-solid fa-person-circle-plus"></i> 
  You can book a fitness coach on tuesdays, thursdays and saturdays from 8:00-11:00. Simply book the hour
   here and send us a message on whatsapp (click the icon). Price:40€
  <i class="fa fa-whatsapp" style="font-size: 20px;padding-left: 10px;" @click="$router.go('https://wa.me/+385994820678')"></i></p>
 
  <p style="font-size: 14px;padding-left:15px;padding-right: 15px;padding-bottom: 20px;" class="text-center"> <i class="fa-solid fa-gift" style="color:red"></i> 
    4 hours for 20€. Reserve here and get discount while paying.
</p>
      
      <div class="container">
      <form  v-on:submit.prevent="getSport" style="padding: 20px;">
        <input type="date" class="form-control" name="date" min="2024-04-29"  v-model="form.datum" v-on:input="getSport"><br>
    
      </form>
    
    
      <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message" >Available times</div>

      
          <div class="row">
            <div  class="col-md-4 col-xl-3" v-if="pokazi">
                  <div class="card bg-c-tenis order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                              <h2 class="text-right">{{ $t('x45') }}</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
              
           
             
            <template v-if="datoteke" v-for="(item, index) in datoteke">
              
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ item"  >
                  <div class="card bg-c-tenis order-card" v-if="item>0">
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(index)" style="cursor:pointer;"></i>
         
                       
                          <span>

                              <h2 class="text-right" style="font-size: 22px">{{index}}h - {{ parseInt(index)+1}}h <span style="font-size: 15px;font-weight: bold;" >Free slots {{ item }}</span> <span style="font-size: 15px;font-weight: bold;">Price: 7€</span></h2>    
                            
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
            </template>
              
          <section id="rez">
              <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message" v-if="prijava">{{ $t('x40') }}</div>
            </section>
              <div class="col-md-4 col-xl-3" style="padding-top:5px;" v-if="prijava && !rezervacije.length">
                  <div class="card bg-c-green order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                            <h2 class="text-right">You have no reservations</h2>
                            <p style="font-size: 13px;">Pick an available slot and reserve fitness.</p>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>

              <div class="col-md-4 col-xl-3"  style="padding-top:5px;" v-for="i in rezervacije">
                  <div class="card bg-c-green order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-times-circle f-right"    style="cursor:pointer;color:red;" @click="cancelpotvrda(i[2])"></i>
    
                       
                          <span>
                            {{ i[1] }}
                            <h2 class="text-right">

                              {{ i[0] }}h - {{ i[0]+1 }}h
                            </h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      <div style="height:200px;"></div>
        </div>
      </div>
    
    
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
    
    <div class="footer-content">
      <div class="container">
        <div class="row">
    
          <div class="col-lg-12 col-md-12">
            <div class="footer-info">
              <h3>Saltwater Oasis Manicure and Pedicure</h3>
              <p>
       
            <p>At  Saltwater Oasis Wellness&Spa <br></p><br>
            <a href= "mailto: wellness@monperin.hr"> wellness@monperin.hr </a>
     
            
              </p>
            </div>
          </div>
    
    
          
    
          <div class="col-lg-4 col-md-12 footer-newsletter">
    
    
    
    
    </div>
    
    
    
    
        </div>
      </div>
    </div>
    
    
    </footer><!-- End Footer -->
    
        </template>
    
      </Navigation >
    
      <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">Reserve your slot</h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')" ></i>


        </div>
        <div class="modal-body">
          
          
          <form>
   
          <div><p>Fee can be payed at the spot<br>You picked {{form.terminsat}}h - {{parseInt(form.terminsat)+1}}h 
           
         </p>
          </div>
     

        </form>

        </div>
        <div class="modal-footer d-flex justify-content-center">
     
           <button  type="button" @click="rezerviraj" :disabled="buttonDisabled" class="btn btn-info f-left modalbutton" style="border:none;">Submit</button>
         
        </div>
      </div>
    </div>
  </div>


      <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('cancelres') }}</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>

            </div>
            <div class="modal-body">
              <p>{{ $t('cancelres2') }}</p>
            </div>
            <div class="modal-footer d-flex justify-content-center">
       <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;background-color: #dc3545;">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="reserveModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">Reserve</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('reserveModal')" ></i>

            </div>
            <div class="modal-body">
              <p>Since you are not logged in, please fill in this form:</p>
              <input class="form-control" type="text" placeholder="Your name or camp location...">
              <input class="form-control" type="email" placeholder="Email where we will send you confirmation">
            </div>
            <div class="modal-footer d-flex justify-content-center">
           <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      

    

      </div>
        </template>
        
     
        <script>
        import axios from 'axios'
        import Navigation from '../components/Navigation.vue'
        export default {
          name: 'Info',
          components: {
            Navigation
          },
        
          data(){
            return{
              pokazi:false,
                ruke:true,
              datoteke:null,
              rezervacije:[],
              id:"",
              canceldatum:"",
              cancelsat:"",
              navBarKey:0,
              buttonDisabled: false,
              prijava:false,
              email:"",

              form:{
                datum:new Date().toISOString().substr(0, 10),
                terminsat:"",

              
    
              },
            
            }
          },
          computed: {
    areAllTerminiZero() {
      if(this.datoteke && !this.datoteke.termini){
        console.log("all are 0d s")
        return true

      }
    
    }
  },

          mounted(){
            
            window.scrollTo(0, 0)
     
            this.platform = Capacitor.getPlatform()
            
            const preloader = document.querySelector('#preloader');
            this.prijava = this.$store.getters.isAuth;
            if(this.prijava){
              this.mojiTermini()
            }
    
            
            this.getSport()
          
        
          },
        
          methods:{

            async togglepolicy(tekst){
              $("#"+tekst).toggle();
            },
  
    
            methodThatForcesUpdate(key){
              
              this.mojiTermini()
              this.getSport()
        
          },
    
          async cancelpotvrda(id){
              this.id = id
    
              this.$root.otvoriform('deleteConfirmModal')
      
          },
    
    
           moguceodgoditi(datum, sat){
            const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
            const now = new Date();
            const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));
            
    
            if (diffInHrs > 2 && targetDate > now) {
              return true
            } else {
              return false
            }
    
      
          },
          async rezervirajpotvrda(sat){
              this.form.terminsat = sat
          
              this.$root.otvoriform('rezervirajModal')
      
          },
          async cancel(){
    
                var apilink = '/mpguest/odgodifitnes/'+this.id
               
    
      
              await axios
              
                .get(apilink)
                .then(response => {
                  this.$root.toastnoti(this.$t('success'), this.$t('odgodatermina'), "uspjeh")
                  this.rezervacije = this.rezervacije.filter(arr => arr[2] !== this.id);
                  $('#deleteConfirmModal').modal('hide')
        
          
                })
                .catch(error => {
                  this.$root.toastnoti(this.$t('error'), this.$t('greska'), "uspjeh")
                 
                })
      
          },
          async rezerviraj(){
            this.buttonDisabled = true
            this.form.vrsta = 1
            
          
              axios.post('/mpguest/rezervirajfitnes/', this.form)
            .then((res) => {
    
              

                  this.$root.toastnoti(this.$t('success'), "Reserved, see you.", "uspjeh")
                  this.$root.scrollToSection('rez')
                
                this.mojiTermini()
                
           
                this.buttonDisabled = false;
                this.getSport()
                    
              })
            .catch((error) => {
          
              this.buttonDisabled = false;
              if (error.response.status === 401) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
              this.$router.push('/bye/')
            }
            else{
              this.$root.toastnoti(this.$t('error'), this.$t('greska'), "error")
            }
             
              
            })
    
            $('#rezervirajModal').modal('hide')
      
          },
         
          async getSport() {
            console.log("aaaaaaaaaaa")
      this.datoteke = {}
      const inputDate = new Date(this.form.datum); // replace with your input date
      const now = new Date();

      const hasAlreadyPassed = inputDate < now &&
        (inputDate.getFullYear() < now.getFullYear() ||
          (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
          (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));


      if (hasAlreadyPassed) {
        this.$root.toastnoti(this.$t('error'), this.$t('prosaodatum'), "error")
        $("#preloader").hide();
        return false;
      }
      if (!this.form.datum) {
        this.$root.toastnoti(this.$t('error'), this.$t('unesitedatum'), "error")
        $("#preloader").hide();
        return false;
      }
      const targetDate = new Date('2024-06-04');
      if (inputDate.toDateString() === targetDate.toDateString()) {
                    this.$root.toastnoti("Info", "We are closed on 4th of june", "info")
                    $("#preloader").hide()
                    return false;
                    }
  


      await axios
        .get('/mpguest/oasis/fitnestermini/' + this.form.datum)
        .then(response => {
            console.log("bb")
          if(response.data.poruka=="ne"){
      
      this.$root.toastnoti(this.$t('error'), "Pick a date before " + response.data.limit, "error")
    
      return;

    }
          this.datoteke = response.data.termini
          if(response.data.termini == null){
 
            this.pokazi = true
          }
          else{
            this.pokazi = false
          }
          console.log(response.data)
          
          $("#preloader").hide();


        })
        .catch(error => {
          this.$root.toastnoti(this.$t('error'), this.$t('greska'), "greska")


        })

    },
    async mojiTermini() {
      await axios
        .get('/mpguest/mojiterminifitnes/')
        .then(response => {
          this.rezervacije = response.data.termini
          console.log(this.rezervacije)
          
          $("#preloader").hide();


        })
        .catch(error => {
          this.$root.toastnoti("Log in", this.$t('loginbitno'), "greska")
          $("#preloader").hide();

        })

    },

            filter(vrsta){
  
        $('.kategorija').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
        
        console.log("check")

        this.form.vrsta = vrsta


    },
    minute(vrsta){
        $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
  if(vrsta=="trideset"){
    this.form.trideset = true
  }
  else{
    this.form.trideset=false
  }

  $('.'+vrsta ).show()


},
vrstaime(vrsta){
        switch (vrsta) {  

      case 0 : return 'Back, neck, head and shoulders';
      case 1 : return 'Foot Reflexolog';
      case 2: return 'Coconut Massage';
      case 3: return 'Aroma massage';
      case 5: return 'Thai oil Massage';
      case 4: return 'Thai traditional massage';
      case 6: return 'Coconut oil massage';
      default: return 'Foot Reflexolog';
    }
    





},
nemasdostupno(){

if(this.datoteke && this.datoteke.termini){
  console.log(Object.values(this.datoteke.termini).every(value => value === 0))
    return Object.values(this.datoteke.termini).every(value => value === 0);
}

 
},
TimeString(time){

var time = new Date('2023-01-01T' + time);
var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
},
TimeString2(time, broj){

var time = new Date('2023-01-01T' + time);
time.setMinutes(time.getMinutes() + broj);

var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
}
          }
        }
        </script>
      
      
      <style>
  
  .image-container img {

    display: block;
    width: 100%;
    min-height: 300px;
    max-height: 5%;
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
  
  }
.darken-image {
  
    position: relative;
    display: inline-block;
  }

  .darken-image img {
    display: block;
  }
  .darken-image .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
      
      .btn-getstarted {
          font-size: 16px;
          color: var(--color-white);
          background: var(--color-primary);
          padding: 8px 23px;
          border-radius: 4px;
          transition: 0.3s;
          font-family: var(--font-secondary);
          border:none;
    
      
      
      }
    
      
      .order-card {
          color: #fff;
      }
      
      .bg-c-blue {
          background: linear-gradient(45deg,#4099ff,#73b4ff);
      }
      
      .bg-c-green {
      
          background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
          
      }
      
      .bg-c-yellow {
          background: linear-gradient(45deg,#FFB64D,#ffcb80);
      }
      
      .bg-c-pink {
          background: linear-gradient(45deg,#FF5370,#ff869a);
      }
      
      
      .card {
          border-radius: 5px;
          -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          border: none;
          margin-bottom: 30px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
      }
      
      .card .card-block {
          padding: 25px;
      }
      
      .order-card i {
          font-size: 26px;
      }
      
      .f-left {
          float: left;
      }
      
      .f-right {
          float: right;
      }
      
      
      .bg-c-tenis {
          background: linear-gradient(45deg,#bed6db,#44a3b6);
      }
      </style>
      
      <style scoped>
        
      .hero-animated {
          padding: 50px 0 0px; 
          min-height: 30vh;
      }
      
      section {
          padding: 0px 0; 
      
      }
    
      .hero-animated p {
        color: rgba(var(--color-secondary-rgb),.8);
        margin: 0 0 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    
    @media (max-width: 640px){
      .hero-animated p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    }
    .menu-tags span{
      display: inline-block;
    }
    
      
      </style>
      
    <style scoped>
    .btn-outline-light:hover {

    background-color: transparent;
    border-color: #f8f9fa;
}

    .oasisnaslov{
      font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
    }
    
    .sub-banner-sec {
  background: url(../assets/fitnes.jpg) no-repeat center;
  
  background-size: cover;
  height: 561px;


}

    .hero-animated{
      min-height: 15vh;
      padding: 0px
    }
    </style>