export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen "])},
  "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machen Sie Ihren Aufenthalt noch besser"])},
  "reportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie ein Defekt"])},
  "reporttekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Klicken auf die Schaltfläche „Erstellen“ auf der Registerkarte „Bericht“ können Sie ein Defekt melden. Unsere Mitarbeiter werden umgehend benachrichtigt und melden sich schnellstmöglich bei Ihnen."])},
  "infonaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
  "infotekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie die neueste Informationsbroschüre mit Veranstaltungen, dem Fahrplan der Touristenzüge und den Öffnungszeiten der Einrichtungen herunter. <br>Sehen Sie sich die Aktivitäten im Camp und in der Umgebung an und erhalten Sie Benachrichtigungen vom Personal."])},
  "deliverynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung"])},
  "deliverytekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Mahlzeit aus den Restaurants Mol Picio oder Paleo Park und wir liefern sie direkt in Ihr Mobilheim."])},
  "rezervacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierungen"])},
  "rezervacijetekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Sportart, wählen Sie Ihre Zeit und genießen Sie."])},
  "morenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Und mehr..."])},
  "moretekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen Sie uns Ihre Meinung in Umfragen mit und werfen Sie einen Blick auf unsere Souvenirs"])},
  "getaccnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch kein Gastkonto?"])},
  "getaccopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie auf der Registerkarte Erste Schritte die erforderlichen Informationen ein, um einen Benutzernamen und ein Kennwort zu erhalten. Alle Informationen finden Sie auf dem Check-in-Blatt."])},
  "getaccbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "footercontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten von uns"])},
  "Souvenirs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andenken"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe melden"])},
  "Restaurnats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurants"])},
  "Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wagen"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "poruke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilungen"])},
  "startmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnwagen"])},
  "startpc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping-Parzelle"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung"])},
  "logininfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Zugangsdaten?"])},
  "downloadare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloadbereich"])},
  "downloadopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Informationen sind nur einen Klick entfernt"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmabende, Poolpartys und mehr"])},
  "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahnhöfe und Fahrplan eines Zuges, der das Lager umrundet"])},
  "trainnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugfahrplan"])},
  "workingtimenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
  "workingtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten in beiden Bereichen des Camps"])},
  "freshmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrplan und weitere Infos zum Frischmarkt"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte von Kamp"])},
  "farewell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebewohl"])},
  "farewell_opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Aufenthalt scheint zu Ende zu sein. Nochmals vielen Dank für Ihren Aufenthalt bei uns und wir hoffen, Sie in Zukunft wieder zu sehen."])},
  "farewell_gumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort geändert? Log in"])},
  "profiledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profildetails"])},
  "notifikacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Benachrichtigungen werden hier angezeigt"])},
  "suveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen Sie Souvenirs an der Rezeption oder im Paleo Park."])},
  "suveniropis1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinosaurier plüschig"])},
  "noreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre gemeldeten Aufgaben werden angezeigt<br>Etwas funktioniert nicht richtig?<br>Erstellen Sie eine Aufgabe für unsere Mitarbeiter und sie werden so schnell wie möglich eintreffen"])},
  "searchjela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Namen (alle Kategorien)..."])},
  "restoranopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlose und schnelle Lieferung zu Ihnen nach Hause, während Sie Ihren Urlaub genießen"])},
  "cartgumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
  "deliveryinformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferinformationen"])},
  "deliverydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung für"])},
  "Preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbereiten"])},
  "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit"])},
  "Taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergriffen"])},
  "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliefert"])},
  "sportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multifunktionaler Sportplatz"])},
  "sportopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Datum und wählen Sie eine beste Zeit für Sie."])},
  "extrainfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatzinformation"])},
  "reportnapomena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir berücksichtigen Ihren gewünschten Zeitpunkt und werden aufgrund des Arbeitsaufwands unser Bestes tun, um Ihrem Wunsch nachzukommen."])},
  "extrainfosuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen (z. B. Zeit oder anderer Ort)"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "loggedsucess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind eingeloggt"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wurden ausgeloggt"])},
  "loggeror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Daten"])},
  "greska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigung, es ist ein Fehler aufgetreten, versuchen Sie es später oder kontaktieren Sie uns"])},
  "profilesave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilinformationen gespeichert"])},
  "incorrectnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie, ob die Telefonnummer korrekt ist"])},
  "verifynumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie die Telefonnummer"])},
  "profilerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Bestätigen der Telefonnummer ist ein Fehler aufgetreten"])},
  "krivikod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falscher Code, bitte wiederholen oder Telefonnummer überprüfen"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "availabletimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Zeiten für das gewählte Datum:"])},
  "nemaslobodnog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine verfügbaren Zeiten..."])},
  "nemarezerviranog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine reservierten Zeiten..."])},
  "rezervacijesporta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Reservierungen:"])},
  "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserviert, bis später"])},
  "prosaodatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das eingegebene Datum ist bereits vergangen"])},
  "odgodatermina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zeit ist storniert"])},
  "zdstatus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten"])},
  "zdstatus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergriffen"])},
  "zdstatus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeiten daran"])},
  "zdstatus4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
  "izbrisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "izbrisizadatak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Aufgabe wirklich löschen?"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe melden"])},
  "reportinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen"])},
  "reportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte Newsletter per E-Mail erhalten"])},
  "jelokategorija1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorspeisen"])},
  "jelokategorija2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühstück"])},
  "jelokategorija3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptsächlich"])},
  "jelokategorija4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachspeisen"])},
  "jelokategorija5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränke"])},
  "razumijem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich verstehe"])},
  "extrainfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatzinformation"])},
  "suvenirdetalji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Andenkenlieferung"])},
  "nemasuvenira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktive Lieferung, holen Sie sich Souvenirs aus dem Shop"])},
  "taskreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind auf dem weg"])},
  "checkboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie das Kontrollkästchen"])},
  "dostavavecpostoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bereits eine aktive Lieferung"])},
  "uspjehsuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Lieferung ist unterwegs"])},
  "emptycart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb ist leer"])},
  "taskdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe gelöscht"])},
  "unesitedatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie zuerst das Datum ein"])},
  "breakfast1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeit"])},
  "breakfast2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränke"])},
  "breakfast3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hinzufügen Auf"])},
  "cartsuvenir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenirwagen"])},
  "cancelres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierung stornieren"])},
  "cancelres2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie es wirklich stornieren?"])},
  "reservesport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportplatz reservieren"])},
  "reservesport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie es reservieren möchten?"])},
  "tenisnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennisplatz"])},
  "tenissport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Datum und wählen Sie eine beste Zeit für Sie."])},
  "main1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorspeisen"])},
  "main2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptsächlich"])},
  "main3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachspeisen"])},
  "main4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränke"])},
  "credentialsmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holen Sie sich Zugangsdaten für Mobilheime"])},
  "credentialspc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holen Sie sich Zugangsdaten für den Stellplatz"])},
  "howcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen finden Sie auf dem Check-in-Papier"])},
  "mh1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilheim nummer *"])},
  "pc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellplatznummer *"])},
  "zona1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone *"])},
  "camp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone *"])},
  "od1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankunftsdatum *"])},
  "do1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreisedatum *"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie ein Passwort fest oder lassen Sie es leer, um ein automatisch generiertes zu erhalten *"])},
  "submitcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "logout1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername"])},
  "password1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "tel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer *"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "notifikacije": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "notifikacijeopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neben gewöhnlichen Benachrichtigungen erhalten Sie möglicherweise wichtige Informationen über das Camp"])},
  "reportmodal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe melden"])},
  "reportmodal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabentyp"])},
  "reportmodal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z. B. Gewünschtes Timing"])},
  "sport1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierungen können bis 2 Stunden vor Beginn storniert werden"])},
  "sport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kosten können beim Check-out mit dem Rest Ihres Aufenthalts bezahlt werden"])},
  "multi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein oder mehrere Felder aus"])},
  "multi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
  "kreiranzd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe erstellt"])},
  "surveynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfragen"])},
  "surveyopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Feedback ist wichtig, um sicherzustellen, dass wir den Service bieten, den Sie benötigen.<br> Wir würden uns freuen, wenn Sie an einer Umfrage zu Ihrem Aufenthalt bei uns teilnehmen würden."])},
  "s1naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufenthalt im Mobilheim"])},
  "s1opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzählen Sie uns mehr über Ihren Aufenthalt im Mobilheim."])},
  "s2naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellplatzaufenthalt"])},
  "s2opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzählen Sie uns mehr über Ihren Aufenthalt auf dem Campingplatz."])},
  "s3naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag bleiben"])},
  "s3opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzählen Sie uns mehr über Ihren Aufenthalt im Allgemeinen."])},
  "Booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broschüre"])},
  "payement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lieferung beginnt, sobald wir die Zahlung erhalten haben"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerkonto"])},
  "Dodano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahlzeit zum Einkaufswagen hinzugefügt"])},
  "loginbitno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich an, um Reservierungen vorzunehmen"])},
  "pickfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie zuerst ein Feld"])},
  "Maknuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernt"])},
  "emptydeliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Lieferungen werden hier angezeigt, erstellen Sie eine, indem Sie Artikel aus Restaurants auswählen und den Schritten im Warenkorb folgen"])},
  "passwordunos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional* Wählen Sie ein Passwort oder lassen Sie sich automatisch eins generieren"])},
  "activity1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "activity2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaß"])},
  "activity3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kultur"])},
  "activity4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "activity5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurse"])},
  "activity6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
  "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrungen"])},
  "exploreactivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkunden Sie die Aktivitäten, die wir in Mon Perin und Bale anbieten"])},
  "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeig mehr..."])},
  "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige weniger..."])},
  "profil1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkunft*"])},
  "profil2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
  "deliveryopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Lieferung auch für später planen"])},
  "bikepaths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrradwege"])},
  "monperin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Perin"])},
  "bale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ballen   Bale/Valle"])},
  "paleopark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paleo-Park"])},
  "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping - Camping Home und Camping Villa"])},
  "bale1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuchen Sie Bale, es wird Sie mit unberührter Natur, Ruhe, kristallklarem Meer, aber auch mit zahlreichen Veranstaltungen erobern"])},
  "camping1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecken Sie unsere Villen"])},
  "paleopark1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisliste Paleo Park (kostenlos für Gäste)"])},
  "bikepaths1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ballen sind eine echte Herausforderung oder ein Abenteuer für alle, die sich aktiv erholen möchten."])},
  "monperin1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahren Sie mehr über Mon Perin"])},
  "cijenik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis: 4€ pro Stunde<br>Tennisschläger und -Bälle können vor Ort ausgeliehen werden"])},
  "cijenik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis: 4€ pro Stunde<br>Ausrüstung vor Ort mietbar"])},
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Zeitpunkt aus, zu dem Sie mit der Lieferung beginnen möchten. Je nach Tageszeit ist nur ein Restaurant verfügbar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurants sind derzeit nicht geöffnet"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie einen Zeitpunkt in der Zukunft aus"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Zeit zwischen 7 und 21 Uhr"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Lieferzeit für die Lieferung beträgt 1 Tag"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie zuerst die Telefonnummer auf der Profilseite ein"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können unter der Telefonnummer kontaktiert werden, die Sie auf der Profilseite eingegeben haben."])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir übernehmen die Lieferung zu Ihrem Mobilheim"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ihrem Warenkorb ist kein Mittagessen ausgewählt"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ihrem Warenkorb ist kein Frühstück ausgewählt"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann möchten Sie mit der Lieferung beginnen?"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für später planen?"])},
  "salespolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde muss eine Reservierung des Sportplatzes über den entsprechenden Kanal vornehmen, beispielsweise per Telefonanruf, über eine Internetplattform oder einen persönlichen Besuch in der Verkaufseinrichtung. Die Reservierung des Sportplatzes muss vom Verkäufer oder der verantwortlichen Person in bestätigt werden Gebühr für Reservierungen. Der Käufer verpflichtet sich, den entsprechenden Betrag für das Sportreservierungsfeld gemäß dem vereinbarten Preis zu zahlen. Zu den Zahlungsmethoden können Barzahlung, Kartenzahlung oder elektronische Zahlung gemäß den Bedingungen des Verkäufers gehören. Der Käufer muss die Zahlung im Voraus oder innerhalb von 24 Stunden leisten Die vereinbarte Zeit vor dem Datum der Sportplatzreservierung. Bei Nichtzahlung innerhalb der vereinbarten Zeit kann die Sportplatzreservierung storniert werden. Beschreibung der Art und Frist der Produktlieferung oder Leistungserbringung: Der Sportplatz steht zur Verfügung zur Nutzung am vereinbarten Datum und zur vereinbarten Uhrzeit. Der Kunde erhält eine Reservierungsbestätigung, die genaue Angaben zu Datum, Uhrzeit und Barcode für die Öffnung des Sportplatzes enthält. Lieferkosten: Bei Sportplatzreservierungen fallen keine Lieferkosten an denn die Reservierung bezieht sich auf die Nutzung des Raums und nicht auf die physische Lieferung des Produkts. Vertragsbestimmungen: Die Reservierung des Sportplatzes gilt als gültige vertragliche Verpflichtung zwischen dem Käufer und dem Verkäufer. Der Käufer verpflichtet sich, die Regeln einzuhalten und Vom Verkäufer angegebene Nutzungsbedingungen des Sportplatzes. Allgemeine Geschäftsbedingungen: Allgemeine Geschäftsbedingungen gelten, sofern sie Vertragsbestandteil sind, für die Reservierung des Sportplatzes und sind für beide Parteien verbindlich. "])},
  "conversionpolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Zahlungen erfolgen in kroatischer Währung. Der Betrag, mit dem Ihre Kreditkarte belastet wird, ergibt sich aus der Umrechnung des Preises in einer anderen Währung in EUR gemäß dem aktuellen Wechselkurs der Kroatischen Nationalbank. Bei der Belastung Ihrer Kreditkarte wird der gleiche Betrag entsprechend dem Wechselkurs der Kartengesellschaften in Ihre Landeswährung umgerechnet. Aufgrund dieser Umrechnung besteht die Möglichkeit einer geringfügigen Abweichung vom Originalpreis, der auf unserer Website aufgeführt ist."])},
  "datapolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest verpflichtet sich, die personenbezogenen Daten der Kunden so aufzubewahren, dass nur die notwendigen Grunddaten über die Kunden/Benutzer erfasst werden, die für die Erfüllung unserer Verpflichtungen erforderlich sind; informiert Kunden über die Verwendung der gesammelten Daten und gibt ihnen regelmäßig die Möglichkeit, über die Verwendung ihrer Daten zu entscheiden, einschließlich der Möglichkeit, zu entscheiden, ob ihr Name aus Listen für Marketingkampagnen entfernt werden soll oder nicht. Alle Benutzerdaten werden streng vertraulich behandelt und stehen nur den Mitarbeitern zur Verfügung, die diese Daten für die Ausübung ihrer Tätigkeit benötigen. Alle Mitarbeiter von Terra Vallis und Geschäftspartner sind für die Einhaltung der Grundsätze des Datenschutzes verantwortlich"])},
  "securitypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest nutzt Monri WSPay für Online-Zahlungen. Monri WSPay ist ein sicheres System für Online-Zahlungen, Echtzeitzahlungen, Kredit- und Debitkarten und andere Zahlungsmethoden. Monri WSPay bietet Kunden und Händlern eine sichere Eingabe und Übertragung der eingegebenen Kartendaten, was durch das PCI DSS-Zertifikat von Monri WSPay bestätigt wird. Monri WSPay verwendet ein SSL-Zertifikat mit 256-Bit-Verschlüsselung und das kryptografische Protokoll TLS 1.2 als höchste Schutzstufe bei der Registrierung und Übertragung von Daten."])},
  "salespolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufspolitik"])},
  "conversionpolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konvertierungsrichtlinie"])},
  "datapolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtlinie zu personenbezogenen Daten"])},
  "securitypolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitspolitik"])},
  "tenisopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht eingeloggt, können aber dennoch Sportplatzreservierungen per Online-Zahlung vornehmen <br>Der Preis pro Stunde für den Tennisplatz beträgt 13 €"])},
  "multiopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht eingeloggt, können aber dennoch Sportplatzreservierungen per Online-Zahlung vornehmen<br>Der Preis für 1 Stellplatz pro Stunde beträgt 13€"])},
  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione dei dati ai sensi del regolamento generale sulla protezione dei dati del Parlamento europeo e del Consiglio n. 2016/679- Regolamento e attuazione del GDPR WSPay, in qualità di responsabile dell'autorizzazione e del pagamento effettuato con carte di credito, utilizza i dati personali in qualità di responsabile del trattamento ai sensi del Regolamento generale sulla protezione dei dati del Parlamento europeo e del Consiglio n. 2016/679, e conforme al Regolamento PCI DSS Livello 1 per il trasferimento dei dati. WSPay utilizza la crittografia SSL a 256 bit e il protocollo crittografico TLS 1.2 come standard di protezione più elevati per l'immissione e il trasferimento dei dati. I dati personali utilizzati ai fini dell'autorizzazione e del pagamento sono considerati dati riservati. I seguenti dati personali del cliente sono necessari per l'adempimento del contratto (autorizzazione e pagamento): Nome e Cognome E-mail Numero di telefono Indirizzo Città CAP Nazione Tipo di carta di credito Numero di carta di credito Data di scadenza (carta di credito) Numero CVV della carta di credito WSPay fa non elaborare o utilizzare questi dati personali se non allo scopo di adempiere al contratto, l'autorizzazione e il pagamento. WSPay garantisce di soddisfare i requisiti stabiliti dalle normative applicabili in materia di protezione dei dati personali, per i responsabili del trattamento dei dati personali, in particolare adottando tutte le necessarie misure tecniche, organizzative o di sicurezza confermate dal certificato PCI DSS di livello 1."])},
  "salespolicy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Bestellung: Frühstück und Mittagessen können zu einem bestimmten Zeitpunkt bestellt werden. Lieferungen können für einen späteren Zeitpunkt im Voraus geplant werden. 2. Lieferzeit: Die Lieferung erfolgt spätestens eine Stunde nach Lieferbeginn. Sollten Lieferungen für einen späteren Zeitpunkt geplant sein, werden wir uns bemühen, diese dann auszuliefern. 3. Zahlungsarten: Wir akzeptieren bei Beginn der Lieferung die Zahlung per Karte (Debit/Kredit). Es ist möglich, über mobile Anwendungen oder Internetbanking zu bezahlen. 4. Lieferbeschreibung: Das Essen wird innerhalb des Camps direkt zum Mobilheim des Gastes geliefert. Die Lieferung erfolgt durch unser Personal. 5. Versandkosten: Die Versandkosten werden bei der Bestellung deutlich angegeben. In einigen Fällen kann der Versand abhängig von der Mindestbestellmenge kostenlos sein. 6. Vertragsbestimmungen: Mit jeder Bestellung kommt ein Vertrag zwischen dem Kunden und dem Lieferdienst zustande. Mit der Auftragsbestätigung erkennt der Kunde die Lieferbedingungen an. 7. Recht des Käufers, den Vertrag zu kündigen: Der Käufer hat kein Recht, den Vertrag zu kündigen. Wenn bei der Lieferung ein Fehler auftritt, wird das Geld an den Käufer zurückerstattet."])},
  "x1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köstliches Essen wird zu Ihrer Unterkunft geliefert. "])},
  "x2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittagessen"])},
  "x3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferungen"])},
  "x10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um zu unserem Menü weitergeleitet zu werden!"])},
  "x11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühstück"])},
  "x4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denken Sie an ein tolles Frühstück für morgen? "])},
  "x5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um zur Vorbestellung der Frühstücksbox weitergeleitet zu werden!"])},
  "x6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie jetzt Ihre Sporteinheit und bereiten Sie sich darauf vor, mit jedem Schwung, Schlag oder Tor unvergessliche Erinnerungen zu schaffen. "])},
  "x7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportreservierungen"])},
  "x8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massagereservierungen"])},
  "x9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie alle Ihre Sorgen verschwinden. "])},
  "x12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 € pro Stunde werden zum Gesamtpreis des Aufenthalts hinzugerechnet und können beim Check-out bezahlt werden. <br><span >Stornierung bis 2 Stunden vor Beginn möglich. <br>Scrollen Sie nach unten zu Ihren Reservierungen und klicken Sie auf die Schaltfläche „X“, um sie zu stornieren</span>"])},
  "x13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Mehrseillängenplatz bietet eine große Auswahl an Aktivitäten für alle Interessen. "])},
  "x14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir machen es für Sie einfach, Kontakt aufzunehmen. "])},
  "x15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entspannen Sie sich nach einem Tag voller Sport und Spaß bei einer wohltuenden Massage. <br>Die Gebühr kann vor Ort bezahlt werden"])},
  "x16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massagezelt in San Polo neben der Babaloo-Bar. <br>Die Gebühr kann vor Ort bezahlt werden"])},
  "x17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denken Sie an ein tolles Frühstück für morgen? "])},
  "x18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühstücksbox besteht aus:"])},
  "x19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbestellungen für den nächsten Morgen sind täglich bis möglich"])},
  "x20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung 8-9 Uhr."])},
  "x21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Kartons möchten Sie bestellen? "])},
  "x22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit sind nur Barzahlungen an das Personal möglich."])},
  "x23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Klicken auf „Erstellen“ bestätigen Sie den Empfang "])},
  "x24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühstücksbox"])},
  "x25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["morgen, am Morgen von"])},
  "x26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Wenn Sie die Bestellung stornieren oder die Anzahl der Kartons ändern möchten, zögern Sie bitte nicht, uns im Chat zu kontaktieren. <br>Derzeit akzeptieren wir nur Barzahlungen an das Lieferpersonal"])},
  "x27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie die Frühstückslieferung"])},
  "x28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf die Schaltfläche „Später planen“, um es für später vorzubestellen"])},
  "x29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denken Sie an ein tolles Essen?  "])},
  "x30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere das"])},
  "x31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrollen Sie nach unten zu Ihren Reservierungen und klicken Sie auf das Barcode-Symbol, um einen eindeutigen Barcode zu erhalten. Verwenden Sie ihn für den Zutritt."])},
  "x32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühstück vorbestellen"])},
  "x33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittellieferservice"])},
  "x34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittagsmenü"])},
  "x35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferungen"])},
  "x36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennis"])},
  "x37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisportplatz"])},
  "x38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage"])},
  "x39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Termine:"])},
  "x40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Reservierungen:"])},
  "x41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode für den Eingang"])},
  "x42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 € werden zu Ihrem Gesamtaufenthalt hinzugefügt"])},
  "x43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbestellung für den Vormittag"])},
  "x44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginnen Sie mit der Lieferung"])},
  "x45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Verfügbarkeit"])},
  "x46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezeption"])},
  "freetenis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gäste frei"])},
  "myrez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Reservierungen"])}
}