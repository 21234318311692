<template>

  <div class="container" style="padding-top: 100px;">
      <div class="dots">
          <div class="dot dot-1"></div>
          <div class="dot dot-2"></div>
          <div class="dot dot-3"></div>
      </div>
  
      
  </div>
  <p class="text-center" style="color:gray;">If page doesn't redirect you to payment terminal, please try reloading it.</p>
  </template>
  
  
  <script>
  import axios from 'axios'
  
  export default {
  
    name: 'Info',
  
  
    data(){
      return{
          shopid:"",
          signature:"",
          total:"",
          cartid:"",
      
      }
    },
  
    mounted(){
      this.shopid = this.$route.params.shopid;
      this.signature = this.$route.params.signature;
      this.total = this.$route.params.total;
      this.cartid = this.$route.params.cartid;
  
  
      this.openform()
     
    },
  
  
    methods:{
  
     
  
      openform() {
  
      const formURL = 'https://form.wspay.biz/authorization.aspx';
      const formParams = {
      ShopID: this.shopid,
      lang:"EN",
      ShoppingCartID: this.cartid,
      Version: '2.0',
      TotalAmount: this.total,
      Signature: this.signature,
      ReturnURL: 'https://mpguest.com/uspjeh/0/restoran/0',
      CancelURL: 'https://mpguest.com/cancel/0/',
      ReturnErrorURL: 'https://mpguest.com/error/0',
      };
  
      const form = document.createElement('form');
      form.name = 'pay';
      form.method = 'POST';
      form.action = formURL;
  
      for (const paramName in formParams) {
      if (formParams.hasOwnProperty(paramName)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = paramName;
          input.value = formParams[paramName];
          form.appendChild(input);
      }
      }
      $("#preloader").hide();
      document.body.appendChild(form);
      form.submit(); 
  
  
  
      },
    
  
    }
  }
  </script>
  
  
  
  <style scoped>
  
  .container {
    display: flex;
    justify-content: center;
  }
  .dots {
    position: relative;
    width: 150px;
    height: 50px;
    transform: scale(0.25, 0.25);
  }
  .dot {
    width: 53px;
    height: 53px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    border-radius: 50%;
    background-color: #0082c3;
  }
  .dot-1 {
    animation: animation-dots 1.5s infinite linear 1s;
  }
  .dot-2 {
    animation: animation-dots 1.5s infinite linear 0.5s;
  }
  .dot-3 {
    animation: animation-dots 1.5s infinite linear;
  }
  @-moz-keyframes animation-dots {
    0% {
      left: -150px;
      opacity: 0;
    }
    20% {
      left: 0px;
      opacity: 1;
    }
    80% {
      left: 100px;
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: 200px;
    }
  }
  @-webkit-keyframes animation-dots {
    0% {
      left: -150px;
      opacity: 0;
    }
    20% {
      left: 0px;
      opacity: 1;
    }
    80% {
      left: 100px;
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: 200px;
    }
  }
  @-o-keyframes animation-dots {
    0% {
      left: -150px;
      opacity: 0;
    }
    20% {
      left: 0px;
      opacity: 1;
    }
    80% {
      left: 100px;
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: 200px;
    }
  }
  @keyframes animation-dots {
    0% {
      left: -150px;
      opacity: 0;
    }
    20% {
      left: 0px;
      opacity: 1;
    }
    80% {
      left: 100px;
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: 200px;
    }
  }
  
  </style>