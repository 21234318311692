<template>

<Navigation yvisina=-200 >
  <template #atag>
    <div class="nav-wrapper">
  <div class="sl-nav">

    <ul v-if="jezik">
      <li><img :src="require(`@/assets/${this.jezik}.svg`)"  @click="openjezik()" class="jezik" id="en" style="width:20px;height:15px;cursor:pointer;">&nbsp;<b style="font-size: 15px;color:var(--color-secondary);" @click="openjezik()">{{jezikduzi(jezik)}}</b> <i @click="openjezik()" class="fa fa-angle-down" aria-hidden="true"></i>
        <div class="triangle"></div>
        <ul id="jezikul">
          <li @click="changeJezik('en')"><img src="@/assets/en.svg" class="jezik" id="en" style="width:15px;height:15px;cursor:pointer;" >&nbsp; <span class="active">English</span></li>
          <li  @click="changeJezik('hr')"><img src="@/assets/hr.svg" class="jezik" id="hr" style="width:15px;height:15px;cursor:pointer;border-radius: 50%;">&nbsp; <span class="active">Hrvatski</span></li>
          <li @click="changeJezik('de')"><img src="@/assets/de.svg" class="jezik" id="de" style="width:15px;height:15px;cursor:pointer;border-radius: 50%;" >&nbsp; <span class="active">Deutsch</span></li>
          <li @click="changeJezik('it')"><img src="@/assets/it.svg" class="jezik" id="it" style="width:15px;height:15px;cursor:pointer;border-radius: 50%;" >&nbsp; <span class="active">Italiano</span></li>
        </ul>
      </li>
    </ul>
  </div>
</div>
  </template>

  <template #content>

    
    <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con text-white text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative lead" style="font-size: 3.5rem !important;font-weight: 700 !important;">Mon Perin</h1>
               <p class="position-relative naslov" style="    font-size: 1.25rem;font-weight: 700 !important;">Discover Hidden Beauties</p>
            </div>
         </div>
      </div>
        

   </section>
<br><br>

    <main id="main">

      <!-- ======= Featured Services Section ======= -->
      <section id="featured-services" class="featured-services">
        <div class="container">


          <div class="row gy-4">
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400" style="">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa-solid fa-pizza-slice icon" style="color:orange;"></i>&nbsp;<i class="fa-solid fa-bacon icon" style="color:lightblue;"></i></div>
                <h4 @click="$router.push('/restaurants/main/')"><a class="stretched-link"><b style="color:orange">Food Delivery, From Breakfast to Dinner</b>  </a></h4>
                <p>Get fresh food directly to your accommodation</p>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="200">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa-solid fa-dumbbell icon"></i> &nbsp;<i class="fa-solid fa-spa icon"></i>&nbsp;<i class="fa-solid fa-scissors   icon"></i></div>
                <h4 @click="$router.push('oasis')"><a class="stretched-link">Saltwater Oasis & Spa</a></h4>
                <p>Everything you need in one place.</p>
              </div>
            </div>
            
            <div class="col-xl-4 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="200">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa-solid fa-circle-info icon"></i></div>
                <h4 @click="$router.push('info')"><a class="stretched-link">Info</a></h4>
                <p v-html="$t('infotekst')"></p>
              </div>
            </div>
<!--
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400" style="">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa-solid fa-pizza-slice icon" style="color:orange;"></i></div>
                <h4 @click="$router.push('/restaurants/main/')"><a class="stretched-link"><b style="color:orange">{{ $t('x2') }} </b>  {{ $t('x3') }}  </a></h4>
                <p>{{ $t('x1') }} <b style="color:#0ea2bd;">{{ $t('x10') }}</b></p>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="400" style="">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa-solid fa-bacon icon" style="color:#0CC0DF;"></i></div>
                <h4 @click="$router.push('/restaurants/breakfast/')"><a class="stretched-link" ><b style="color:#0CC0DF">{{ $t('x11') }} </b> {{ $t('x3') }}  </a></h4>
                <p>{{ $t('x4') }}   <br><b style="color:#0ea2bd;">{{ $t('x5') }}  </b></p>
              </div>
            </div>

          -->

            

            <div class="col-xl-4 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="600">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa-solid fa-volleyball icon"></i></div>
                <h4 ><a @click="otvoritenis" class="stretched-link">{{ $t('x7') }}  </a></h4>
                <p>{{ $t('x6') }}  </p>
              </div>
            </div>
            <!--

            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="600">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa-solid fa-spa icon"></i></div>
                <h4><a @click="otvorimasaze" class="stretched-link">{{ $t('x8') }}  </a></h4>
                <p>{{ $t('x9') }}  
                 </p>

              </div>
            </div>
          -->



            <div class="col-xl-4 col-md-6 d-flex" data-aos="zoom-out">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa fa-users icon"></i></div>
                <h4 @click="$router.push('/report/')"><a class="stretched-link">{{ $t('reportnaslov') }}</a></h4>
                <p>{{ $t('reporttekst') }}</p>
              </div>
            </div>

     

          </div>

        </div>
      </section>
<br><br>
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">

          <div class="section-header">
            <h2>{{ $t('morenaslov') }} </h2>
            <p>{{ $t('moretekst') }}</p>
          </div>

        </div>
      </section><!-- End About Section -->

      <!-- ======= Clients Section ======= -->


      <!-- ======= Call To Action Section ======= -->
      <section id="cta" class="cta" v-show="!prijava">
        <div class="container" data-aos="zoom-out">

          <div class="row g-5">

            <div class="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
              <h3 v-html="$t('getaccnaslov')"></h3>
              <p> {{ $t('getaccopis') }}</p><br>

              <a class="cta-btn align-self-start" href="/start/">{{ $t('getaccbutton') }}</a>
            </div>

            <div class="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div class="img">

              </div>
            </div>

          </div>

        </div>
      </section>
    </main>

    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">

      <div class="footer-content">
        <div class="container">
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="footer-info">
                <h3>Mon Perin</h3>
                <p>
         
              <p>Mon Perin d.d. Trg La Musa 2 HR-52211 Bale, Croatia</p><br>
              
            
                  <strong>Reservations & Info:</strong> +385 52 824 338<br>
                  <strong>Reception:</strong> +385 52 824 338<br>
                  <strong>Email:</strong> info@monperin.hr<br>
                </p>
              </div>
            </div>


            

            <div class="col-lg-4 col-md-12 footer-newsletter">
 

            </div>
            <div class="col-lg-4 col-md-12 footer-newsletter">
 
 

 <p>IBAN: HR77 2340 0091 1102 05359 <br>PRIVREDNA BANKA ZAGREB D.D.<br> 10000 Zagreb, Račkog 6<br> Croatia SWIFT CODE: PBZGHR2X</p>
 <p>OIB: 06374155285</p>



</div>




          </div>
        </div>
      </div>

      <div class="footer-legal text-center">
        <div
          class="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">



          <div class="social-links order-first order-lg-last mb-3 mb-lg-0">

            <a href="https://www.facebook.com/MonPerin" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/monperin" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="https://twitter.com/campingmonperin" class="instagram"><i class="bi bi-twitter"></i></a>
            <a href="https://www.linkedin.com/company/monperin/" class="instagram"><i class="bi bi-linkedin"></i></a>
            <a href="https://www.youtube.com/user/campingmonperin" class="instagram"><i class="bi bi-youtube"></i></a>

          </div>

        </div>

      </div>

    </footer><!-- End Footer -->

    <a href="#" class="scroll-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>
  </template>
</Navigation>


<div class="modal fade" id="massageModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="deleteLabel">Massage</h4>
            <i class="fa fa-times" style="color:gray;font-size: 20px;" onclick="$('#massageModal').modal('hide')" ></i>
          </div>
          <div class="modal-body" style="margin-bottom: 10px;">
            <p>We have 2 locations</p>
            <a @click="otvorilink('massageModal', '/massage/paleo/')"><button class="btn btn-primary" style="border: none;margin-bottom: 20px;width:100%;" >Paleo Park</button></a>
            <a @click="otvorilink('massageModal', '/massage/sp/')" ><button class="btn btn-primary" style="border: none;width:100%;">Tent next to Babaloo bar</button></a>
            
          </div>
         
        </div>
      </div>
    </div>


    <div class="modal fade" id="tenisModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="deleteLabel">Sport</h4>
            <i class="fa fa-times" style="color:gray;font-size: 20px;" onclick="$('#tenisModal').modal('hide')" ></i>
          </div>
          <div class="modal-body" style="margin-bottom: 10px;">
            <p>Would you like to choose tennis or multifunctional pitch</p>
            <a  @click="otvorilink('tenisModal', '/sport/tenis/')"><button class="btn btn-primary" style="border: none;margin-bottom: 20px;width:100%;" >Tennis pitch</button></a>
            <a  @click="otvorilink('tenisModal', '/sport/multi/')"> <button class="btn btn-primary" style="border: none;width:100%;">Multifunctional sport pitch</button></a>
            
          </div>
         
        </div>
      </div>
    </div>

</template>


<script>
import Navigation from '../components/Navigation.vue'
export default {
  name: 'Home',
  components: {
    Navigation
  },
  data() {
    return { langs: ['de', 'en'], yvisina:10, prijava:false, jezik:false}
  },
  methods: {
    otvorilink(modal, url){
      $('#'+modal).modal('hide')
      this.$router.push(url)


    },
    otvorimasaze(){
      $('#massageModal').modal('show')
    },
    otvoritenis(){
      this.otvorilink('tenisModal', '/sport/tenis/')
      //$('#tenisModal').modal('show')
    },

    methodThatForcesUpdate() {
      window.location.reload()
    },
    
    changeJezik(jezik){

this.$i18n.locale = jezik;
this.jezik = jezik
localStorage.setItem("lang", jezik)
$(".triangle").css("display", "none")
$("#jezikul").css("display", "none")
},
openjezik(){
 $(".triangle").toggle()
$("#jezikul").toggle()
},
jezikduzi(jezik){
 if(jezik=="hr"){ return "Hrvatski"}
 else if(jezik=="en"){return "English"}
 else if(jezik=="it"){return "Italiano"}
 else if(jezik=="de"){return "Deutsch"}
},
  },


  mounted() {
    window.scrollTo(0, 0)
    $('#preloader').remove();
    this.prijava = this.$store.getters.isAuth;

    var lang = localStorage.getItem('lang')

if(lang!=null){

   this.$i18n.locale = lang;
   this.jezik = lang
} 
else{

this.$i18n.locale = "en"
this.jezik = "en"

}



    
  },
}
</script>



<style>

.sub-banner-sec{
  clip-path: ellipse(100% 50% at 50% 50%);
       
      }

.sub-banner-sec {
  background: url(../assets/slika.jpeg) no-repeat center;
  
  background-size: cover;
  height: 561px;


}
.naslovhome{
      font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
    }
    


/* Styles for screens wider than 768px */
@media (min-width: 769px) {
  .sub-banner-sec {


  }
  
}

.overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3); /* Adjust the last value (0.5) to control the darkness */

      }




.modalbutton{
  background-color:#17a2b8;
  color:white;width: 300px;
  border-radius: 20px;
  border-color: #17a2b8;

}
.modelheading{
  font-weight: bold !important;
  color:#0189a1 !important;

}
.cd-tab-filter ul{
  background: white;
}


@media (min-width: 576px){
.modal-dialog {
  max-width: 800px;
}
}

.animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}

.animate-down {
  position: relative;
  animation: animatedown 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: 0;
    opacity: 1;
  }

  to {
    bottom: -300px;
    opacity: 0;
  }

 
}



@media only screen and (max-width: 768px) {
  .prazanprostor {
  padding-top:0px !important;
}
}



.modal-dialog  {
  position: fixed;
 
  margin: 0px auto 0;
  top:auto;
  bottom:0;
  width:100% !important;

  transform: translate(-50%, 0%) !important;
  left: 50%;

  -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
}

.choices__heading{
  color:var(--color-primary);
  font-size:14px;
}

.choices[data-type*=select-one]:after{
  border-width: 8px;
  border-color: var(--color-primary) transparent transparent transparent;
}

.choices[data-type*=select-one].is-open:after{
  border-width: 8px;
  border-color: transparent transparent var(--color-primary) transparent  ;
}

.modal-content{
  border-radius: 1rem 1rem 0 0;
  border-color: white;
}





:root {
  scroll-behavior: smooth;
}

a {
  color: var(--color-links);
  text-decoration: none;
}

a:hover {
  color: var(--color-links-hover);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {

  z-index: 9999;
  background: var(--color-white);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;

}

#loadingimage {
  width: 400px;
  height: 400px;

}

/*--------------------------------------------------------------
  # Sections & Section Header
  --------------------------------------------------------------*/
section {

  padding-bottom: 60px;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.section-header p {
  margin: 0 auto;
  color: var(--color-secondary-light);
}

@media (min-width: 1280px) {
  .section-header p {
    max-width: 80%;
  }
}

/*--------------------------------------------------------------
  # Scroll top button
  --------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 995;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--color-white);
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
  color: var(--color-white);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/


.header {

  padding:15px 0;
  
  
  transition: all 0.5s;
  z-index: 997;
}

.header.sticked {
  background: var(--color-white);
  box-shadow: 0px 2px 20px rgba(var(--color-secondary-rgb), 0.1);
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 32px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.header .logo h1 span {
  color: var(--color-primary);
  font-weight: 500;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  font-size: 16px;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 8px 23px;
  border-radius: 4px;
  transition: 0.3s;
  font-family: var(--font-secondary);
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--color-white);
  background: rgba(var(--color-primary-rgb), 0.85);
}

@media (max-width: 1279px) {

  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    margin-right: 50px;
  }
}

/*--------------------------------------------------------------
  # Laptop Navigation 
  --------------------------------------------------------------*/
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {

  .navbar a,
  .navbar a:focus {
    padding: 14px 15px !important;

  }
}




@media (min-width: 1280px) {
  .navbar {
    padding: 0;
    position: relative;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar>ul>li {
    white-space: nowrap;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 400;
    color: rgba(var(--color-secondary-dark-rgb), 0.7);
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before {
    visibility: visible;
    transform: scaleX(0.7);
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown a:hover:before,
  .navbar .dropdown:hover>a:before,
  .navbar .dropdown .active:before {
    visibility: hidden;
  }

  .navbar .dropdown a:hover,
  .navbar .dropdown .active,
  .navbar .dropdown .active:focus,
  .navbar .dropdown:hover>a {
    color: var(--color-white);
    background: var(--color-secondary);
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    padding: 0 0 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: var(--color-secondary);
    transition: 0.3s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: rgba(var(--color-white-rgb), 0.5);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-white);
    background: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    visibility: visible;
  }

  .navbar .megamenu {
    position: static;
  }

  .navbar .megamenu ul {
    right: 0;
    padding: 10px;
    display: flex;
  }

  .navbar .megamenu ul li {
    flex: 1;
  }

  .navbar .megamenu ul li a,
  .navbar .megamenu ul li:hover>a {
    color: rgba(var(--color-white-rgb), 0.5);
    background: none;
  }

  .navbar .megamenu ul li a:hover,
  .navbar .megamenu ul li .active,
  .navbar .megamenu ul li .active:hover {
    color: var(--color-white);
    background: var(--color-primary);
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    left: -100%;
    width: calc(100% - 70px);
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 10px 0;
    margin: 0;
    background: rgba(var(--color-secondary-rgb), 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--color-white-rgb), 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: var(--color-white);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid rgba(var(--color-secondary-light-rgb), 0.3);
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-toggle {
    display: block !important;
    color: var(--color-secondary);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    top: 20px;
    z-index: 9999;
    right: 20px;
  }

  .mobile-nav-toggle.bi-x {
    color: var(--color-white);
  }

  .mobile-nav-active {
    overflow: hidden;
    z-index: 9995;
    position: relative;
  }

  .mobile-nav-active .navbar {
    left: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(var(--color-secondary-rgb), 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
  # Index Page
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Animated Hero Section
  --------------------------------------------------------------*/
.hero-animated {
  width: 100%;
  min-height: 50vh;

  background-size: cover;
  position: relative;

}

.hero-animated h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.hero-animated h2 span {
  color: var(--color-primary);
}

.hero-animated p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero-animated .animated {
  margin-bottom: 20px;
  margin-top: 10px;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 992px) {
  .hero-animated .animated {
    max-width: 17%;
  }
}

@media (max-width: 991px) {
  .hero-animated .animated {
    max-width: 45%;
  }
}

@media (max-width: 575px) {
  .hero-animated .animated {
    max-width: 50%;
  }
}

.hero-animated .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.hero-animated .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.hero-animated .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.hero-animated .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero-animated .btn-watch-video:hover {
  color: var(--color-primary);
}

.hero-animated .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .hero-animated h2 {
    font-size: 32px;
  }

  .hero-animated p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .hero-animated .btn-get-started,
  .hero-animated .btn-watch-video {
    font-size: 14px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
  # Featured Services Section
  --------------------------------------------------------------*/
.featured-services .service-item {
  padding: 30px;
  transition: all ease-in-out 0.4s;
  background: var(--color-white);
  height: 100%;
}

.featured-services .service-item .icon {
  margin-bottom: 10px;
}

.featured-services .service-item .icon i {
  color: var(--color-primary);
  font-size: 36px;
  transition: 0.3s;
}

.featured-services .service-item h4 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 24px;
}

.featured-services .service-item h4 a {
  color: var(--color-secondary);
  transition: ease-in-out 0.3s;
}

.featured-services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured-services .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0 60px 0 rgba(var(--color-secondary-rgb), 0.1);
}

.featured-services .service-item:hover h4 a {
  color: var(--color-primary);
}

/*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
.about .about-img {
  position: relative;
  margin: 60px 0 0 60px;
}

.about .about-img:before {
  position: absolute;
  inset: -60px 0 0 -60px;
  z-index: -1;
  content: "";

  background-repeat: no-repeat;
}

@media (max-width: 575px) {
  .about .about-img {
    margin: 30px 0 0 30px;
  }

  .about .about-img:before {
    inset: -30px 0 0 -30px;
  }
}

.about h3 {
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-weight: 300;
  font-size: 32px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .about h3 {
    font-size: 28px;
  }
}

.about .nav-pills {
  border-bottom: 1px solid rgba(var(--color-secondary-rgb), 0.2);
}

.about .nav-pills li+li {
  margin-left: 40px;
}

.about .nav-link {
  background: none;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-secondary);
  padding: 12px 0;
  margin-bottom: -2px;
  border-radius: 0;
  font-family: var(--font-secondary);
}

.about .nav-link.active {
  color: var(--color-primary);
  background: none;
  border-bottom: 3px solid var(--color-primary);
}

@media (max-width: 575px) {
  .about .nav-link {
    font-size: 16px;
  }
}

.about .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: var(--color-secondary);
}

.about .tab-content i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
.cta {
  padding: 0;
  margin-bottom: 60px;
}

.cta .container {
  padding: 80px;
  background: rgba(var(--color-secondary-rgb), 0.1);
  border-radius: 15px;
}

@media (max-width: 992px) {
  .cta .container {
    padding: 60px;
  }
}

.cta .content h3 {
  color: var(--color-secondary);
  font-size: 48px;
  font-weight: 700;
}

.cta .content h3 em {
  font-style: normal;
  position: relative;
}

.cta .content h3 em:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  height: 10px;
  background: rgba(var(--color-primary-rgb), 0.5);
  z-index: -1;
}

.cta .content p {
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 18px;
}

.cta .content .cta-btn {
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  background: rgba(var(--color-primary-dark-rgb), 0.9);
}

.cta .content .cta-btn:hover {
  background: var(--color-primary);
}

.cta .img {
  position: relative;
}

.cta .img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(var(--color-white-rgb), 0.5);
  border-radius: 15px;
  transform: rotate(12deg);
}

.cta .img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(var(--color-white-rgb), 0.9);
  border-radius: 15px;
  transform: rotate(6deg);
}

.cta .img img {
  position: relative;
  z-index: 3;
  border-radius: 15px;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.footer {
  color: var(--color-white);
  font-size: 14px;
}

.footer .footer-content {
  background: var(--color-secondary);
  padding: 60px 0 30px 0;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

.footer .footer-content .footer-info h3 span {
  color: var(--color-primary);
}

.footer .footer-content .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: var(--font-primary);
  color: var(--color-white);
}

.footer .footer-content h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white);
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.footer .footer-content h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: var(--color-primary);
  bottom: 0;
  left: 0;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul i {
  padding-right: 2px;
  color: var(--color-white);
  font-size: 12px;
  line-height: 1;
}

.footer .footer-content .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: rgba(var(--color-white-rgb), 0.7);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
  color: var(--color-white);
}

.footer .footer-content .footer-newsletter form {
  margin-top: 30px;
  background: var(--color-white);
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

.footer .footer-content .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

.footer .footer-content .footer-newsletter form input[type=email]:focus-visible {
  outline: none;
}

.footer .footer-content .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--color-primary);
  color: var(--color-white);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-content .footer-newsletter form input[type=submit]:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
}

.footer .footer-legal {
  padding: 30px 0;
  background: var(--color-secondary-dark);
}

.footer .footer-legal .credits {
  padding-top: 4px;
  font-size: 13px;
  color: var(--color-white);
}

.footer .footer-legal .credits a {
  color: var(--color-primary-light);
}

.footer .footer-legal .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(var(--color-white-rgb), 0.1);
  color: var(--color-white);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .footer-legal .social-links a:hover {
  background: var(--color-primary);
  text-decoration: none;
}

:root {
  /* Fonts */
  --font-default: 'Open Sans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: 'Source Sans Pro', sans-serif;
  --font-secondary: 'Poppins', sans-serif;

  /* Colors */
  /* The *-rgb color names are simply the RGB converted value of the corresponding color for use in the rgba() function */

  /* Default text color */
  --color-default: #1a1f24;
  --color-default-rgb: 26, 31, 36;

  /* Defult links color */
  --color-links: #0ea2bd;
  --color-links-hover: #1ec3e0;

  /* Primay colors */
  --color-primary: #0ea2bd;
  --color-primary-light: #1ec3e0;
  --color-primary-dark: #0189a1;

  --color-primary-rgb: 14, 162, 189;
  --color-primary-light-rgb: 30, 195, 224;
  --color-primary-dark-rgb: 1, 137, 161;

  /* Secondary colors */
  --color-secondary: #485664;
  --color-secondary-light: #8f9fae;
  --color-secondary-dark: #3a4753;

  --color-secondary-rgb: 72, 86, 100;
  --color-secondary-light-rgb: 143, 159, 174;
  --color-secondary-dark-rgb: 58, 71, 83;

  /* General colors */
  --color-blue: #0d6efd;
  --color-blue-rgb: 13, 110, 253;

  --color-indigo: #6610f2;
  --color-indigo-rgb: 102, 16, 242;

  --color-purple: #6f42c1;
  --color-purple-rgb: 111, 66, 193;

  --color-pink: #f3268c;
  --color-pink-rgb: 243, 38, 140;

  --color-red: #df1529;
  --color-red-rgb: 223, 21, 4;

  --color-orange: #fd7e14;
  --color-orange-rgb: 253, 126, 20;

  --color-yellow: #ffc107;
  --color-yellow-rgb: 255, 193, 7;

  --color-green: #059652;
  --color-green-rgb: 5, 150, 82;

  --color-teal: #20c997;
  --color-teal-rgb: 32, 201, 151;

  --color-cyan: #0dcaf0;
  --color-cyan-rgb: 13, 202, 240;

  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;

  --color-gray: #6c757d;
  --color-gray-rgb: 108, 117, 125;

  --color-black: #000000;
  --color-black-rgb: 0, 0, 0;

}

/*--------------------------------------------------------------
  # 2. Override default Bootstrap variables
  --------------------------------------------------------------*/
:root {

  --bs-blue: var(--color-blue);
  --bs-indigo: var(--color-indigo);
  --bs-purple: var(--color-purple);
  --bs-pink: var(--color-pink);
  --bs-red: var(--color-red);
  --bs-orange: var(--color-orange);
  --bs-yellow: var(--color-yellow);
  --bs-green: var(--color-green);
  --bs-teal: var(--color-teal);
  --bs-cyan: var(--color-cyan);
  --bs-white: var(--color-white);
  --bs-gray: var(--color-gray);
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: var(--color-blue);
  --bs-secondary: var(--color-blue);
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: var(--color-primary-rgb);
  --bs-secondary-rgb: var(--color-secondary-rgb);
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: var(--color-white-rgb);
  --bs-black-rgb: var(--color-black-rgb);
  --bs-body-color-rgb: var(--color-default-rgb);
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: var(--font-default);
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--font-default);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: var(--color-default);
  --bs-body-bg: #fff;

}

/*--------------------------------------------------------------
  # 3. Set color and background class names
  --------------------------------------------------------------*/
/* Fonts */
.font-default {
  font-family: var(--font-default) !important;
}

.font-primary {
  font-family: var(--font-primary) !important;
}

.font-secondary {
  font-family: var(--font-secondary) !important;
}

/* Text Colors */
.color-default {
  color: var(--color-default) !important;
}

.color-links {
  color: var(--color-links) !important;
}

.color-links:hover {
  color: var(--color-links-hover) !important;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-primary-light {
  color: var(--color-primary-light) !important;
}

.color-primary-dark {
  color: var(--color-primary-dark) !important;
}

.color-secondary {
  color: var(--color-secondary) !important;
}

.color-secondary-light {
  color: var(--color-secondary-light) !important;
}

.color-secondary-dark {
  color: var(--color-secondary-dark) !important;
}

.color-blue {
  color: var(--color-blue) !important;
}

.color-indigo {
  color: var(--color-indigo) !important;
}

.color-purple {
  color: var(--color-purple) !important;
}

.color-pink {
  color: var(--color-pink) !important;
}

.color-red {
  color: var(--color-red) !important;
}

.color-orange {
  color: var(--color-orange) !important;
}

.color-yellow {
  color: var(--color-yellow) !important;
}

.color-green {
  color: var(--color-green) !important;
}

.color-teal {
  color: var(--color-teal) !important;
}

.color-cyan {
  color: var(--color-cyan) !important;
}

.color-white {
  color: var(--color-white) !important;
}

.color-gray {
  color: var(--color-gray) !important;
}

.color-black {
  color: var(--color-black) !important;
}

/* Background Colors */
.bg-default {
  background-color: var(--color-default) !important;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-primary-light {
  background-color: var(--color-primary-light) !important;
}

.bg-primary-dark {
  background-color: var(--color-primary-dark) !important;
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.bg-secondary-light {
  background-color: var(--color-secondary-light) !important;
}

.bg-secondary-dark {
  background-color: var(--color-secondary-dark) !important;
}

.bg-blue {
  background-color: var(--color-blue) !important;
}

.bg-indigo {
  background-color: var(--color-indigo) !important;
}

.bg-purple {
  background-color: var(--color-purple) !important;
}

.bg-pink {
  background-color: var(--color-pink) !important;
}

.bg-red {
  background-color: var(--color-red) !important;
}

.bg-orange {
  background-color: var(--color-orange) !important;
}

.bg-yellow {
  background-color: var(--color-yellow) !important;
}

.bg-green {
  background-color: var(--color-green) !important;
}

.bg-teal {
  background-color: var(--color-teal) !important;
}

.bg-cyan {
  background-color: var(--color-cyan) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-gray {
  background-color: var(--color-gray) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}
</style>