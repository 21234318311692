<template>


  <Navigation yvisina=15>
    <template #atag> <a class="btn-getstarted scrollto" href="/souvenirs/" style="color:White;"><i class="fa fa-arrow-left"></i></a>
    </template>
    <template #content>
      
  <br><br>
  
  <section class="menu">
  <div class="container">
  <div class="row">
  <div class="col-md-12">
  <div class="page-header wow fadeInDown">
  <h1 class="testh1">{{ $t('cartsuvenir') }}<small style="color:#97a4bd; font-size:50%;">Souvenirs</small></h1>
  
  </div>
  </div>
  </div>
  <div class="food-menu wow fadeInUp">
  <div class="row">
  
  
  <div class="menu-item jelo col-sm-6 col-xs-12 starter" v-for="i in cart.items" :id="i.id">
  <div class="clearfix menu-wrapper">
  <h4 class="testh4"><i style="cursor:pointer;color:red;" class="fa fa-times" @click="removeFromCart(i.ime, i.price, i.quantity)"></i> &nbsp;{{i.ime}}(<span class="broj" :id=i.quantity >{{i.quantity}}</span>x{{ i.price }}€)</h4>
  <span class="price">€{{i.price * i.quantity}}</span>
  <div class="dotted-bg"></div>
  </div>
  </div>
  
  
  </div>
  <hr>
  <h4 class="testh4">Total&nbsp;&nbsp;= {{cartTotal}}€ &nbsp;&nbsp;</h4><h4 class="testh4"></h4><br><br>
  <h4  style="color:gray;font-size: 18px;">Total can be paid at check out on reception<br>
    <i style="font-size:25px;" onclick="$('#viseteksta' ).toggle()">...</i></h4>
    <h4 style="color:gray;font-size: 18px;display: none;" id="viseteksta">You may be contacted on the phone number you filled in at profile page.<br>We will deliver it right away to your mobile home</h4>
 
  <br>
  
  
  
  <div style="padding-top:10px;">
    <input type="text" :placeholder="$t('extrainfosuveniri')" class="form-control" v-model="formopis.opis"><br>
    <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
    <label class="form-check-label" for="flexCheckDefault">
      <p style="color: rgba(var(--color-secondary-rgb),.8);font-size: 16px;">{{ $t('razumijem') }}</p>
    </label>
  </div>
    <button class="btn btn-primary" style="border:none; z-index:5;"  @click="createDelivery">{{ $t('cartgumb') }} &nbsp;<i class="fa fa-arrow-right"></i></button>
  
  </div>
  
  
  </div>
  
  </div>
  
  </section>
  
    </template>
  </Navigation>
  
  
  
  

  
  </template>
  
  
  <style scoped>
  .testh4{
    font-size:90%;
  }
  
  </style>
  
  
  
  <script>
  import axios from 'axios'
  import Navigation from '../components/Navigation.vue'
  export default {
    name: 'Info',
    components: {
      Navigation
    },
  
     data(){
      return{
          jela:[],
          total:"0",
          jelo:{
              id:"",
              vrsta:"0",
              quantity:"",
              
          },
          count:1,
          cart: {
          items: []
        },
        formopis:{
        opis:'',
        cart:[]
      },
   
      }
    },
    computed: {
        cartTotal() {
            let total = 0
            for (let i = 0; i < this.cart.items.length; i++) {
              total += parseInt(this.cart.items[i].price) * parseInt(this.cart.items[i].quantity)
            }
  
            return total;
  
        }
    },
  
    methods:{
    methodThatForcesUpdate(){
      
      window.location.reload()
    },
  
  
  
    removeFromCart(ime, cijena, quantity) {
          const item = {
              ime: ime,
              cijena: cijena,
              quantity: quantity
          }
         
          this.$store.commit('removeFromCartSuveniri', item)
             
      },
      createDelivery(){
    
    if(this.cartTotal == 0){
      this.$root.toastnoti(this.$t('error'), this.$t('emptycart'), "greska")
      return;
    }
    if(!document.getElementById('flexCheckDefault').checked){
      this.$root.toastnoti(this.$t('error'), this.$t('checkboxes'), "greska")
      return;
    }
    this.formopis.cart = this.cart.items
        axios.post('/mpguest/suvenir/createsuvenirdelivery/', this.formopis)
             .then((res) => {     

                  if(res.data=="Tijek"){
                    this.$root.toastnoti(this.$t('error') , this.$t('dostavavecpostoji') , "greska")
                  }
                  else if(res.data == "Telefon"){
                          this.$root.toastnoti(this.$t('error'), "Please fiil in telephone info at profile page first", "error")
                  }
                  else{
                    this.$store.commit('clearCartSuveniri')
                    this.$router.push('/suveniri/detalji/');
                    this.$root.toastnoti(this.$t('success') , this.$t('uspjehsuveniri') , "uspjeh")
                  }
                  
             })
             .catch((error) => {
                
             }).finally(() => {
                 //Perform action in always
             });
             
  },
  
     
        
  
    },
  
  
    mounted(){
  
      this.cart = this.$store.state.suvenircart
      const preloader = document.querySelector('#preloader');
      preloader.remove();
     
    },
  }
  </script>
  
  