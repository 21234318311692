
<template>

    <Navigacija yvisina="15">
      <template #atag>
        <!--<a class="btn-getstarted scrollto" id="podsjetnik" style="color:White; cursor: pointer;" href="/login/">Log in</a>-->
        <a></a>
      </template>
      

      <template #content>
        <div style="padding-top: 60px;"></div>
        <br>
        
    <div class="content" style="color: #4e7ec7c9;">
      
      <div class="container">
        
 
        <div class="row align-items-stretch no-gutters contact-wrap">
          
          <div class="col-md-12">
            <div class="form h-100">
              <h3 class="naslov">   <span class="account" style="float:left;"> <template v-if="type=='mh'">{{ $t('credentialsmh') }}</template><template v-else>{{ $t('credentialspc') }}</template></span><br></h3>
              
              <p>{{ $t('howcred') }}</p><br>
              
              
                
                
                <div class="row">
                  <form v-on:submit.prevent="stvoriGosta"  class="mb-5">
  
                
                <span class="row">
                
                  <div class="form-group mb-5 col-md-12">
                        <span class="fa-stack" style="font-size:40px;padding-right:20px;cursor: pointer;" @click="togglevrsta('mh')" id="mh"><i class="fa fa-home fa-stack-1x" ></i><i class="fa fa-circle fa-stack-2x"></i></span>
                        <span  class="fa-stack" style="font-size:40px;cursor: pointer;" @click="togglevrsta('pc')" id="pc"><i class="fa fa-tent fa-stack-1x" ></i><i class="fa fa-circle fa-stack-2x"></i></span>


                </div>
               
          
                  
          
          <div  class="row" v-show="type == 'pc'">
            <div class="form-group mb-5 col-md-3">
          <label for="message" class="col-form-label">Stay number *</label>
          <input class="form-control" id=""  v-model="form.id" required>
            
    
          </div>

            <div class="form-group mb-5  col-md-3">
                  <label for="message" class="col-form-label">{{ $t('pc1') }}</label>
                  <input type="number" class="form-control" id=""  v-model="form.broj" required>
                </div>

            <div class="form-group mb-5  col-md-6" id="zona">
                  <label for="message" class="col-form-label">{{ $t('zona1') }}</label>
                  <select id="zonachoice" class="inputVrsta" placeholder="Označi djelatnike" v-model="form.zona"></select>
                </div>

         
              </div>

          <template class="row" v-show="type == 'mh'">
            
            <div class="form-group mb-5 col-md-6">
          <label for="message" class="col-form-label">Stay number *</label>
          <input class="form-control" type="number" id=""  v-model="form.id" required>
            
    
          </div>

            <div class="form-group mb-5  col-md-6">
                  <label for="message" class="col-form-label">{{ $t('mh1') }}</label>
                  <input type="number" class="form-control" id=""  v-model="form.broj" required>
                </div>

              </template>

                


  </span>
                  
    
    
    
              
     
                
    
                <br>
                
          
        
                         <div class="row">
           
                    <input type="submit" value="Start" class="btn btn-primary" style="border:none;" id="submit" :disabled="disableconfirm"><br><br><br>
                  
                    
    
                </div>
              </form>
            
              
                </div>
              
           
    
          
            </div>
        
          </div>
        
        </div>
    
      </div>
    
      </div>
      </template>
    </Navigacija>
    
   
    

    </template>
    
    <script>
    import axios from 'axios'
    import { PushNotifications } from '@capacitor/push-notifications';
    import Navigacija from '../components/Navigation.vue'
    export default{
      components: {
        Navigacija
      },
     data() {
        return {
          disableconfirm:false,
          type:"mh",
          choice1:null,
          choice2:null,
          fcmtoken:"",
            form:{
                id:"",
                broj:"",
                od:"",
                do:"",
                password:"",
                zona:"",
                kamp:"",
                fcmtoken:"",
                
            },
            password:"",
            username:"",
            usrtoken:{
              token:'',
              vrsta:'guest',
              device:''
            },

        }
      },
      mounted(){
        $('#preloader').remove();
     
        PushNotifications.addListener('registration', (token) => {
      
          this.form.fcmtoken = token.value
     
          
        });


        if(Capacitor.getPlatform()!="web"){
                  PushNotifications.requestPermissions().then(result => {
                    if(result.receive=="granted"){
                    PushNotifications.register()

                  }
                  

                  });
                }


        $('#pc').css('opacity', '0.5');
    
   
        
        const preloader = document.querySelector('#preloader');
        

        this.choice1 = new Choices('#zonachoice', {
          choices : [{id:"A SanPolo", value:"A SanPolo"}, {id:"B SanPolo", value:"B SanPolo"}, {id:"A Colona", value:"A Colona"}, {id:"B Colona", value:"B Colona"}, {id:"C Colona", value:"C Colona"}],
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 
      this.choice2 = new Choices('#kampchoice', {
        choices : [{id:"SanPolo", value:"SanPolo", selected:true}, {id:"Colona", value:"Colona"}],
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 
      $("#preloader").hide();
      
      },
      methods:{
        

        async submitForm() {
      this.usrtoken.token = localStorage.getItem('token')
      

      if (this.usrtoken.token){
        

        await axios
              .post("/api/odjava/", this.usrtoken)
              .catch(error => {
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
                axios.defaults.headers.common["Authorization"] = ""
                
                })
                
                
               
      }
      
         
                this.prijavise()

          
        },

        async prijavise(){
          const formData = {
                username: this.username,
                password: this.password,
                fcmtoken:this.fcmtoken,
                vrsta:'guest',
            }
   
            await axios
                .post("/api/api-token-auth/", formData)
                .then(response => {
                  
                    

                })
                .catch(error => {
            
                  
                    this.$root.toastnoti(this.$t('error'), this.$t('loggeror'), "greska")
                })
        },


        
        togglevrsta(vrsta){
          if(vrsta=="mh"){
            $('#mh').css('opacity', '0.9');
            $('#pc').css('opacity', '0.5');
            this.type="mh"
          }
          else{
            $('#mh').css('opacity', '0.5');
            $('#pc').css('opacity', '0.9');
            this.type="pc"
          }
        },
        copytoclip(){},

    
          stvoriGosta(){
            
            if(this.type=="pc"){
              this.form.broj += $('#zona .choices__item ').attr('data-value')
     

            }

            this.disableconfirm = true

           
                
        
                axios.post('/mpguest/getcredentials2/', this.form)
                .then((res) => {
                this.$root.toastnoti("Sucess!", "Here is your password and username", "uspjeh")
  
                  if(res.data=="No"){
                    console.log(res.data)
                    this.disableconfirm = false
                    this.$root.toastnoti("Error", "No guest with selected info", "greska")
                  }
                  else{

                    const token = res.data.token
                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    
                    localStorage.setItem("username", this.username)
                    localStorage.setItem("token", token)
                    localStorage.setItem("mh", res.data.mh)
              
                    const toPath = this.$route.query.to || '/'
                 
                    this.$router.push(toPath)
                    this.$root.toastnoti(this.$t('success'), this.$t('loggedsucess'), "uspjeh")

                    
                  
                  }
                
                        
                })
                .catch((error) => {
                  console.log(error)
                  this.disableconfirm = false
                  
                    this.$root.toastnoti("Error!", "Error while fetching data, please try again", "greska")
                })          
          },
      }
    }
    </script>

    <style scoped>




    .cred{
        border: none;
    }

.account {
    color: var(--color-primary);
    font-size: 30px;
    font-weight: 700;

    
}
.account em{
    font-style: normal;
    position: relative;
}

</style>