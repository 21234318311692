export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto a "])},
  "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendi il tuo soggiorno ancora migliore"])},
  "reportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnala un malfunzionamento"])},
  "reporttekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facendo clic sul pulsante Crea nella scheda Segnalazione, sarai in grado di segnalare un malfunzionamento. Il nostro staff riceverà immediatamente una notifica e Le contatterà il prima possibile."])},
  "infonaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Informazioni"])},
  "infotekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica l'ultimo opuscolo informativo contenente gli eventi, l'orario del treno turistico e gli orari di apertura delle strutture. <br>Visualizza le attività nel campo e nei dintorni e ricevi notifiche dallo staff."])},
  "deliverynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna"])},
  "deliverytekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un pasto dai ristoranti Mol Picio o Paleo Park e noi te lo consegneremo direttamente nella tua casa mobile."])},
  "rezervacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazioni campi sportivi"])},
  "rezervacijetekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli uno sport, scegli il tuo tempo e divertiti"])},
  "morenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E altro..."])},
  "moretekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diteci cosa ne pensate attraverso i sondaggi e date un'occhiata ai nostri souvenir"])},
  "getaccnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora il tuo account?"])},
  "getaccopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella scheda Inizia, inserisci le informazioni richieste per ottenere un nome utente e una password. "])},
  "getaccbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
  "footercontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattaci"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggi da noi"])},
  "Souvenirs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto"])},
  "Restaurnats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristoranti"])},
  "Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrello"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
  "poruke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggi"])},
  "startmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa mobile"])},
  "startpc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piazzola"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina iniziale"])},
  "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna"])},
  "logininfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai le informazioni di accesso?"])},
  "downloadare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area di download"])},
  "downloadopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le informazioni importanti sono a portata di clic"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serate di cinema, feste in piscina e altro ancora"])},
  "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stazioni e orari di un treno che fa il giro del campo"])},
  "trainnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari del treno"])},
  "workingtimenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari di apertura"])},
  "workingtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari di apertura in entrambe le aree del campo"])},
  "freshmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari e maggiori informazioni sul mercato del fresco"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa di campo"])},
  "farewell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addio"])},
  "farewell_opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che il tuo soggiorno sia giunto al termine.<br>Grazie ancora per essere stati con noi e speriamo di rivedervi in ​​futuro."])},
  "farewell_gumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cambiata? "])},
  "profiledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del profilo"])},
  "notifikacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nuove notifiche verranno visualizzate qui"])},
  "suveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquista souvenir alla reception principale del campo o al Paleo Park"])},
  "suveniropis1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peluche di dinosauro"])},
  "noreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue attività segnalate appariranno ascoltate<br>Qualcosa non funziona correttamente?<br>Crea un'attività per il nostro staff e arriveranno il prima possibile"])},
  "searchjela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per nome (tutte le categorie)..."])},
  "restoranopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna gratuita e veloce a casa tua mentre ti godi la vacanza"])},
  "cartgumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
  "deliveryinformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di consegna"])},
  "deliverydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna per"])},
  "Preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparazione"])},
  "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto"])},
  "Taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preso"])},
  "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegnato"])},
  "sportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo sportivo multifunzionale"])},
  "sportopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli una data e scegli il momento migliore per te."])},
  "extrainfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni"])},
  "reportnapomena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenderemo in considerazione la tempistica desiderata e faremo del nostro meglio per soddisfare la tua richiesta a causa della mole di lavoro."])},
  "extrainfosuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni extra (ad es. ora o luogo diverso)"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "loggedsucess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei stato loggato"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei stato disconnesso"])},
  "loggeror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati errati"])},
  "greska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, si è verificato un errore, riprova più tardi o contattaci"])},
  "profilesave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul profilo salvate"])},
  "incorrectnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla se il numero di telefono è corretto"])},
  "verifynumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di verificare il numero di telefono"])},
  "profilerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la conferma del numero di telefono"])},
  "krivikod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice errato, ripetere o controllare il numero di telefono"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
  "availabletimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari disponibili per la data prescelta:"])},
  "nemaslobodnog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun orario disponibile..."])},
  "nemarezerviranog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun orario riservato..."])},
  "rezervacijesporta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie prenotazioni:"])},
  "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riservato, a dopo"])},
  "prosaodatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data inserita è già trascorsa"])},
  "odgodatermina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tempo è cancellato"])},
  "zdstatus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevuto"])},
  "zdstatus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preso"])},
  "zdstatus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavorando su di esso"])},
  "zdstatus4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatto"])},
  "izbrisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
  "izbrisizadatak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questa attività?"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnala un'attività"])},
  "reportinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
  "reportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voglio ricevere newsletter via e-mail"])},
  "jelokategorija1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antipasti"])},
  "jelokategorija2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colazione"])},
  "jelokategorija3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principale"])},
  "jelokategorija4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolci"])},
  "jelokategorija5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevanda"])},
  "razumijem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capisco"])},
  "extrainfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni"])},
  "suvenirdetalji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli sulla consegna dei souvenir"])},
  "nemasuvenira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna consegna attiva, scegli i souvenir dal negozio"])},
  "taskreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiamo arrivando"])},
  "checkboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare la casella"])},
  "dostavavecpostoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già una consegna attiva"])},
  "uspjehsuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua consegna è in arrivo"])},
  "emptycart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il tuo carrello è vuoto"])},
  "taskdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività eliminata"])},
  "unesitedatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci prima la data"])},
  "breakfast1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasto"])},
  "breakfast2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevanda"])},
  "breakfast3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi su"])},
  "cartsuvenir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carretto ricordo"])},
  "cancelres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla prenotazione"])},
  "cancelres2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di volerlo annullare"])},
  "reservesport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva campo sportivo"])},
  "reservesport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di volerlo prenotare?"])},
  "tenisnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo da tennis"])},
  "tenissport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli una data e scegli il momento migliore per te."])},
  "main1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antipasti"])},
  "main2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principale"])},
  "main3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolci"])},
  "main4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevanda"])},
  "credentialsmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni le credenziali per la casa mobile"])},
  "credentialspc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni le credenziali per la piazzola"])},
  "howcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le informazioni possono essere trovate sul check-in cartaceo"])},
  "mh1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di casa mobile *"])},
  "pc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero piazzola *"])},
  "zona1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona *"])},
  "camp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo *"])},
  "od1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data d'arrivo *"])},
  "do1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di partenza *"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta la password o lasciala vuota per ottenerne una generata automaticamente *"])},
  "submitcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
  "logout1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettersi"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente"])},
  "password1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola d'ordine"])},
  "tel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono *"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "notifikacije": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
  "notifikacijeopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oltre alle notifiche ordinarie, potresti ricevere informazioni importanti sul campo"])},
  "reportmodal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnala un'attività"])},
  "reportmodal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di attività"])},
  "reportmodal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad esempio Tempi desiderati"])},
  "sport1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prenotazioni possono essere cancellate fino a 2 ore prima dell'inizio"])},
  "sport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il costo può essere pagato al momento del check out con il resto del soggiorno"])},
  "multi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona uno o più campi"])},
  "multi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
  "kreiranzd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività creata"])},
  "surveynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi"])},
  "surveyopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo feedback è importante per garantire che stiamo fornendo il servizio di cui hai bisogno.<br> Ti saremmo grati se volessi partecipare a un sondaggio sul tuo soggiorno con noi."])},
  "s1naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggiorno in casa mobile"])},
  "s1opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccontaci di più sul tuo soggiorno in casa mobile."])},
  "s2naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggiorno in piazzola"])},
  "s2opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccontaci di più sul tuo soggiorno in piazzola."])},
  "s3naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post soggiorno"])},
  "s3opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccontaci di più sul tuo soggiorno in generale."])},
  "Booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuscolo"])},
  "payement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La consegna inizierà quando riceveremo il pagamento"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "Dodano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasto aggiunto al carrello"])},
  "loginbitno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi per effettuare le prenotazioni"])},
  "pickfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli prima un posto"])},
  "Maknuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimosso"])},
  "foodcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["carello della spesa"])},
  "emptydeliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue consegne appariranno qui, creane una selezionando gli articoli dai ristoranti e seguendo i passaggi nel carrello"])},
  "passwordunos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzionale* Scegli la password o ottienine una generata automaticamente"])},
  "activity1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
  "activity2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divertimento"])},
  "activity3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultura"])},
  "activity4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "activity5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsi"])},
  "activity6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
  "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze"])},
  "exploreactivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esplora le attività che offriamo a Mon Perin e Bale"])},
  "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra di più..."])},
  "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra meno..."])},
  "profil1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio*"])},
  "profil2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome*"])},
  "deliveryopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi anche programmare la consegna per dopo"])},
  "bikepaths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piste ciclabili"])},
  "monperin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Perin"])},
  "bale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valle/Valle"])},
  "paleopark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parco Paleo"])},
  "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campeggio - Camping Home e Camping Villa"])},
  "bale1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visita Valle, ti conquisterà con la natura incontaminata, la pace, il mare cristallino, ma anche con i numerosi eventi"])},
  "camping1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esplora le nostre ville"])},
  "paleopark1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listino prezzi Paleopark (gratuito per gli ospiti)"])},
  "bikepaths1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le balle sono una vera sfida o un'avventura per tutti coloro che vogliono godersi una ricreazione attiva."])},
  "monperin1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni su Mon Perin"])},
  "cijenik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Prezzo: 4€ all'ora<br>Racchetta e pallina da tennis disponibili per il noleggio sul sito"])},
  "cijenik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo: 4€ all'ora<br>Attrezzatura disponibile per il noleggio in loco"])},
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un orario in cui vuoi iniziare la consegna, a seconda dell'ora del giorno sarà disponibile solo un ristorante"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I ristoranti non funzionano in questo momento"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli l'ora futura"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un orario tra le 7:00 e le 21:00"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero massimo di giorni di anticipo per la consegna è di 1 giorno"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire prima le informazioni telefoniche nella pagina del profilo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potresti essere contattato al numero di telefono che hai inserito nella pagina del profilo."])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettueremo la consegna alla tua casa mobile"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun pranzo selezionato nel carrello"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna colazione selezionata nel carrello"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando vuoi iniziare la consegna?"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programma per dopo?"])},
  "salespolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente deve effettuare una prenotazione del campo sportivo attraverso il canale appropriato, come una telefonata, una piattaforma internet o una visita personale alla struttura di vendita.La prenotazione del campo sportivo deve essere confermata dal venditore o dal responsabile in addebito delle prenotazioni.L'acquirente si impegna a pagare l'importo adeguato per il campo di prenotazione sportiva secondo il prezzo concordato.I metodi di pagamento possono includere il pagamento in contanti, pagamento con carta o pagamento elettronico secondo le regole del venditore.L'acquirente deve effettuare il pagamento in anticipo o entro l'orario concordato prima della data di prenotazione del campo sportivo.In caso di mancato pagamento entro l'orario concordato, la prenotazione del campo sportivo potrà essere annullata.Descrizione della modalità e termine di consegna del prodotto o prestazione del servizio: Il campo sportivo sarà disponibile per l'utilizzo nella data e ora concordate.Il cliente riceverà una conferma di prenotazione che conterrà informazioni accurate sulla data, l'ora e il codice a barre per l'apertura del campo sportivo.Costi di consegna: In caso di prenotazione del campo sportivo, i costi di consegna non si applicano perché la prenotazione si riferisce all'utilizzo dello spazio, non alla consegna fisica del prodotto.Disposizioni contrattuali: La prenotazione del campo sportivo è considerata un valido obbligo contrattuale tra l'acquirente e il venditore.L'acquirente si impegna a rispettare le regole e condizioni d'uso del campo sportivo dichiarate dal venditore Condizioni generali di contratto: Le condizioni generali di contratto, se sono parte integrante del contratto, si applicano alla prenotazione del campo sportivo e sono vincolanti per entrambe le parti."])},
  "conversionpolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i pagamenti saranno effettuati in valuta croata. L'importo che verrà addebitato sulla tua carta di credito si ottiene convertendo il prezzo in un'altra valuta in EUR secondo il tasso di cambio corrente della Banca nazionale croata. Al momento dell'addebito sulla tua carta di credito, lo stesso importo viene convertito nella tua valuta locale in base al tasso di cambio delle associazioni di carte. Come risultato di questa conversione, c'è la possibilità di una leggera differenza rispetto al prezzo originale indicato sul nostro sito web."])},
  "datapolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest si impegna a conservare i dati personali dei clienti, in modo tale da raccogliere solo i dati di base necessari sui clienti/utenti che sono necessari per l'adempimento dei nostri obblighi; informa i clienti sull'utilizzo dei dati raccolti, offre regolarmente ai clienti l'opportunità di scegliere l'utilizzo dei propri dati, inclusa la possibilità di decidere se desiderano o meno che il loro nome venga rimosso dagli elenchi utilizzati per le campagne di marketing. Tutti i dati dell'utente sono rigorosamente conservati e sono disponibili solo per i dipendenti che necessitano di questi dati per svolgere il proprio lavoro. Tutti i dipendenti di Terra Vallis e i partner commerciali sono responsabili del rispetto dei principi di protezione della privacy"])},
  "securitypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest utilizza Monri WSPay per i pagamenti online. Monri WSPay è un sistema sicuro per pagamenti online, pagamenti in tempo reale, carte di credito e di debito e altri metodi di pagamento. Monri WSPay fornisce a clienti e commercianti l'inserimento e il trasferimento sicuri dei dati della carta inseriti, che è confermato dal certificato PCI DSS di Monri WSPay. Monri WSPay utilizza un certificato SSL con crittografia a 256 bit e protocollo crittografico TLS 1.2 come massimo livello di protezione durante la registrazione e il trasferimento dei dati."])},
  "salespolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politica di vendita"])},
  "conversionpolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica di conversione"])},
  "datapolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica sui dati personali"])},
  "securitypolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica di sicurezza"])},
  "tenisopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei loggato, ma puoi comunque effettuare prenotazioni per campi sportivi con Pagamento online <br>Il prezzo orario per il campo da tennis è di 13€"])},
  "multiopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei loggato, ma puoi comunque effettuare prenotazioni per campi sportivi con Pagamento online<br>Il prezzo per 1 piazzola all'ora è di 13€"])},
  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione dei dati ai sensi del regolamento generale sulla protezione dei dati del Parlamento europeo e del Consiglio n. 2016/679- Regolamento e attuazione del GDPR WSPay, in qualità di responsabile dell'autorizzazione e del pagamento effettuato con carte di credito, utilizza i dati personali in qualità di responsabile del trattamento ai sensi del Regolamento generale sulla protezione dei dati del Parlamento europeo e del Consiglio n. 2016/679, e conforme al Regolamento PCI DSS Livello 1 per il trasferimento dei dati. WSPay utilizza la crittografia SSL a 256 bit e il protocollo crittografico TLS 1.2 come standard di protezione più elevati per l'immissione e il trasferimento dei dati. I dati personali utilizzati ai fini dell'autorizzazione e del pagamento sono considerati dati riservati. I seguenti dati personali del cliente sono necessari per l'adempimento del contratto (autorizzazione e pagamento): Nome e Cognome E-mail Numero di telefono Indirizzo Città CAP Nazione Tipo di carta di credito Numero di carta di credito Data di scadenza (carta di credito) Numero CVV della carta di credito WSPay fa non elaborare o utilizzare questi dati personali se non allo scopo di adempiere al contratto, l'autorizzazione e il pagamento. WSPay garantisce di soddisfare i requisiti stabiliti dalle normative applicabili in materia di protezione dei dati personali, per i responsabili del trattamento dei dati personali, in particolare adottando tutte le necessarie misure tecniche, organizzative o di sicurezza confermate dal certificato PCI DSS di livello 1."])},
  "salespolicy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Ordinazione: la colazione e il pranzo possono essere ordinati in un determinato momento. Le consegne possono essere pre-programmate per un tempo futuro. 2. Tempi di consegna: La consegna verrà effettuata entro e non oltre un'ora dall'orario di inizio della consegna. Se le consegne sono programmate per un tempo futuro, cercheremo di consegnarle allora. 3. Modalità di pagamento: Accettiamo pagamenti con carte (debito/credito) all'inizio della consegna. È possibile pagare tramite applicazioni mobili o internet banking. 4. Descrizione della consegna: Il cibo verrà consegnato all'interno del campeggio direttamente nella casa mobile dell'ospite. La consegna sarà effettuata dal nostro personale. 5. Spese di consegna: Le spese di consegna saranno chiaramente indicate al momento dell'ordine. In alcuni casi, la spedizione potrebbe essere gratuita a seconda dell'importo minimo dell'ordine. 6. Disposizioni contrattuali: Ogni ordine rappresenta un contratto concluso tra il cliente e il servizio di consegna. Il cliente accetta i termini di consegna al momento della conferma dell'ordine. 7. Diritto dell'acquirente di recedere dal contratto: L'acquirente non ha il diritto di recedere dal contratto, in caso di errore durante la consegna, il denaro verrà restituito all'acquirente."])},
  "x1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibo delizioso consegnato al tuo alloggio. "])},
  "x2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pranzo"])},
  "x3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegne"])},
  "x10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca per essere reindirizzato al nostro menu!"])},
  "x11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colazione"])},
  "x4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai pensando a un'ottima colazione per domani? "])},
  "x5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic per essere reindirizzato al preordine della scatola della colazione!"])},
  "x6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota ora la tua sessione sportiva e preparati a creare ricordi indimenticabili con ogni swing, tiro o goal. "])},
  "x7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazioni sportive"])},
  "x8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazioni di massaggi"])},
  "x9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia che tutte le tue preoccupazioni svaniscano. "])},
  "x12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13€ all'ora, verranno aggiunti al totale del soggiorno e possono essere pagati al momento del check-out. <br><span >Cancellabile fino a 2 ore prima dell'inizio. <br>Scorri verso il basso fino alle tue prenotazioni e fai clic sul pulsante X per annullarle</span>"])},
  "x13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nostro multipitch offre una vasta gamma di attività per tutti gli interessi. "])},
  "x14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendiamo semplice e senza soluzione di continuità per te raggiungere. "])},
  "x15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo una giornata trascorsa a fare sport e divertirsi, rilassati con un bel massaggio. <br>La quota può essere pagata in loco"])},
  "x16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenda massaggi situata a San Polo accanto al bar Babaloo. <br>La quota può essere pagata in loco"])},
  "x17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai pensando a un'ottima colazione per domani? "])},
  "x18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il box colazione è composto da:"])},
  "x19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preordini per la mattina successiva disponibili tutti i giorni fino al"])},
  "x20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna dalle 8:00 alle 9:00."])},
  "x21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quante scatole vuoi ordinare? "])},
  "x22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ora sono disponibili solo pagamenti in contanti al personale."])},
  "x23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliccando su crea confermi di ricevere "])},
  "x24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scatola per la colazione"])},
  "x25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domani mattina del"])},
  "x26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Se desideri annullare l'ordine o modificare il numero di scatole, non esitare a contattarci in chat. <br>Per ora accettiamo solo pagamenti in contanti al personale di consegna"])},
  "x27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la consegna della colazione"])},
  "x28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic sul pulsante Pianifica più tardi per preordinarlo per dopo"])},
  "x29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai pensando a un ottimo pasto?  "])},
  "x30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accetto il"])},
  "x31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorri verso il basso fino alle tue prenotazioni e fai clic sull'icona del codice a barre per ottenere un codice a barre univoco. Usalo per l'ingresso"])},
  "x32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preordina la colazione"])},
  "x33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna del cibo"])},
  "x34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu 'pranzo"])},
  "x35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegne"])},
  "x36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennis"])},
  "x37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo polisportivo"])},
  "x38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massaggio"])},
  "x39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuntamenti disponibili:"])},
  "x40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue prenotazioni:"])},
  "x41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice a barre per l'ingresso"])},
  "x42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13€ verranno aggiunti al totale del soggiorno"])},
  "x43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preordina per la mattina del"])},
  "x44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia consegna"])},
  "x45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna disponibilità"])},
  "x46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione"])},
  "freetenis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratuito per gli ospiti"])},
  "myrez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie prenotazioni"])}
}