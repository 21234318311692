import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ReportView from '../views/ReportView.vue'
import DeliveryDetailView from '../views/DeliveryDetailView.vue'
import CartView from '../views/CartView.vue'
import Restaurants from '../views/Restaurants.vue'
import Profile from '../views/Profile.vue'
import Info from '../views/Info.vue'
import Bye from '../views/Bye.vue'
import Sport from '../views/Sport.vue'
import Obavijest from '../views/Obavijest.vue'
import Suveniri from '../views/Suveniri.vue'
import store from '../store'
import DetaljiSuvenir from '../views/DetaljiSuvenir.vue'
import CartSuveniri from '../views/CartSuveniri.vue'
import Breakfast from '../views/Breakfast.vue'
import Multi from '../views/Multi.vue'
import Start from '../views/Start.vue'
import Ankete from '../views/Ankete.vue'
import Experiences from '../views/Experiences.vue'
import Uspjeh from '../views/Uspjeh.vue'
import Cancel from '../views/Cancelled.vue'
import Error from '../views/Error.vue'
import Qrcode from '../views/Qrcode.vue'
import Massage from '../views/Massage.vue'
import MassageSp from '../views/MassageSp.vue'
import MojeAnkete from '../views/MojeAnkete.vue'
import MasazaQr from '../views/MasazaQr.vue'
import StartPayment from '../views/StartPayment.vue'
import Oasis from '../views/Oasis.vue'
import Manicure from '../views/Manicure.vue'
import Fitnes from '../views/Fitnes.vue'
import Hair from '../views/Hair.vue'
import Depilation from '@/views/Depilation.vue'


const routes = [
  {
    path: '/qrcode/',
    name: 'qrcode',
    component: Qrcode
  },

  {
    path: '/anketemonperin/',
    name: 'anketemonperin',
    component: MojeAnkete
  },
  {
    path: '/massage/',
    name: 'massageqr',
    component: MasazaQr
  },
  {
    path: '/manicure/',
    name: 'manicure',
    component: Manicure
  },
  {
    path: '/uspjeh/:platform/:vrsta/:qnt/',
    name: 'uspjeh',
    component: Uspjeh
  },

  {
    path: '/cancel/:platform/',
    name: 'cancel',
    component: Cancel
  },
  {
    path: '/error/:platform/',
    name: 'error',
    component: Error
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/ankete/',
    name: 'ankete',
    component: Ankete
  },
  {
    path: '/start/',
    name: 'start',
    component: Start
  },
  {
    path: '/massage/oasis',
    name: 'Massage',
    component: Massage,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/oasis',
    name: 'Oasis',
    component: Oasis,
    meta: {
      requireLogin: true
    }
   
   
   
  },
  {
    path: '/oasis/depilation',
    name: 'Depilation',
    component: Depilation,
    meta: {
      requireLogin: true
    }
   
   
   
  },
  {
    path: '/massage/sp',
    name: 'MassageSp',
    component: MassageSp,
    meta: {
      requireLogin: true
    }
  
  },
  {
    path: '/oasis/fitness',
    name: 'Fitnes',
    component: Fitnes,
    meta: {
      requireLogin: true
    }
  
  },
  {
    path: '/oasis/hair',
    name: 'Hair',
    component: Hair,
    meta: {
      requireLogin: true
    }
  
  },
  {
    path: '/obavijest/',
    name: 'Obavijest',
    component: Obavijest,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/souvenirs/',
    name: 'Souvenirs',
    component: Suveniri
  },
  {
    path: '/startpayment/:shopid/:cartid/:total/:signature/',
    name: 'StartPayment',
    component: StartPayment
  },
  {
    path: '/login/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/restaurants/breakfast',
    name: 'restaurantbreakfast',
    component: Breakfast,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/report/',
    name: 'report',
    component: ReportView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/cart/suveniri/',
    name: 'CartSuveniri',
    component: CartSuveniri,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/restaurants/main/',
    name: 'restaurants',
    component: Restaurants
  },
  {
    path: '/cart/',
    name: 'cart',
    component: CartView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/delivery/',
    name: 'deliverydetails',
    component: DeliveryDetailView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/sport/tenis/',
    name: 'sport',
    component: Sport,
    meta: {
      requireLogin: true
    }
    

   
 
  },
  {
    path: '/sport/multi/',
    name: 'multi',
    component: Multi,

  
  },
  {
    path: '/suveniri/detalji',
    name: 'suveniridetalji',
    component: DetaljiSuvenir,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/profile/',
    name: 'profile',
    component: Profile,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/info/',
    name: 'info',
    component: Info
  },
  {
    path: '/bye/',
    name: 'bye',
    component: Bye
  },
  {
    path: '/experiences/',
    name: 'experiences',
    component: Experiences,
  
  },


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    return next('/start/')
  } else {
    next()
  }
})

export default router
