<template>


    <Navigation yvisina=0>
      <template #atag> <a >
     
    </a>
  </template>
     <template #content>
      <div style="padding-top: 100px;"></div>
      <div class="cards">


<article class="plan [ card ]">
  <div class="inner">

    <span class="pricing">
      <span>
        12€ 
      </span>
    </span>
    <h2 class="title">Breakfast</h2>
    <p class="info">Order breakfast box directly to your acommodation.<br> Delivery from 8AM</p>
    <ul class="features">
      <li>
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
          </svg>
        </span>
        <span><strong>Empty Croissant</strong></span>
      </li>
      <li>
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
          </svg>
        </span>
        <span><strong>Sandwich + Cooked ham, cheese, Boiled Egg</strong></span>
      </li>
      <li>
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
          </svg>
        </span>
        <span><strong>Nutella, Cheese, Butter</strong></span>
      </li>
      <li>
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
          </svg>
        </span>
        <span><strong>Home made Granola yogurt and fresh fruit</strong></span>
      </li>
    </ul>
    <button  class="buttonoffer"  v-if="isBefore8PM()" @click="startdeliverypotvrda" :disabled="buttonDisabled">
      {{ $t('x43') }} {{ new Date(Date.now() + 86400000).toLocaleDateString('uk', { day: 'numeric', month: 'numeric' }).replace(/\//g, '.') }}
    </button>
  </div>
</article>

</div>

      


    <div style="height: 1000px;"></div>

</template>
    </Navigation>
    
    
    <div class="modal fade" id="ConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" >
      <div class="modal-dialog" style="padding:100px 10px 10px 10px;">
        <div class="modal-content" style="border-radius: 1rem;">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel" style="color:#97a4bd">{{ $t('x27') }} </h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('ConfirmModal')" ></i>
          </div>
          <div class="modal-body">
            <p>Do you want to order <b style="color:#97a4bd">{{ this.form.qnt }} {{ $t('x24') }}</b>  for {{ new Date(Date.now() + 86400000).toLocaleDateString('uk', { day: 'numeric', month: 'numeric' }).replace(/\//g, '.') }} <br>
           <br>
           <span v-html="$t('x26')"></span></p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
             <button type="button" @click="startdelivery" :disabled="buttonDisabled" class="btn btn-info f-left modalbutton" style="border:none;">{{ $t('create') }}</button>
             <div style="padding-top:120px;" class="prazanprostor"></div>
          </div>
        </div>
      </div>
    </div>
    </template>

    <style>
    #dodaj:hover{
      color:red;
    }
  
  </style>
    
    <script>
    import axios from 'axios'
    import Navigation from '../components/Navigation.vue'
    export default {
      name: 'Restaurant',
      components: {
        Navigation
      },
    
      data(){
        return{
            jela:[],
            buttonDisabled:false,
            qnt:1,
            jelo:{
                id:"",
                vrsta:"1",
            },
            cart: {
            items: []
          },
          form:{
            qnt:1,
            karticno:"Gotovina",
          },
          do:24,
        }
      },
    
      beforeCreate() {
        this.$store.commit('initializeStore')
        const token = this.$store.state.token
        if (token) {
            axios.defaults.headers.common['Authorization'] = "Token " + token
        } else {
            axios.defaults.headers.common['Authorization'] = ""
        }
      },
      mounted(){
        this.cart = this.$store.state.cart
        
      
        this.searchJela();
    
    
        this.getJela()
        
        $('.menu-tags span').on('click', function(event){
          
     
            
            $('.menu-tags .tagsort-active').removeClass('tagsort-active');
            $(event.target).addClass('tagsort-active');
            var selected_filter = $(event.target).data('type').toUpperCase();
    
            $(".menu-items .menu-item").each(function(){
                
        
                if(!$(this).hasClass(selected_filter)){
                    $(this).hide()
                }
                else{
                    $(this).show()
                    
                }
    
            });
        })
        const preloader = document.querySelector('#preloader');
      },
      computed: {
          cartTotalLength() {
              let totalLength = 0
              for (let i = 0; i < this.cart.items.length; i++) {
                
                  totalLength += parseInt(this.cart.items[i].quantity)
              }
              
              return totalLength;
    
          }
      },
    
    
      methods:{
        startdeliverypotvrda(){
       
          this.$root.otvoriform('ConfirmModal')
        },
        formatTime(hour) {
  if (hour === 0 || hour === 24) {
    return '24:00';
  } else if (hour === 12) {
    return '12:00';
  } else if (hour < 12) {
    return hour + ':00';
  } else {
    return hour + ':00';
  }
},

        startdelivery(){

          this.$root.zatvoriform('ConfirmModal')
          axios.post('/placanje/createbreakfast/', this.form)
                   .then((res) => {     
                    
                      this.buttonDisabled = false
                      return this.$router.push("/delivery/")
                        

                        
                   })
                   .catch((error) => {
                    console.lor(error)
                    this.$root.toastnoti(this.$t('error'), "Error while creating delivery, please contact us or try later", "error")
                    if (error.response.status === 401) {this.$router.push('/bye/')
                    axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              }
              this.buttonDisabled = false
                      
                   })


        },
        methodThatForcesUpdate(){
        window.location.reload()
      },
      isBefore8PM() {
        return true
        
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      return (currentHour >= 7 && currentHour < this.do)// 8pm is 20:00 in 24-hour format

    },
    tomorrowDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const day = tomorrow.getDate();
      const month = tomorrow.getMonth() + 1; // January is month 0
      return `${day}.${month}.`;
    },
    
      addToCart(jelo, price, vrsta, id) {
            const item = {
                jelo: jelo,
                price: price,
                quantity: "1",
                vrsta:vrsta,
                id:id
            }
           
            this.$store.commit('addToCart', item)
            this.$root.toastnoti(this.$t('success'), this.$t('Dodano'), "info")
               
        },
    
    
      searchJela(){
        $("#searchjelainput").keyup(function(){
            var inputText = $("#searchjelainput").val().toLowerCase();
            
            if ((inputText.length) > 0) {            
            
                $('.JELO').each(function() {
          
                        var $this = $(this);
                      
                    
                      if($this.attr('class').toLowerCase().match(inputText)) {
                        $this.show()
                      }
                      else{
                        $this.hide()
                      }
    
                      
                
          
            }); 
        }
        else{
            $(".JELO").show()
        }
    
      });
    
      },
    
    
         async getJela() {
    
          await axios
            .get('/mpguest/jelanovo/molpicio')
            .then(response => {
              this.jela = response.data.jela
   
              this.do = response.data.breakfastdo
             
              preloader.remove();
              
    
            
            })
            .catch(error => {
    
             if (error.response.status === 401) {
             //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
              preloader.remove();

            }
            })
    
        },
    
    
      },
    
    }
    </script>

<style>
:root {
  --c-white: #fff;
  --c-black: #000;
  --c-ash: #eaeef6;
  --c-charcoal: #a0a0a0;
  --c-void: #141b22;
  --c-fair-pink: #ffedec;
  --c-apricot: #fbc8be;
  --c-coffee: #754d42;
  --c-del-rio: #917072;
  --c-java: #1fcac5;
  --c-titan-white: #f1eeff;
  --c-cold-purple: #a69fd6;
  --c-indigo: #6558d3;
  --c-governor: #4133b7;
}

</style>
<style scoped>
.buttonoffer2 {
   background-color: var(--c-cold-purple) !important;
   padding-left: 10px;
   padding-right: 10px;
}
.buttonloyalty {
   margin-top: 100px;
}

/* Media query for desktop and larger devices */
@media (min-width: 768px) { /* Adjust the min-width as per your requirement */
   .buttonloyalty {
   margin-top: 150px;
}
}

.disabled{
   opacity: 0.5;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  width: 90%;
  max-width: 1000px;
  margin: 5vh auto;
}
.card {
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, .25);
  width: 340px;
}
.information {
  background-color: var(--c-white);
  padding: 1.5rem;
}
.information .tag {
  display: inline-block;
  background-color: var(--c-titan-white);
  color: var(--c-indigo);
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5em 0.75em;
  line-height: 1;
  border-radius: 6px;
}
.information .tag + * {
  margin-top: 1rem;
}
.information .title {
  font-size: 1.5rem;
  color: var(--c-void);
  line-height: 1.25;
}
.information .title + * {
  margin-top: 1rem;
}
.information .info {
  color: var(--c-charcoal);
}
.information .info + * {
  margin-top: 1.25rem;
}
.information .button {
  font: inherit;
  line-height: 1;
  background-color: var(--c-white);
  border: 2px solid var(--c-indigo);
  color: var(--c-indigo);
  padding: 0.5em 1em;
  border-radius: 6px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.information .button:hover, .information .button:focus {
  background-color: var(--c-indigo);
  color: var(--c-white);
}
.information .details {
  display: flex;
  gap: 1rem;
}
.information .details div {
  padding: 0.75em 1em;
  background-color: var(--c-titan-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.125em;
  flex-basis: 50%;
}
.information .details dt {
  font-size: 0.875rem;
  color: var(--c-cold-purple);
}
.information .details dd {
  color: var(--c-indigo);
  font-weight: 600;
  font-size: 1.25rem;
}
.plan {
  padding: 10px;
  background-color: var(--c-white);
  color: var(--c-del-rio);
}
.plan strong {
  font-weight: 600;
  color: var(--c-coffee);
}
.plan .inner {
  padding: 20px;
  padding-top: 40px;
  background-color: var(--c-fair-pink);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.ticketplan {
   background-color: var(--c-ash) !important;
}
.plan .pricing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--c-apricot);
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--c-coffee);
}
.plan .pricing small {
  color: var(--c-del-rio);
  font-size: 0.75em;
  margin-left: 0.25em;
}
.plan .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--c-coffee);
}
.plan .title + * {
  margin-top: 0.75rem;
}
.plan .info + * {
  margin-top: 1rem;
}
.plan .features {
  display: flex;
  flex-direction: column;
}
.plan .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.plan .features li + * {
  margin-top: 0.75rem;
}
.plan .features .icon {
  background-color: var(--c-java);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.plan .features .icon svg {

  height: 14px;
}
.plan .features + * {
  margin-top: 1.25rem;
}

.buttonoffer {
  font: inherit;
  background-color: var(--c-indigo);
  border-radius: 6px;
  color: var(--c-white);
  font-weight: 500;
  font-size: 1.125rem;
  width: 100%;
  border: 0;
  padding: 1em;
}
.plan button:hover, .plan button:focus {
  background-color: var(--c-governor);
}
</style>
<style scoped>

.bannertest{
   border-top-left-radius: 0;     /* Top left corner remains square */
   border-top-right-radius: 0;    /* Top right corner remains square */
   border-bottom-left-radius: 50%;  /* Bottom left corner is oval */
   border-bottom-right-radius: 50%; /* Bottom right corner is oval */
   height: 200px;
}
.modal-dialog{
  top:0% !important;
  text-align: center;
}

.modal-content{
 max-height:75vh !important;

}

@media only screen and (min-width: 600px) {
 .svgklub {
  
  
  width:50%;
  
 
 }
}
</style>