<template>
    
    <Navigation yvisina=15  @reload-parent="methodThatForcesUpdate">
        <template #atag><a></a></template>
<template #content>
    <br><br>

<div style="color:gray" class="text-center cd-fail-message"  v-if="!dostava.kosarica">{{ $t('nemasuvenira') }}</div>
  <div class="card" id="deliverydetail" v-if="dostava.kosarica && dostava.kosarica.length">
            <div class="title">{{ $t('suvenirdetalji') }}</div>
  
            
            <div class="info">
                <div class="row">
                    <div class="col-7">
                        
                        <span id="heading">From</span><br>
                        <span id="details">Reception</span>
                    </div>
                    <div class="col-5 pull-right">
                        <span id="heading">To</span><br>
                        <span id="details">MH {{dostava.suveniri[1]}}</span>
                    </div>
             
                </div>      
            </div>      
            <div class="pricing">
                <div class="row">
                    <template v-for="i in dostava.kosarica">
                   <div class="col-9">
                        <span id="name">{{i[0]}}</span>  
                    </div>
                   
                    <div class="col-3">
                        <span id="price">{{ i[1] }}x</span>
                    </div>
                </template>
             
                   

          
                </div>
            </div><br>
            <div class="tracking">
            <div class="title">{{ $t('extrainfo2') }}</div>
            <p class="info2" style="color:gray;">{{dostava.suveniri[2]}}</p>
        </div>
     
        </div>

</template>
</Navigation>

    

      </template>
    
    
    
    <script>
    import Navigation from '../components/Navigation.vue'
    import axios from 'axios'
    export default {
    
      name: 'HomeView',
      components: {
    Navigation
  },
      data(){
        return {
         
          username:'',
          dostava:[],
    
        }
      },
    
      methods:{
        methodThatForcesUpdate(){
            this.getDelivery();
        },
    
        otvoriModal(){
            $("#myModal").modal('show');     
        },
    

       async getDelivery() {
        const id = this.$route.params.id
          await axios
            .get('/mpguest/gostdostavasuveniri/')
            .then(response => {
              this.dostava = response.data
              
              preloader.remove();
    
            
            })
            .catch(error => {
                preloader.remove();
            })
    
        },
    },
    
      mounted(){
        this.username = localStorage.getItem('username')
        this.getDelivery();
        const preloader = document.querySelector('#preloader');
    
        
      },
    }
    </script>
    

      <style scoped>
     .card{
        margin: auto;
        width: 38%;
        max-width:600px;
        padding: 4vh 0;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-top: 3px solid var(--color-primary);
        border-bottom: 3px solid var(--color-primary);
        border-left: none;
        border-right: none;
    }
    @media(max-width:768px){
        .card{
            width: 90%;
        }
    }
    .title{
        color: var(--color-primary);
        font-weight: 600;
        margin-bottom: 2vh;
        padding: 0 8%;
        font-size: initial;
    }
    #details{
        font-weight: 400;
    }
    .info{
        padding: 5% 8%;
    }
    .info2{
        padding: 0% 8%;
    }
    .info .col-5{
        padding: 0;
    }
    #heading{
        color: grey;
        line-height: 6vh;
    }
    .pricing{
        background-color: #ddd3;
        padding: 2vh 8%;
        font-weight: 400;
        line-height: 2.5;
    }
    .pricing .col-3{
        padding: 0;
    }
    .total{
        padding: 2vh 8%;
        color: var(--color-primary);
        font-weight: bold;
    }
    .total .col-3{
        padding: 0;
    }
    .footer{
        padding: 0 8%;
        font-size: x-small;
        color: black;
    }
    .footer img{
        height: 5vh;
        opacity: 0.2;
    }
    .footer a{
        color: rgb(252, 103, 49);
    }
    .footer .col-10, .col-2{
        display: flex;
        padding: 3vh 0 0;
        align-items: center;
    }
    .footer .row{
        margin: 0;
    }
    #progressbar {
        margin-bottom: 3vh;
        overflow: hidden;
        color: rgb(252, 103, 49);
        padding-left: 0px;
        margin-top: 3vh
    }
    
    #progressbar li {
        list-style-type: none;
        font-size: x-small;
        width: 20%;
        float: left;
        position: relative;
        font-weight: 400;
        color: rgb(160, 159, 159);
    }
    
    #progressbar #step1:before {
        content: "";
        color: rgb(252, 103, 49);
        width: 5px;
        height: 5px;
        margin-left: 0px !important;
        /* padding-left: 11px !important */
    }
    
    #progressbar #step2:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-left: 32%;
    }
    
    #progressbar #step3:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 32% ; 
        /* padding-right: 11px !important */
    }
    
    #progressbar #step4:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 0px !important;
        /* padding-right: 11px !important */
    }

    
    #progressbar li:before {
        line-height: 29px;
        display: block;
        font-size: 12px;
        background: #ddd;
        border-radius: 50%;
        margin: auto;
        z-index: -1;
        margin-bottom: 1vh;
    }
    
    #progressbar li:after {
        content: '';
        height: 2px;
        background: #ddd;
        position: absolute;
        left: 0%;
        right: 0%;
        margin-bottom: 2vh;
        top: 1px;
        z-index: 1;
    }
    .progress-track{
        padding: 0 8%;
    }
    #progressbar li:nth-child(2):after {
        margin-right: auto;
    }
    
    #progressbar li:nth-child(1):after {
        margin: auto;
    }
    
    #progressbar li:nth-child(3):after {
        float: left;
        width: 68%;
    }
    #progressbar li:nth-child(4):after {
        margin-left: auto;
        width: 132%;
    }
    
    #progressbar  li.active{
        color: black;
    }
    
    #progressbar li.active:before,
    #progressbar li.active:after {
        background: #91d16f;
    }
    
      </style>
    