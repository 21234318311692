<template><br>

    <div>
      <Navigation yvisina=-200 @reload-parent="methodThatForcesUpdate">
        <template #atag>
          <a></a>
      
        </template>
        <template #content>

          <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con  text-center wow bounceInUp" data-wow-duration="1s">
              <p class="position-relative oasisnaslov text-white" style="    font-size: 0.5rem !important;font-weight: 500 !important;">
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;border-radius: 20px;font-size: 15px"  @click="  this.$router.push('/oasis/')"> <i class="fa fa-arrow-left"></i> Back to Oasis</button>
                </p>
           
             
               <h1 class="position-relative lead oasisnaslov text-white" style="font-size: 2.5rem !important;font-weight: 700 !important;">Saltwater Oasis Wellness&Spa</h1>
               <p class="position-relative oasisnaslov text-white" style="    font-size: 1.25rem;font-weight: 700 !important;">Massage & Face Treatments<br><br>
              
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;"  @click="this.$root.scrollToSection('rez')">Your Reservations</button></p>
               


            </div>
         </div>
      </div>
        

   </section>
<br><br>


      
          <div class="darken-image  image-container">
        
       
</div>

  
      <section class="menu">
        <div class="container">
        <div class="row">
        <div class="col-md-12">
          <section id="hero-animated" class="hero-animated d-flex align-items-center">
          <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
           

            <p v-if="prijava"></p>
   <p style="font-size: 18px;color:purple;"><i class="fa fa-gift"> </i> 30% off on all massages and face treatments!.<br><i class="fa fa-gift"> </i> Special offer! 2+1 free massage</p>

   <p>Offers are not reflected in prices on this app, make a reservation here and price with offers will be calculated on sight.</p>
 
         
          </div>
      
      </section>
        </div>
        </div>
        <div class="food-menu wow fadeInUp">
        <div class="row">
        <div class="col-md-12"> 
          <div class="menu-tags" >
          <span id="ruke" class="tagsort-active kategorija2" @click="filterruke(0)"><img src="../assets/massage.png" style="color:gray;width:30px;padding-bottom:5px;"><br><span >Massage</span></span>
        <span id="noge" class=" kategorija2" @click="filterruke(1)"><img src="../assets/face.png" style="color:gray;width:30px;padding-bottom:5px;"><br><span >Face</span></span>

        </div>

        <div class="menu-tags" v-if="ruke">
          <select class="form-select" aria-label="Hairdresser" @change="filter($event.target.value)" >
      
     <option value="1">Aroma 50min</option>
     <option value="2">Back, neck, head and shoulders 30min</option>
     <option value="3">Back, neck, head and shoulders 50min</option>
     <option value="4">Foot Reflexology 30min</option>
     <option value="5">Foot Reflexology 50min</option>
     <option value="6">Thai Classic 50min</option>
     <option value="7">REVIVE BODY PEELING WITH AROMA MASSAGE 80min</option>
     <option value="8">ALOE AFTER SUN TREATMENT  20min</option>

 

 
 </select>

       
        </div>
        <div class="menu-tags" v-if="!ruke">

          <select class="form-select" aria-label="Treatment Options" @change="filter($event.target.value)">
    <option value="10">HYDRATION AND REGENERATION TREATMENT PLATINUM FORLLED</option>
    <option value="11">TREATMENT BIOFILER FORLLED ANTI AGE</option>
    <option value="12">AESTHETIC NON-INVASIVE TREATMENTS HYALURONIC FILLER + BOTOX FACIAL</option>
    <option value="13">AESTHETIC NON-INVASIVE TREATMENTS SKIN BOOSTER + BOTOX FACIAL</option>
    <option value="14">AESTHETIC NON-INVASIVE TREATMENTS HYALURONIC FILLER</option>
    <option value="15">AESTHETIC NON-INVASIVE FACIAL BOTOX TREATMENTS</option>
    <option value="16">AESTHETIC NON-INVASIVE SKIN BOOSTER TREATMENTS</option>
</select>
 
 
 </div>



        </div>
      

       
 
        </div>
<br>
        <div class="row menu-items">
            <div :class="i.kategorija + ' card col-md-4 sve'" v-for="(i, key) in info">
  <img class="card-img-top" :src="i.img_path" alt="Card image cap" >
  <div class="card-body">
    <h5 class="card-title" > <span class="card-text vrsta" style="padding-right:5px;">{{i.vrsta}} </span> {{i.naslov}}</h5>
    <p class="card-text" v-html="i.kratakopis"></p>
    <p style="color:gray;opacity:0.8;cursor: pointer;" :id="'vise'+key.id" @click="viseopisa(key)">{{ $t('showmore') }}</p>
    <p class="card-text" :class="'opis'+key" style="display:none" v-html="i.opis"></p>
    <p class="card-text" :class="'opis'+key" style="display:none;font-weight:600;font-size: 16px;" v-html="i.dodatno"></p>
    

  </div>
</div>



</div>
        
        

        </div>
        </div>
        </section>
      
      <div class="container">
      <form style="padding:10px;" v-on:submit.prevent="getSport">
        <input type="date" class="form-control" name="date" min="2024-04-29"  v-model="form.datum" v-on:input="getSport"><br>
    
      </form>
    
    
      <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message">{{ $t('x39') }}</div>
      
      
          <div class="row">
            <div  class="col-md-4 col-xl-3" v-if="areKeysEmpty()">
                  <div class="card bg-c-tenis order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                              <h2 class="text-right">{{ $t('x45') }}</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
              
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke[getTimeValue(this.form.vrsta)]">
                  <div class="card bg-c-tenis order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
                        
                          <span>
                           

                            <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, this.getTimeValue(this.form.vrsta))}}h<span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>    
                          
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
         
          
              
          <section id="rez">
              <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message" v-if="prijava">{{ $t('x40') }}</div>
            </section>
              <div class="col-md-4 col-xl-3" style="padding-top:5px;" v-if="prijava && !rezervacije.length">
                  <div class="card bg-c-green order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                            <h2 class="text-right">You have no reservations</h2>
                            <p style="font-size: 13px;">Pick a massage type and select the best time!</p>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      
              <div class="col-md-4 col-xl-3"  style="padding-top:5px;" v-for="i in rezervacije">
                  <div class="card bg-c-green order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-times-circle f-right"  @click="cancelpotvrda(i[2], i[1], i[0])"  style="cursor:pointer;color:red;"></i>
    
                         
                          <span>
                
                            <h2 class="text-right"><b>Room {{ i[5] }}, {{ tretmani[i[3]] }}</b>&nbsp; {{ new Date(i[1]).toLocaleDateString('en-UK', {day: 'numeric', month: 'numeric'}) }} &nbsp;  {{TimeString(i[0])}}h -  {{TimeString2(i[0], this.getTimeValue(i[3]))}}h
         
</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      <div style="height:200px;"></div>
        </div>
      </div>
    
    
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
    
    <div class="footer-content">
      <div class="container">
        <div class="row">
    
          <div class="col-lg-12 col-md-12">
            <div class="footer-info">
              <h3>Saltwater Oasis Massage</h3>
              <p>
       
            <p>At  Saltwater Oasis Wellness&Spa <br> </p><br>
            <a href= "mailto: wellness@monperin.hr"> wellness@monperin.hr </a>
            <a href="tel: 38552219626">+ 385 52 219 626</a>
     
            
              </p>
            </div>
          </div>
    
    
          
    
          <div class="col-lg-4 col-md-12 footer-newsletter">
    
    
    
    
    </div>
    
    
    
    
        </div>
      </div>
    </div>
    
    
    </footer><!-- End Footer -->
    
        </template>
    
      </Navigation>
    
      <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">Reserve</h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')" ></i>


        </div>
        <div class="modal-body">
          
          
          <form>
   
          <div v-if="prijava"><p>Fee can be payed at the spot<br>You chose {{TimeString(form.terminsat)}}h 
            
            - {{TimeString2(form.terminsat, this.getTimeValue(this.form.vrsta))}}h
            <br><b style="color:#0ea2bd;">{{ tretmani[form.vrsta] }}</b></p>
          </div>
      

        </form>

        </div>
        <div class="modal-footer d-flex justify-content-center">
     
           <button  type="button" @click="rezerviraj" :disabled="buttonDisabled" class="btn btn-info f-left modalbutton" style="border:none;">Submit</button>
         
        </div>
      </div>
    </div>
  </div>


      <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('cancelres') }}</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>

            </div>
            <div class="modal-body">
              <p>{{ $t('cancelres2') }}</p>
            </div>
            <div class="modal-footer d-flex justify-content-center">
       <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;background-color: #dc3545;">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="reserveModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">Reserve</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('reserveModal')" ></i>

            </div>
            <div class="modal-body">
              <p>Since you are not logged in, please fill in this form:</p>
              <input class="form-control" type="text" placeholder="Your name or camp location...">
              <input class="form-control" type="email" placeholder="Email where we will send you confirmation">
            </div>
            <div class="modal-footer d-flex justify-content-center">
           <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      

    

      </div>
        </template>
        
     
        <script>
        import axios from 'axios'
        import Navigation from '../components/Navigation.vue'
        export default {
          name: 'Info',
          components: {
            Navigation
          },
        
          data(){
            return{
              datoteke:[],
              ruke:true,
              rezervacije:[],
              id:"",
              canceldatum:"",
              cancelsat:"",
              navBarKey:0,
              buttonDisabled: false,
              prijava:false,
              email:"",

              form:{
                datum:new Date().toISOString().substr(0, 10),
                email:"",
                terminsat:"",
                vrsta:"",
                trideset:false,
                lokacija:"oasis",
                ime:"",
              
    
              },
              cijene:{
                1:70,
                2:50,
                3:70,
                4:50,
                5:70,
                6:70,
                7:85,
                8:35,
           

                10:100,
                11:120,
                12:600,
                13:600,
                14:350,
                15:350,
                16:350
              },
              tretmani:{
                1:"CUSTOM AROMA MASSAGE 50",
                2:"BACK, NECK, HEAD, SHOULDERS 30",
                3:"BACK, NECK, HEAD, SHOULDERS 50",
                4:"FOOT REFLEXOLOGY 30",
                5:"FOOT REFLEXOLOGY 50",
                6:"THAI CLASSIC 50",
                7:"REVIVE BODY PEELING WITH AROMA MASSAGE 1'20",
                8:"ALOE AFTER SUN TREATMENT  20",

                10:"HYDRATION AND REGENERATION TREATMENT PLATINUM FORLLED 1 hour 30 min",
                11:"TREATMENT BIOFILER FORLLED ANTI AGE 1 hour 30 min",
                12:"AESTHETIC NON-INVASIVE TREATMENTS HYALURONIC FILLER + BOTOX FACIAL 1 hour 30 min",
                13:"AESTHETIC NON-INVASIVE TREATMENTS SKIN BOOSTER + BOTOX FACIAL 1 hour 30 min",
                14:"AESTHETIC NON-INVASIVE TREATMENTS HYALURONIC FILLER 1 hour 30 min",
                14:"AESTHETIC NON-INVASIVE FACIAL BOTOX TREATMENTS 1 hour 30 min",
                14:"AESTHETIC NON-INVASIVE SKIN BOOSTER TREATMENTSL 1 hour 30 min"
              }

            }
          },
          mounted(){
            
            window.scrollTo(0, 0)
            this.minute("pedeset")
            this.filter(1)

            this.platform = Capacitor.getPlatform()
            
            const preloader = document.querySelector('#preloader');
            this.prijava = this.$store.getters.isAuth;
            if(this.prijava){
              this.mojiTermini()
            }
    
            
            this.getSport()
          
        
          },
        
          methods:{
            botox(){
              const inputDate = new Date(this.form.datum); // replace with your input date
                  const dayOfWeek = inputDate.getDay();
                  if(dayOfWeek === 2){
                  
                    return true
                  }
                  return false

            },
            filterruke(broj){
                if(broj==0){
                    this.ruke = true
                    $('#noge').css('opacity', '0.5').removeClass('tagsort-active');
                    $('#ruke').addClass('tagsort-active').css('opacity', '1');
                }
                else{
                    this.ruke = false
                    $('#ruke').css('opacity', '0.5').removeClass('tagsort-active');
                    $('#noge').addClass('tagsort-active').css('opacity', '1');
                }
      
            },
            async togglepolicy(tekst){
              $("#"+tekst).toggle();
            },
    
            async plati(){
            if(!document.getElementById('flexCheckDefault').checked){
              this.$root.toastnoti(this.$t('error'), "Confirm sales policy to proceed...", "greska")
              return;
            }
              $("#preloader").show();
              $('#rezervirajModal').modal('hide')
              await axios
              
              .get("placanje/stvorihash/sport/"+this.form.datum+"/"+this.form.terminsat)
              .then(response => {
                this.$root.toastnoti(this.$t('success'), "Redirecting...", "uspjeh")
                this.openPaymentForm(response.data.hash, response.data.cartid)
      
      
        
              })
              .catch(error => {
                this.$root.toastnoti(this.$t('error'), "There has been error with our Payment service, please try to contact us because app might not be working properly", "greska")
                $("#preloader").hide();
               
              })
            },

    
            methodThatForcesUpdate(key){
              
              this.mojiTermini()
              this.getSport()
        
          },
    
          async cancelpotvrda(id){
              this.id = id
    
              this.$root.otvoriform('deleteConfirmModal')
      
          },
    
    
           moguceodgoditi(datum, sat){
            const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
            const now = new Date();
            const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));
            
    
            if (diffInHrs > 2 && targetDate > now) {
              return true
            } else {
              return false
            }
    
      
          },
          async rezervirajpotvrda(sat){
              this.form.terminsat = sat
          
              this.$root.otvoriform('rezervirajModal')
      
          },
          async cancel(){
    
                var apilink = '/mpguest/odgodimasaza/'+this.id
               
    
      
              await axios
              
                .get(apilink)
                .then(response => {
                  this.$root.toastnoti(this.$t('success'), this.$t('odgodatermina'), "uspjeh")
                  this.rezervacije = this.rezervacije.filter(arr => arr[2] !== this.id);
                  $('#deleteConfirmModal').modal('hide')
        
          
                })
                .catch(error => {
                  this.$root.toastnoti(this.$t('error'), this.$t('greska'), "uspjeh")
                 
                })
      
          },
          async rezerviraj(){
            this.buttonDisabled = true
            
          
              axios.post('/mpguest/rezervirajmasaza/', this.form)
            .then((res) => {
    
              
                if(this.prijava){
                  this.rezervacije.push([this.form.terminsat, this.form.datum, res.data.id, this.form.vrsta, res.data.soba])
               
                  this.$root.toastnoti(this.$t('success'), this.$t('reserved') + " Treatment is in Room "+res.data.soba, "uspjeh")
                  this.$root.scrollToSection('rez')
                }
                this.mojiTermini()
                
                
                $("#termin"+this.form.terminsat).remove();
                this.buttonDisabled = false;
                this.getSport()
                    
              })
            .catch((error) => {
          
              this.buttonDisabled = false;
              if (error.response.status === 401) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
              this.$router.push('/bye/')
            }
            else{
              this.$root.toastnoti(this.$t('error'), this.$t('greska'), "error")
            }
             
              
            })
    
            $('#rezervirajModal').modal('hide')
      
          },
         
            async getSport() {
              
              $("#preloader").show()
              this.datoteke = {}
                  const inputDate = new Date(this.form.datum); // replace with your input date
                  const dayOfWeek = inputDate.getDay();
                  const targetDate = new Date('2024-06-04');
            


// Check if it's Monday (dayOfWeek === 1)

        const now = new Date();
    
        const hasAlreadyPassed = inputDate < now &&
                                (inputDate.getFullYear() < now.getFullYear() ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));
    
    
              if (hasAlreadyPassed) {
              this.$root.toastnoti(this.$t('error'), this.$t('prosaodatum'), "error")
              $("#preloader").hide();
              return false;
            }
            if (!this.form.datum) {
              this.$root.toastnoti(this.$t('error'), this.$t('unesitedatum'), "error")
              return false;
            }
              //$("#preloader").show();
        
              await axios
                .get('/mpguest/masazatermini/oasis/'+this.form.datum)
                .then(response => {
                  this.datoteke = response.data
                  console.log(this.datoteke)
                  $("#preloader").hide();
                 

                })
                .catch(error => {
                  this.$root.toastnoti(this.$t('error'), this.$t('greska'), "greska")
                  $("#preloader").hide();
                
                 
                })
        
            },
            areKeysEmpty(obj) {
                if(obj) {
                    const keys = [30, 50, 120, 80, 20, 15, 25, 45, 60];

return keys.every(key => Array.isArray(obj[key]) && obj[key].length === 0);
                }
                else{
                    return false
                }
        
},


            getTimeValue(value) {
      const timeMapping = {
        8: 20,
        7: 80,
        2: 30, 4: 30, 43: 30, 44: 30, 45: 30, 46: 30, 47: 30, 50: 30,
        54: 30, 55: 30, 56: 30, 57: 30, 58: 30, 61: 30, 66: 30, 67: 30,
        10: 90, 11: 90, 12: 90, 13: 90, 14: 90, 15: 90, 16: 90, 40: 90, 
        41: 90, 51: 90, 52: 90,
        1: 50, 3: 50, 5: 50, 6: 50,
        42: 60, 53: 60, 65: 60,
        64: 45, 68: 45,
        62: 25,
        48: 15, 49: 15, 59: 15, 60: 15, 63: 15
      };
      return timeMapping[value] || null; // Return null if the value is not found
    },
            async mojiTermini() {
              await axios
                .get('/mpguest/mojiterminimasaza/oasis')
                .then(response => {
                  this.rezervacije = response.data.termini
                  
                  
                  $("#preloader").hide();
        
          
                })
                .catch(error => {
                  //this.$root.toastnoti('Log in', "You are not logged in, but you can still", "greska")
                  $("#preloader").hide();
                 
                })
        
            },
            filter(vrsta){
              vrsta = Number(vrsta);
              if([12, 13, 14, 15, 16].includes(vrsta)){
                const inputDate = new Date(this.form.datum); // replace with your input date
                  const dayOfWeek = inputDate.getDay();
                  if(dayOfWeek !== 2){
                    this.$root.toastnoti("Info", "Botox and filler treatments are done only on tuesdays from 12pm to 4pm. Pick a tuesday to see available times", "info")
                    return false
                  
                  }
        
              }
  
        $('.kategorija').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
        this.form.vrsta = vrsta
        if(vrsta != 0 && vrsta!=1){
          $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
          $('#pedeset').addClass('tagsort-active').css('opacity', '1');
          this.form.trideset = false
        }

        $('.'+vrsta ).show()


    },
    minute(vrsta){
        $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
  if(vrsta=="trideset"){
    this.form.trideset = true
  }
  else{
    this.form.trideset=false
  }

  $('.'+vrsta ).show()


},
vrstaime(vrsta){
        switch (vrsta) {  

      case 0 : return 'Back, neck, head and shoulders';
      case 1 : return 'Foot Reflexolog';
      case 2: return 'Coconut Massage';
      case 3: return 'Aroma massage';
      case 5: return 'Thai oil Massage';
      case 4: return 'Thai traditional massage';
      case 6: return 'Coconut oil massage';
      default: return 'Foot Reflexolog';
    }
    





},
nemasdostupno(){
  if (this.form.trideset){
    if(!this.datoteke.tri || this.datoteke.tri.length === 0){
      return true
    }
    return false
  }
  else{
    if(!this.datoteke.pet || this.datoteke.pet.length === 0){
      return true
    }
    return false
  }
 
},
TimeString(time){

var time = new Date('2023-01-01T' + time);
var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
},
TimeString2(time, broj){

var time = new Date('2023-01-01T' + time);
time.setMinutes(time.getMinutes() + broj);

var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
}
          }
        }
        </script>
      
      
      <style>
  
  .image-container img {

    display: block;
    width: 100%;
    min-height: 300px;
    max-height: 5%;
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
  
  }
.darken-image {
  
    position: relative;
    display: inline-block;
  }

  .darken-image img {
    display: block;
  }
  .darken-image .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
      
      .btn-getstarted {
          font-size: 16px;
          color: var(--color-white);
          background: var(--color-primary);
          padding: 8px 23px;
          border-radius: 4px;
          transition: 0.3s;
          font-family: var(--font-secondary);
          border:none;
    
      
      
      }
    
      
      .order-card {
          color: #fff;
      }
      
      .bg-c-blue {
          background: linear-gradient(45deg,#4099ff,#73b4ff);
      }
      
      .bg-c-green {
      
          background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
          
      }
      
      .bg-c-yellow {
          background: linear-gradient(45deg,#FFB64D,#ffcb80);
      }
      
      .bg-c-pink {
          background: linear-gradient(45deg,#FF5370,#ff869a);
      }
      
      
      .card {
          border-radius: 5px;
          -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          border: none;
          margin-bottom: 30px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
      }
      
      .card .card-block {
          padding: 25px;
      }
      
      .order-card i {
          font-size: 26px;
      }
      
      .f-left {
          float: left;
      }
      
      .f-right {
          float: right;
      }
      
      
      .bg-c-tenis {
          background: linear-gradient(45deg,#bed6db,#44a3b6);
      }
      </style>
      
      <style scoped>
        
      .hero-animated {
          padding: 50px 0 0px; 
          min-height: 30vh;
      }
      
      section {
          padding: 0px 0; 
      
      }
    
      .hero-animated p {
        color: rgba(var(--color-secondary-rgb),.8);
        margin: 0 0 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    
    @media (max-width: 640px){
      .hero-animated p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    }
    .menu-tags span{
      display: inline-block;
    }
    
      
      </style>
      
    <style scoped>
    .btn-outline-light:hover {

    background-color: transparent;
    border-color: #f8f9fa;
}

    .oasisnaslov{
      font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
    }
    
    .sub-banner-sec {
  background: url(../assets/masaza.jpg) no-repeat center;
  
  background-size: cover;
  height: 561px;


}

    .hero-animated{
      min-height: 15vh;
      padding: 0px
    }
    </style>