<template><br>

    <div>
      <Navigation yvisina=-200 @reload-parent="methodThatForcesUpdate">
        <template #atag>
          <a></a>
      
        </template>
        <template #content>

          <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con  text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative lead oasisnaslov text-white" style="font-size: 2.5rem !important;font-weight: 700 !important;">Saltwater Oasis Wellness&Spa</h1>
               <p class="position-relative oasisnaslov text-white" style="    font-size: 1.25rem;font-weight: 700 !important;">What are you looking for?<br><br>
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;"  @click="  this.$router.push('/massage/oasis/')">Massage & Face Treatments</button>&nbsp;<br>
        
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;margin-top: 10px;"  @click="  this.$router.push('/manicure/')">Manicure and Pedicure</button>&nbsp;
                <button type="button" v-show="true"  @click="this.$router.push('/oasis/fitness/')" class="btn btn-outline-light btn-sm"  style="border-color: white;margin-top: 10px;"  >Fitness</button>&nbsp;
              
                </p>

          
             


            </div>
         </div>
      </div>
        

   </section>
<br><br>


      
          <div class="darken-image  image-container">
        
       
</div>

  
    
    
    <!-- ======= Footer ======= -->

    
        </template>
    
      </Navigation >
    
  

    

      </div>
        </template>
        
     
        <script>
        import axios from 'axios'
        import Navigation from '../components/Navigation.vue'
        export default {
          name: 'Info',
          components: {
            Navigation
          },
        
          data(){
            return{
     
            }
          },
          mounted(){
            $('#preloader').remove();
            window.scrollTo(0, 0)
          
        
          },
        
          methods:{
            working(){
              this.$root.toastnoti("Info", "We are working on bringing  this feature at your fingertip. In the meantime please contact wellness@monperin.hr for more information.", "info")
            }
       
          }
        }
        </script>
      
      
      <style>
  
  .image-container img {

    display: block;
    width: 100%;
    min-height: 300px;
    max-height: 5%;
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
  
  }
.darken-image {
  
    position: relative;
    display: inline-block;
  }

  .darken-image img {
    display: block;
  }
  .darken-image .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
      
      .btn-getstarted {
          font-size: 16px;
          color: var(--color-white);
          background: var(--color-primary);
          padding: 8px 23px;
          border-radius: 4px;
          transition: 0.3s;
          font-family: var(--font-secondary);
          border:none;
    
      
      
      }
    
      
      .order-card {
          color: #fff;
      }
      
      .bg-c-blue {
          background: linear-gradient(45deg,#4099ff,#73b4ff);
      }
      
      .bg-c-green {
      
          background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
          
      }
      
      .bg-c-yellow {
          background: linear-gradient(45deg,#FFB64D,#ffcb80);
      }
      
      .bg-c-pink {
          background: linear-gradient(45deg,#FF5370,#ff869a);
      }
      
      
      .card {
          border-radius: 5px;
          -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          border: none;
          margin-bottom: 30px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
      }
      
      .card .card-block {
          padding: 25px;
      }
      
      .order-card i {
          font-size: 26px;
      }
      
      .f-left {
          float: left;
      }
      
      .f-right {
          float: right;
      }
      
      
      .bg-c-tenis {
          background: linear-gradient(45deg,#bed6db,#44a3b6);
      }
      </style>
      
      <style scoped>
        
      .hero-animated {
          padding: 50px 0 0px; 
          min-height: 30vh;
      }
      
      section {
          padding: 0px 0; 
      
      }
    
      .hero-animated p {
        color: rgba(var(--color-secondary-rgb),.8);
        margin: 0 0 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    
    @media (max-width: 640px){
      .hero-animated p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    }
    .menu-tags span{
      display: inline-block;
    }
    
      
      </style>
      
    <style scoped>
    .btn-outline-light:hover {

    background-color: transparent;
    border-color: #f8f9fa;
}

    .oasisnaslov{
      font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
    }
    
    .sub-banner-sec {
  background: url(../assets/oasis.jpg) no-repeat center;
  
  background-size: cover;
  height: 561px;


}

    .hero-animated{
      min-height: 15vh;
      padding: 0px
    }
    </style>