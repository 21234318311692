<template><br>

    <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
  <template #atag><a></a></template>
  <template #content>
    <div style="padding-top: 100px;"></div>
  <section id="hero-animated" class="hero-animated d-flex align-items-center">
      <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
  
  
        <h2><span>{{ $t('surveynaslov') }}</span></h2>
        <p>Tell us more about your stay</p>
     
      </div>
  
  </section>

    <section id="featured-services" class="featured-services">
        <div class="container">
  
          <div class="row gy-4">
           <div class="col-xl-4 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="200">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa fa-home icon"></i></div>
                <h4><a href="https://survey.hospitalityinsights.eu/-/single/ishDAuDPzQDd0XqoysWs1ocVuOBEX4P?st=W9AEuil0eqZjd4prw5Ou3lPCU8HiV5di4O3HybpMW92wSG6SMnSI2Qmycqm$Ns!J" class="stretched-link">{{ $t('s1naslov') }}</a></h4>
                <p>{{ $t('s1opis') }}</p>
              </div>
            </div><!-- End Service Item -->

            <div class="col-xl-4 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="200" >
              <div class="service-item position-relative">
                <div class="icon"><i class="fa fa-tent icon"></i></div>
                <h4><a href="https://survey.hospitalityinsights.eu/-/single/7bcA7vNFDSlgj6vdlKp47ePGrEdC9fp?st=SMRus7qWB0CpbCvPKHUB7gZSdGYpalKncCHJ4iI2!jFL5!X8qG$vZP4Vi674baUw" class="stretched-link">{{ $t('s2naslov') }}</a></h4>
                <p>{{ $t('s2opis') }}</p>
              </div>
            </div><!-- End Service Item -->

            <div class="col-xl-4 col-md-6 d-flex" data-aos="zoom-out" data-aos-delay="200">
              <div class="service-item position-relative">
                <div class="icon"><i class="fa fa-tent icon"></i> <i class="fa fa-home icon"></i></div>
                <h4><a href="https://survey.hospitalityinsights.eu/-/single/k16wJfqJkCoafYatfT8ExxeFl88YaCI?st=b4kFmGURF57aKxpvoQPGbJEGgl!fClDccAQ3VmPe4fVPYGttyBgjI3F4!oEBR1hL" class="stretched-link">{{ $t('s3naslov') }}</a></h4>
                <p>{{ $t('s3opis') }}</p>
              </div>
            </div><!-- End Service Item -->
  


          </div>
  
        </div>
      </section><!-- End Featured Services Section --></template>
  </Navigation>
  
  
  
  </template>
  
  <script>
  
  import axios from 'axios'
  import Navigation from '../components/Navigation.vue'
  export default {
  
    name: 'Info',
    components: {
      Navigation
    },
  
    data(){
      return{
        datoteke:[],
        mh:false,
      }
    },
  
    mounted(){
      $('#preloader').remove();


    const preloader = document.querySelector('#preloader');

    },
  
  
    methods:{
  
   
   

  

    }
  }
  </script>
  
  <style scoped>
  
  .hero-animated {
      padding: 0px 0 0px; 
      min-height: 10vh;
  }
  
  section {
      padding: 0px 0; 
  
  }
  
  </style>
  