<template>


  <Navigation yvisina=0>
    <template #atag>
      <a></a>
    </template>
    <template #content>

      <div style="padding-top: 70px;"></div>

<section id="hero-animated" class="hero-animated d-flex align-items-center">
  <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">


    <h2><span>{{ $t('Souvenirs') }}</span></h2>
    <p v-html="$t('suveniri')"></p>
    <div class="d-flex">

    </div>
 
  </div>

</section>  


<div class="product-card" v-for="i in svisuveniri">
  
<div class="product-details">
  
  <p style="font-weight:bold;color:#0ea2bd;">{{ i[4] }}</p>
  <p style="word-wrap: break-word; width: 150px;" >{{ i[1] }}</p>

  <!--@click="addToCart(i[4], i[2], i[3])"-->
  <button class="btn-getstarted scrollto" style="color:White; cursor:pointer;">{{ i[2] }}€</button>
</div>
<div class="product-image">
  <img :src="i[0]" class="containjelo img--coverjelo imgjelo img-fluid">

</div>

</div>
<div style="padding-bottom:100px;"></div>
    </template>

</Navigation>
<br><br>




</template>



<script>
import Navigation from '../components/Navigation.vue'
import axios from 'axios'
export default {
  name: 'Info',
  components: {
    Navigation
  },
  data(){
  return{
    svisuveniri:[],
    suveniri: {
      items: []
    }
  }},
  mounted(){
  
    this.suveniri = this.$store.state.suvenircart
    
    this.SviSuveniri();
  },
  computed: {
    cartTotalLength() {
        let totalLength = 0
        for (let i = 0; i < this.suveniri.items.length; i++) {
          
            totalLength += parseInt(this.suveniri.items[i].quantity)
        }
        return totalLength;

    }
},
  methods:{
    methodThatForcesUpdate(){
    window.location.reload()
  },
  addToCart(ime, price, id) {
      const item = {
          ime: ime,
          price: price,
          quantity: "1",
          id:id
      }
      
      this.$store.commit('addToCartSuveniri', item)
         
  },
  async SviSuveniri() {
      const id = this.$route.params.id
        await axios
          .get('/api/svisuveniri/')
          .then(response => {
            this.svisuveniri = response.data
            document.querySelector('#preloader').remove();
  
          
          })
          .catch(error => {
              if (error.response.status === 401) {this.$router.push('/bye/')
              axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              }
          preloader.remove();
          })
  
      },
  },
 
  
}
</script>
<style scoped>

.hero-animated {
    padding: 25px 0 0px; 
    min-height: 23vh;
}

section {
    padding: 0px 0; 

}


.btn-getstarted {
    font-size: 16px;
    color: var(--color-white);
    background: var(--color-primary);
    padding: 8px 23px;
    border-radius: 4px;
    transition: 0.3s;
    font-family: var(--font-secondary);
    border:none;


}

.naslovkart{
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    font-family: var(--font-secondary);
    color: var(--color-secondary);
    font-weight: 600;
}

.product-card {

  display: flex;
  align-items: center;
  justify-content: space-between;
   background-color: #fdfefe;
   max-width: 550px;
   min-height: 300px;
   margin: 30px auto;
   margin-top: 0px;

   box-shadow: 8px 12px 30px #b3b3b3;
   color: #919495;
   font-weight: normal;
   text-align: left;
   font-size: 18px;
   position: relative;
}
 .product-details {
    width: 53%;
        float: left;
   height: 100%;
   padding: 30px;
}
 .product-details h1 {
   color: #333;

   margin-bottom: 35px;
}



.product-image {
  flex: 0 0 auto;
  margin-left: 10px;
  max-width: calc(50% - 10px);
}

.product-image img {
  max-width: 100%;
  height: auto;
}


 @media (max-width: 700px) {
   .product-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
     margin-left: 20px;
     margin-right: 20px;
  }
}
 @media (max-width: 540px) {
   .product-card {
     overflow: hidden;
     margin-bottom: 50px;
  }
   .product-details {
     width: 10%;
     z-index: 1;
  }

}
 @media (max-width: 440px) {
   .product-details {
     width: 10%;
  }
}
 @media (max-width: 365px) {
   .product-details {
     width: 10%;
     position: relative;
     color: #333;
     background-color: rgba(255, 255, 255, 0.7);
  }
}
</style>
  