import { createStore } from 'vuex'

export default createStore({
  state: {
   
    token: '',
    isAuthenticated:false,
    cart: {items: []},
    suvenircart:{items:[]}
  },
  getters: {
    isLoggedIn(state) {

      return state.user !== null;
  },

  isAuth(state) {

    return state.isAuthenticated
},
changeAuth(state) {

  state.isAuthenticated=false

}
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('suvenircart')) {
        state.suvenircart = JSON.parse(localStorage.getItem('suvenircart'))

      } else {
        localStorage.setItem('suvenircart', JSON.stringify(state.suvenircart))
      }


      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      } 
  },
  addToCart(state, item) {
    const exists = state.cart.items.filter(i => i.jelo === item.jelo)

    if (exists.length) {
      exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
    } else {
      state.cart.items.push(item)
    }

    localStorage.setItem('cart', JSON.stringify(state.cart))
  },

  removeFromCart(state, item) {
    console.log(item)
    const index = state.cart.items.findIndex(i => i.id === item.id);
    console.log(index)

    if(index !== -1){
      item =  state.cart.items[index];

      if(item.quantity > 1){
          item.quantity--; //remove one quantity
      }else{
        state.cart.items.splice(index, 1);
      }
    }

    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  clearCart(state) {
    state.cart = { items: [] }

    localStorage.setItem('cart', JSON.stringify(state.cart))
  },


  addToCartSuveniri(state, item) {
    const exists = state.suvenircart.items.filter(i => i.ime === item.ime)
    if (exists.length) {
      exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
    } else {

      state.suvenircart.items.push(item)
    }
    localStorage.setItem('suvenircart', JSON.stringify(state.suvenircart))
  },
  removeFromCartSuveniri(state, item) {

    const exists = state.suvenircart.items.filter(i => i.ime === item.ime)
    

    if (item.quantity>1) {
      exists[0].quantity -=1;
    } else {
      state.suvenircart.items.splice(state.suvenircart.items.indexOf(exists), 1)
    }

    localStorage.setItem('suvenircart', JSON.stringify(state.suvenircart))
  },
  clearCartSuveniri(state) {
    state.suvenircart = { items: [] }

    localStorage.setItem('suvenircart', JSON.stringify(state.suvenircart))
  },
  

  setToken(state, token){
    state.token = token
    state.isAuthenticated = true
  },
  removeToken(state){
    state.token=''
    state.isAuthenticated = false
  }
},
  actions: {
  },
  modules: {
  }
})

