<template>

    <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
        <template #atag>
            <a></a>
        </template>

        <template #content>
            <br>
    <div id="baseApp" style="background-color: #fafbfc;">
    <aside class='task-details  d-flex justify-content-center' id="notifikacijee" ref="notifi">
    
          <div class='task-activity'>
            <h2>{{ $t('notifikacije') }}</h2>
            <p>{{ $t('notifikacijeopis') }}</p><br>
            
            <ul>
              <li v-for="notifikacija in obavijesti"> <span class='task-icon task-icon--attachment'><i style="color:white;"  :class="ikonaNotifikacije(notifikacija.vrsta)"></i></span><b>{{notifikacija.naslov}}</b><br>
                <span v-html="notifikacija.opis"></span>  <time datetime="2021-11-24T20:00:00">{{notifikacija.timestamp}}</time>
               
                <a @click="openfile(notifikacija.img_path)"  class="download-link">
                <img  onerror="this.style.display='none'" :src="notifikacija.img_path" style="padding:10px;width:100%;border-radius: 25px;" alt="Image" class="image">
                <i style="font-size: 50px;color:white;opacity: 0.8;" class="fa fa-download download-icon"></i>
                </a>
            </li>
              <li v-if="obavijesti.length==0"> <span class='task-icon task-icon--attachment'><i style="color:white;"  :class="'fa fa-envelope'"></i></span><b>{{ $t('notifikacijenaslov') }}</b><time datetime="2021-11-24T20:00:00"></time></li>
            </ul>
            
            
          </div>
    
      </aside>
    </div>
    
        </template>
    
    </Navigation>
    
    

    </template>
    
    
    
    <script>
   
    import axios from 'axios'
    import Navigation from '../components/Navigation.vue'
    export default {
      name: 'Info',
      components: {
        Navigation
      },
    
      data(){
        return{
          obavijesti:[],
        }
      },
      mounted(){
    
      this.getDatoteke()
        
    
        const preloader = document.querySelector('#preloader');
      
    
      },
    
      methods:{
        methodThatForcesUpdate(){
            this.getDatoteke()
      },
      openfile(url){

      

        window.open(url, '_blank');


    },


      ikonaNotifikacije(status) {
      switch (status) {  
      case '0': return 'fa fa-envelope';break;
      default: return 'fa fa-envelope';
    }},
        async getDatoteke() {
    
          await axios
            .get('/mpguest/obavijesti/')
            .then(response => {
              this.obavijesti = response.data
              console.log(this.obavijesti)
              
              preloader.remove();
    
      
            })
            .catch(error => {
    
              if (error.response.status === 401) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              this.$router.push('/bye/')
            }
             
            })
    
        },
      }
    }
    </script>
    
    
    
    <style>
   .download-link {
  position: relative;
  display: inline-block;
}

.image {
  display: block;
  transition: filter 0.3s;
}

.download-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  background: url(../assets/1.png) no-repeat center center;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.download-link:hover .image {
  filter: brightness(50%);
}

.download-link:hover .download-icon {
  display: block;
}


    :root {  
        --bg: #ebf0f7;  
        --header: #fbf4f6;  
        --text: #2e2e2f;  
        --white: #fff;  
        --light-grey: #c4cad3;  
        --tag-1: #ceecfd;  
        --tag-1-text: #2e87ba;  
        --tag-2: #d6ede2;  
        --tag-2-text: #13854e;  
        --tag-3: #ceecfd;  
        --tag-3-text: #2d86ba;  
        --tag-4: #f2dcf5;  
        --tag-4-text: #a734ba;  
        --purple: #7784ee;  
    }  
    
    @media (max-width: 991px) {
        .multivrsta{
          padding-bottom: 16px !important;
        }
      }
      
    .project-participants{
        display: none !important;
    }
    
    .bg-zelena{
        background: green !important;
        
    }
    .bg-nova{
        background: rgba(154, 187, 230, 0.027) !important;
        
    }
    
    
    .bg-ljubicasta {
        background: var(--purple) !important;
    }
    
    
    .bg-plava {
        background: #267fdd !important;
    }
    
    
    .bg-roza {
        background: linear-gradient(45deg,#FF5370,#ff869a) !important;
    }
    
    .bg-zuta {
        background: linear-gradient(45deg,#FFB64D,#ffcb80) !important;
    }
    
    
    
    ul{
        padding-left: 0px;
    }
    
    * {  
        margin: 0;  
        padding: 0;  
        box-sizing: border-box;  
        font-family: 'Poppins', sans-serif;  
    }  
    body {  
        color: var(--text);  
    }  
    .app {  
    
        
        width: 100%;  
        min-height: 100vh;  
    }  
    @media screen and (min-width: 800px) {
        .app {  
            padding-left: 20px;
        }
    }
    h1 {  
        font-size: 30px;  
    }  
    .project {  
        padding: 2rem;  
        max-width: 75%;  
        width: 100%;  
        display: inline-block;  
    }  
    .project-info {  
        padding: 2rem 0;  
        display: flex;  
        width: 100%;  
        justify-content: space-between;  
        align-items: center;  
    }  
    .project-participants {  
        display: flex;  
        align-items: center;  
    }  
    .project-participants span, .project-participants__add {  
        width: 30px;  
        height: 30px;  
        display: inline-block;  
        background: var(--purple);  
        border-radius: 100rem;  
        margin: 0 0.2rem;  
    }  
    .project-participants__add {  
        background: transparent;  
        border: 1px dashed #969696;  
        font-size: 0;  
        cursor: pointer;  
        position: relative;  
    }  
    .project-participants__add:after {  
        content: '+';  
        font-size: 15px;  
        color: #969696;  
    }  
    .project-tasks {  
        display: grid;  
        grid-template-columns: repeat(4, 1fr);  
        width: 100%;  
        grid-column-gap: 1.5rem;  
    }  
    .project-column-heading {  
        margin-bottom: 1rem;  
        display: flex;  
        align-items: center;  
        justify-content: space-between;  
    }  
    .project-column-heading__title {  
        font-size: 20px;  
    }  
    .project-column-heading__options {  
        background: transparent;  
        color: var(--light-grey);  
        font-size: 18px;  
        border: 0;  
        cursor: pointer;  
    }  
    .task {  
        cursor: pointer;  
        background-color: white ;
        padding: 1rem;  
        border-radius: 8px;  
        width: 100%;  
        box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;  
        margin-bottom: 1rem;  
        border: 3px dashed transparent;  
    }  
    .task:hover {  
        box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;  
        border-color: rgba(162, 179, 207, .2) !important;  
    }  
    .task p {  
        font-size: 15px;  
        margin: 1.2rem 0;  
    }  
    .task__tag {  
        border-radius: 100px;  
        padding: 2px 13px;  
        font-size: 12px;  
    }  
    .task__tag--copyright {  
        color: var(--tag-4-text);  
        background-color: var(--tag-4);  
    }  
    .task__tag--design {  
        color: var(--tag-3-text);  
        background-color: var(--tag-3);  
    }  
    .task__tag--illustration {  
        color: var(--tag-2-text);  
        background-color: var(--tag-2);  
    }  
    .task__tags {  
        width: 100%;  
        display: flex;  
        align-items: center;  
        justify-content: space-between;  
    }  
    .task__options {  
        background: transparent;  
        border: 0;  
        color: var(--light-grey);  
        font-size: 17px;  
    }  
    .task__stats {  
        position: relative;  
        width: 100%;  
        color: var(--light-grey);  
        font-size: 12px;  
    }  
    .task__stats span:not(:last-of-type) {  
        margin-right: 1rem;  
    }  
    .task__stats svg {  
        margin-right: 5px;  
    }  
    .task__owner {  
        width: 25px;  
        height: 25px;  
        border-radius: 100rem;  
        position: absolute;  
        display: inline-block;  
        right: 0;  
        bottom: 0;  
    }  
    .task-hover {  
        border: 3px dashed var(--light-grey) !important;  
    }  
    .task-details {  
        width: 20%;  
        border-left: 1px solid #d9e0e9;  
        display: inline-block;  
        height: 100%;  
        vertical-align: top;  
        padding: 3rem 2rem;  
    }  
    .tag-progress {  
        margin: 1.5rem 0;  
    }  
    .tag-progress h2 {  
        font-size: 20px;  
        margin-bottom: 1rem;  
    }  
    .tag-progress p {  
        display: flex;  
        width: 100%;  
        justify-content: space-between;  
    }  
    .tag-progress p span {  
        color: #b4b4b4;  
    }  
    .tag-progress .progress {  
        width: 100%;  
        -webkit-appearance: none;  
        appearance: none;  
        border: none;  
        border-radius: 10px;  
        height: 10px;  
    }  
    .tag-progress .progress::-webkit-progress-bar, .tag-progress .progress::-webkit-progress-value {  
        border-radius: 10px;  
    }  
    .tag-progress .progress--copyright::-webkit-progress-bar {  
        background-color: #ecd8e6;  
    }  
    .tag-progress .progress--copyright::-webkit-progress-value {  
        background: #d459e8;  
    }  
    .tag-progress .progress--illustration::-webkit-progress-bar {  
        background-color: #dee7e3;  
    }  
    .tag-progress .progress--illustration::-webkit-progress-value {  
        background-color: #46bd84;  
    }  
    .tag-progress .progress--design::-webkit-progress-bar {  
        background-color: #d8e7f4;  
    }  
    .tag-progress .progress--design::-webkit-progress-value {  
        background-color: #08a0f7;  
    }  
    .task-activity h2 {  
        font-size: 20px;  
        margin-bottom: 1rem;  
    }  
    .task-activity li {  
        list-style: none;  
        margin: 1rem 0;  
        padding: 0rem 1rem 1rem 3rem;  
        position: relative;  
    }  
    .task-activity time {  
        display: block;  
        color: var(--light-grey);  
    }  
    .task-icon {  
        width: 30px;  
        height: 30px;  
        border-radius: 100rem;  
        position: absolute;  
        top: 0;  
        left: 0;  
        display: flex;  
        align-items: center;  
        justify-content: center;  
    }  
    .task-icon svg {  
        font-size: 12px;  
        color: var(--white);  
    }  
    .task-icon--attachment {  
        background-color: #0ea2bd;  
    }  
    .task-icon--comment {  
        background-color: #5dc983;  
    }  
    .task-icon--edit {  
        background-color: #7784ee;  
    }  
    @media only screen and (max-width: 1300px) {  
        .project {  
             max-width: 100%;  
       }  
        .task-details {  
             width: 100%;  
             display: flex;  
       }  
        .tag-progress, .task-activity {  
             flex-basis: 50%;  
             background: var(--white);  
             padding: 1rem;  
             border-radius: 8px;  
             margin: 1rem;  
       }  
    }  
    
    .project {  
             max-width: 100%;  
       }  
        .task-details {  
             width: 100%;  
             display: flex;  
       }  
        .tag-progress, .task-activity {  
             flex-basis: 50%;  
             background: var(--white);  
             padding: 1rem;  
             border-radius: 8px;  
             margin: 1rem;  
    } 
    
    
    @media only screen and (max-width: 600px) {  
    
    .task-details {  
         flex-wrap: wrap;  
         padding: 3rem 1rem;  
    }  
    .tag-progress, .task-activity {  
         flex-basis: 100%;  
    }  
    h1 {  
         font-size: 25px;  
    }  
    }  
    
    
    @media only screen and (max-width: 600px) {  
    
        .task-details {  
             flex-wrap: wrap;  
             padding: 3rem 1rem;  
       }  
        .tag-progress, .task-activity {  
             flex-basis: 100%;  
       }  
        h1 {  
             font-size: 25px;  
       }  
    }  
    
    
    
    </style>