<template>
  <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
    <template #atag> <a></a></template>
    <template #content>
      <div style="padding-top: 90px;"></div>
      <br>
      
  <div class="content">
    <div class="container">
      <div class="row align-items-stretch no-gutters contact-wrap">
        <div class="col-md-12">
          <div class="form h-100">
            <h3 class="naslov">{{ $t('profiledetails') }}</h3>
            <span v-if="this.$store.state.token">
 
 <button @click="logout()" class="btn btn-danger">{{ $t('logout1') }} {{ prijeusername }}</button><br>
 </span><br>
<!--
            <h3 style="color: green" v-if="profil.broj__status == 2">
              {{ $t('statuscisto') }}
            </h3>
            <h3
              style="color: red"
              v-if="profil.broj__status == 0 || profil.broj__status == 1"
            >
              {{ $t('statusprljavo') }}
            </h3>
          -->
            <form v-on:submit.prevent="submitPost" class="mb-5">

              <div class="mb-5">
                <label for="message" class="col-form-label">{{ $t('profil1') }}</label>
                <input
                  class="form-control"
                  id="email"
                  :placeholder="profil.broj__lokacija"
                  style="color: gray"
                  disabled
            
                />
              </div>
              <div class="mb-5">
                <label for="message" class="col-form-label">{{ $t('profil2') }}</label>
                <input
                  class="form-control"
                  id="email"
                  :placeholder="profil.ime"
                  style="color: gray"
                  disabled
            
                />
                </div>
              
                <div class="mb-5">
                <label for="message" class="col-form-label">Id*</label>
                <input
                  class="form-control"
                  id="email"
                  :placeholder="profil.hisid"
                  style="color: gray"
                  disabled
            
                />
                </div>
              

              <div class="form-group mb-5" >
                <label for="message" class="col-form-label"
                  > {{ $t('tel1') }}</label
                >
                <vue-tel-input
                  v-bind="bindprops"
                  v-model="formprofil.telefon"
                  mode="international"
                ></vue-tel-input>
              </div>

             
            
              <div class="form-group mb-5">
                <label for="message" class="col-form-label">Email *</label>
                <input
                  class="form-control"
                  type="text"
                  id="email"
                  :placeholder="profil.email"
                  style="color: gray"
                  v-model="formprofil.email"
            
                />
              </div>
          


              <div
                class="input-group mb-3"
                style="display: none"
                id="verifynumber"
              >
                <input
                  type="text"
                  class="form-control"
                  :placeholder="'Code:'"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  v-model="verifyform.kod"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary"
                    style="border: none"
                    type="button"
                    @click="verify"
                  >
                    Verify
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 form-group">
                  <input
                    type="submit"
                    :value="$t('save')"
                    class="btn btn-primary"
                    style="border: none"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

    </template>
  </Navigation>

  <div
    class="modal fade"
    id="deleteConfirmModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="deleteLabel">Delete</h4>
        </div>
        <div class="modal-body">
          <p>Ovaj zadatak više se neće prikazivati označenim djelatnicima</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            onclick="$('#deleteConfirmModal').modal('hide')"
            class="btn btn-success"
            data-dismiss="modal"
          >
            Zatvori
          </button>
          <button type="button" @click="izbrisiPost" class="btn btn-danger">
            Potvrdi
          </button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import axios from 'axios'
import Navigation from '../components/Navigation.vue'

export default {
  name: 'Profile',
  components: {
    Navigation,
    VueTelInput,
  },

  data() {
    return {

      profil: [],
      verifyform:{
        kod:"",
      },
      formprofil: {
        ime: "",
        prezime: "",
        telefon: "",
        mailnews: false,
        email: "",

      },
      bindprops: {
        preferredCountries: ["DE", "AT", "SI", "DK", "NL", "GB", "FR", "ES", "RS"],
      },
      usrtoken:{
              token:'',
              vrsta:'guest',
              device:''
            },


    }
  },

  methods: {
    methodThatForcesUpdate() {
      this.getProfil()
    },
    async logout() {

this.usrtoken.token = localStorage.getItem('token')

if (this.usrtoken.token){
  this.usrtoken.device = Capacitor.getPlatform();
  if (this.usrtoken.device != "web"){
    this.usrtoken.device="mobile"
  }
  
  

  await axios
        .post("/api/odjava/", this.usrtoken)
        .then(response => {
          
          
        this.$root.toastnoti(this.$t('success'), this.$t('logout'), "greska")
        })
        .catch(error => {
          
          
            this.$root.toastnoti(this.$t('success'), this.$t('logout'), "greska")
        })
        axios.defaults.headers.common["Authorization"] = ""
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("userid")
        this.$store.commit('removeToken')
        this.$router.push("/");
        
}


    
},
    verify(){
          this.verifyform.telefon = this.formprofil.telefon
            axios.post('/mpguest/potvrditelefon/', this.verifyform)
                 .then((res) => {
                    
                      if(res.data=='Error'){
                        this.$root.toastnoti(this.$t('krivikod'), "error")
                      }
                      else{
                        this.$root.toastnoti(this.$t('sucess'), this.$t('profilesave'), "uspjeh")
                      }

                 })
                 .catch((error) => {
                    this.$root.toastnoti(this.$t('error'), this.$t('profilerror'), "greska")
                 })

      },

    async getProfil() {
      await axios
        .get('/mpguest/profile/')
        .then(response => {
          this.profil = response.data
          
          $(".vti__input").attr("placeholder", this.profil.telefon);
          preloader.remove();


        })
        .catch(error => {

          if (error.response.status === 401) {
            axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
            this.$router.push('/bye/')
          }
        })

    },
    submitPost() {

      this.formprofil.telefon = $('.vti__input').val();
      axios.post('/mpguest/update_profil/', this.formprofil)
        .then((res) => {
          /*
          if(this.formprofil.telefon){
            this.$root.toastnoti(this.$t('success'), this.$t('verifynumber'), "uspjeh")
            $('#verifynumber').show()
          }*/
          //else{
          this.$root.toastnoti(this.$t('success'), this.$t('profilesave'), "uspjeh")
          //}


        })
        .catch((error) => {
          console.log(error)
          this.$root.toastnoti(this.$t('error'), this.$t('incorrectnumber'), "greska")
        }).finally(() => {
          //Perform action in always
        });

    },
  },
  mounted() {

    this.getProfil()
    const preloader = document.querySelector('#preloader');
    $('#email').focus({preventScroll: true,});


  },
}
</script>

<style>
.vti__dropdown-list {
  position: absolute;
  width: 300px;
}
content {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  color: #4e7ec7c9;
  position: relative;
}

content:before {
  z-index: -1;
  position: absolute;
  height: 50vh;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  background: rgba(99, 165, 219, 0.623);
}

@media (max-width: 991px) {
  .multivrsta {
    padding-bottom: 16px !important;
  }
}

#deleteConfirmModal {
  color: black;
}

.link-grey {
  color: #aaa;
}

.link-grey:hover {
  color: #00913b;
}

/*
.btn, .form-control, .custom-select {
  height: 45px; }
.btn {
  border: none;
  border-radius: 0;
  font-size: 12px;
 }
  .btn.btn-primary {
    background: #35477d;
    color: #fff;
    padding: 15px 20px; }
  .btn:hover {
    color: #fff; }
  .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
*/
a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

a,
a:hover {
  text-decoration: none !important;
}

.text-black {
  color: #000;
}



.heading {
  font-size: 2.5rem;
  font-weight: 900;
}

.form-control {
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  background: none;
}

.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.col-form-label {
  color: #000;
  font-size: 13px;
}

.custom-select:active,
.custom-select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.btn,
.form-control,
.custom-select {
  height: 45px;
}

.btn.btn-primary {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.btn:hover {
  color: #fff;
}

.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact-wrap {
  -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
}

.contact-wrap .col-form-label {
  font-size: 14px;
  color: #a3b1dbb9;
  margin: 0 0 10px 0;
  display: inline-block;
  padding: 0;
}

.contact-wrap .form,
.contact-wrap .contact-info {
  padding: 40px;
}

.contact-wrap .contact-info ul li {
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.5);
}

.contact-wrap .contact-info ul li .wrap-icon {
  font-size: 20px;
  color: #fff;
  margin-top: 5px;
}

.contact-wrap .form {
  background: #fff;
}

.contact-wrap .form h3 {
  color: #35477d;
  font-size: 20px;
  margin-bottom: 30px;
}

.contact-wrap .contact-info {
  background-color: white;
}

.contact-wrap .contact-info h3 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
}

label.error {
  font-size: 12px;
  color: red;
}

#message {
  resize: vertical;
}

#form-message-warning,
#form-message-success {
  display: none;
}

#form-message-warning {
  color: #b90b0b;
}

#form-message-success {
  color: #55a44e;
  font-size: 18px;
  font-weight: bold;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

@media (min-width: 0) {
  .g-mr-15 {
    margin-right: 0.7rem !important;
  }
}

@media (min-width: 0) {
  .g-mt-3 {
    margin-top: 0.7rem !important;
  }
}

.g-height-50 {
  height: 20px;
}

.g-width-50 {
  width: 20px !important;
}

@media (min-width: 0) {
  .g-pa-30 {
    padding: 2.1rem !important;
  }
}

.g-bg-secondary {
  background-color: #fafafa !important;
}

.u-shadow-v18 {
  box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.15);
}

.g-color-gray-dark-v4 {
  color: #777 !important;
}

.g-font-size-12 {
  font-size: 0.5rem !important;
}

.media-comment {
  margin-top: 10px;
}
</style>