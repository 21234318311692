<template><br>

    <div>
      <Navigation yvisina=-200 @reload-parent="methodThatForcesUpdate">
        <template #atag>
          <a></a>
      
        </template>
        <template #content>

          <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con  text-center wow bounceInUp" data-wow-duration="1s">
              <p class="position-relative oasisnaslov text-white" style="    font-size: 0.5rem !important;font-weight: 500 !important;">
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;border-radius: 20px;font-size: 15px"  @click="  this.$router.push('/oasis/')"> <i class="fa fa-arrow-left"></i> Back to Oasis</button>
                </p>
           
             
               <h1 class="position-relative lead oasisnaslov text-white" style="font-size: 2.5rem !important;font-weight: 700 !important;">Saltwater Oasis Wellness&Spa</h1>
               <p class="position-relative oasisnaslov text-white" style="    font-size: 1.25rem;font-weight: 700 !important;">Manicure & Pedicure<br><br>
              
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;"  @click="this.$root.scrollToSection('rez')">Your Reservations</button></p>
               


            </div>
         </div>
      </div>
        

   </section>
<br><br>


      
          <div class="darken-image  image-container">
        
       
</div>

  
      <section class="menu">
        <div class="container">
        <div class="row">
        <div class="col-md-12">
       
        </div>
        </div>
        <div class="food-menu wow fadeInUp">
        <div class="row">
        <div class="col-md-12"> 
            <div class="menu-tags" >
        
        <span id="ruke" class="tagsort-active kategorija2" @click="filterruke(0)"><img src="../assets/hand.png" style="color:gray;width:30px;padding-bottom:5px;"><br><span >Hand</span></span>
        <span id="noge" class=" kategorija2" @click="filterruke(1)"><img src="../assets/1.png" style="color:gray;width:30px;padding-bottom:5px;"><br><span >Feet</span></span>
 <hr>
        </div>
        <p class="text-center">Pick a Treatment</p>
        <div class="menu-tags hand" v-show="ruke" >
   
        
          <span id="1" class="tagsort-active kategorija" @click="filter(1)"><br><span >NAIL EXTENSION - GEL</span></span>
        <span id="2" class=" kategorija" @click="filter(2)"><br><span >NAIL REFILL</span></span>
        <span  id="3" class="kategorija" @click="filter(3)"><br><span>PERMANENT NAIL POLISH</span></span>
        <span  id="4" class="kategorija"  @click="filter(4)"><br><span>REMOVAL OF GEL/PERMANENT NAIL POLISH </span></span>
        <span id="5"  class="kategorija"  @click="filter(5)"><br><span >CLASSIC MANICURE WITH NAIL POLISH </span></span>


        </div>
        <div class="menu-tags feet" v-show="!ruke">
       
        
        <span id="6" class="tagsort-active kategorija" @click="filter(6)"><br><span >NAIL CARE - POLISH</span></span>
        <span id="7" class=" kategorija" @click="filter(7)"><br><span >NAIL CARE - PERMANENT NAIL POLISH</span></span>
        <span  id="8" class="kategorija" @click="filter(8)"><br><span>CLASSIC PEDICURE - POLISH</span></span>
        <span  id="9" class="kategorija"  @click="filter(9)"><br><span>CLASSIC PEDICURE - PERMANENT POLISH</span></span>
        <span id="10"  class="kategorija"  @click="filter(10)"><br><span >CLASSIC PEDICURE - GEL</span></span>
        <span id="11"  class="kategorija"  @click="filter(11)"><br><span >MEDICAL PEDICURE</span></span>
        <span id="12"  class="kategorija"  @click="filter(12)"><br><span >MEDICAL PEDICURE - PERMANENT NAIL POLISH</span></span>
        <span id="13"  class="kategorija"  @click="filter(13)"><br><span >MEDICAL PEDICURE - GEL</span></span>

        </div>

        </div>
      
        <div style="padding: 0px 40px 0px 40px"> <hr></div>
       
    
        </div>


        

        </div>
        </div>
        </section>
      
      <div class="container">
      <form style="padding:50px;" v-on:submit.prevent="getSport">
        <input type="date" class="form-control" name="date" min="2024-04-29"  v-model="form.datum" v-on:input="getSport"><br>
    
      </form>
    
    
      <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message">{{ $t('x39') }}</div>

      
          <div class="row">
            <div  class="col-md-4 col-xl-3" v-if="nemasdostupno()">
                  <div class="card bg-c-tenis order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                              <h2 class="text-right">{{ $t('x45') }}</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
              
           
              <template v-if="[4, 5].includes(form.vrsta)">
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke.pola">
                  <div class="card bg-c-tenis order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
                          
                          <span>
                       
                              <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, 30)}}h <span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>   
                              
                            
                          
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
            </template>
            <template v-if="[3, 6, 7, 8].includes(form.vrsta)">
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke.jedan">
                  <div class="card bg-c-tenis order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
         
                         
                          <span>

                              <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, 60)}}h <span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>    
                              
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
            </template>
            <template v-if="[1, 2, 9, 10, 11, 12, 13].includes(form.vrsta)">
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke.jedanipo">
                  <div class="card bg-c-tenis order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
         
                       
                          <span>

                              <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, 90)}}h <span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>    
                            
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
            </template>
              
          <section id="rez">
              <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message" v-if="prijava">{{ $t('x40') }}</div>
            </section>
              <div class="col-md-4 col-xl-3" style="padding-top:5px;" v-if="prijava && !rezervacije.length">
                  <div class="card bg-c-green order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                            <h2 class="text-right">You have no reservations</h2>
                            <p style="font-size: 13px;">Pick a treatment and select the best time!</p>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      
              <div class="col-md-4 col-xl-3"  style="padding-top:5px;" v-for="i in rezervacije">
                  <div class="card bg-c-green order-card" >
        
      
                      <div class="card-block">
                          <i class="fa fa-times-circle f-right"  @click="cancelpotvrda(i[2], i[1], i[0])"  style="cursor:pointer;color:red;"></i>
    
                       
                          <span>
                
                            <h2 class="text-right"><b> {{tretmani[i[3]]}} {{ i[5] }}</b>&nbsp; {{ new Date(i[1]).toLocaleDateString('en-UK', {day: 'numeric', month: 'numeric'}) }} &nbsp;  {{TimeString(i[0])}}h - 
                                <template v-if="[4, 5].includes(i[3])">{{TimeString2(i[0], 30)}}</template>
            <template v-if="[3, 6, 7, 8].includes(i[3])">{{TimeString2(i[0], 60)}}</template>
            <template v-if="[1, 2, 9, 10, 11, 12, 13].includes(i[3])">{{TimeString2(i[0], 90)}}</template>

                         
                            </h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      <div style="height:200px;"></div>
        </div>
      </div>
    
    
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
    
    <div class="footer-content">
      <div class="container">
        <div class="row">
    
          <div class="col-lg-12 col-md-12">
            <div class="footer-info">
              <h3>Saltwater Oasis Manicure and Pedicure</h3>
              <p>
       
            <p>At  Saltwater Oasis Wellness&Spa <br></p><br>
            <a href= "mailto: wellness@monperin.hr"> wellness@monperin.hr </a>
     
            
              </p>
            </div>
          </div>
    
    
          
    
          <div class="col-lg-4 col-md-12 footer-newsletter">
    
    
    
    
    </div>
    
    
    
    
        </div>
      </div>
    </div>
    
    
    </footer><!-- End Footer -->
    
        </template>
    
      </Navigation >
    
      <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">Reserve Treatment</h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')" ></i>


        </div>
        <div class="modal-body">
          
          
          <form>
   
          <div><p>Fee can be payed at the spot<br>You picked {{TimeString(form.terminsat)}}h - 
            <template v-if="[4, 5].includes(form.vrsta)">{{TimeString2(form.terminsat, 30)}}</template>
            <template v-if="[3, 6, 7, 8].includes(form.vrsta)">{{TimeString2(form.terminsat, 30)}}</template>
            <template v-if="[1, 2, 9, 10, 11, 12, 13].includes(form.vrsta)">{{TimeString2(form.terminsat, 30)}}</template>
            
            <br><b style="color:#0ea2bd;">{{ tretmani[form.vrsta] }}</b></p>
          </div>
     

        </form>

        </div>
        <div class="modal-footer d-flex justify-content-center">
     
           <button  type="button" @click="rezerviraj" :disabled="buttonDisabled" class="btn btn-info f-left modalbutton" style="border:none;">Submit</button>
         
        </div>
      </div>
    </div>
  </div>


      <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('cancelres') }}</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>

            </div>
            <div class="modal-body">
              <p>{{ $t('cancelres2') }}</p>
            </div>
            <div class="modal-footer d-flex justify-content-center">
       <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;background-color: #dc3545;">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="reserveModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">Reserve</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('reserveModal')" ></i>

            </div>
            <div class="modal-body">
              <p>Since you are not logged in, please fill in this form:</p>
              <input class="form-control" type="text" placeholder="Your name or camp location...">
              <input class="form-control" type="email" placeholder="Email where we will send you confirmation">
            </div>
            <div class="modal-footer d-flex justify-content-center">
           <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      

    

      </div>
        </template>
        
     
        <script>
        import axios from 'axios'
        import Navigation from '../components/Navigation.vue'
        export default {
          name: 'Info',
          components: {
            Navigation
          },
        
          data(){
            return{
                ruke:true,
              datoteke:[],
              rezervacije:[],
              id:"",
              canceldatum:"",
              cancelsat:"",
              navBarKey:0,
              buttonDisabled: false,
              prijava:false,
              email:"",

              form:{
                datum:new Date().toISOString().substr(0, 10),
                email:"",
                terminsat:"",
                vrsta:"",
                vrsta:1,
                lokacija:"oasis",
                ime:"",
              
    
              },
              cijene:{
                1:55,
                2:45,
                3:40,
                4:15,
                5:20,
                6:30,
                7:40,
                8:35,
                9:45,
                10:50,
                11:45,
                12:60,
                13:65
              },
              tretmani:{
                1:"NAIL EXTENSION - GEL",
                2:"NAIL REFILL",
                3:"PERMANENT NAIL POLISH",
                4:"REMOVAL OF GEL/PERMANENT NAIL POLISH",
                5:"CLASSIC MANICURE WITH NAIL POLISH",
                6:"NAIL CARE - POLISH",
                7:"NAIL CARE - PERMANENT NAIL POLISH",
                8:"CLASSIC PEDICURE - POLISH",
                9:"CLASSIC PEDICURE - PERMANENT POLISH",
                10:"CLASSIC PEDICURE - GEL",
                11:"MEDICAL PEDICURE",
                12:"MEDICAL PEDICURE - PERMANENT NAIL POLISH",
                13:"MEDICAL PEDICURE - GEL"
              }
            }
          },
          mounted(){
            
            window.scrollTo(0, 0)
            this.minute("pedeset")
            this.filterruke(0)
            this.platform = Capacitor.getPlatform()
            
            const preloader = document.querySelector('#preloader');
            this.prijava = this.$store.getters.isAuth;
            if(this.prijava){
              this.mojiTermini()
            }
    
            
            this.getSport()
          
        
          },
        
          methods:{

            filterruke(broj){
                if(broj==0){
                    this.ruke = true
                    $('#noge').css('opacity', '0.5').removeClass('tagsort-active');
                    $('#ruke').addClass('tagsort-active').css('opacity', '1');
                }
                else{
                    this.ruke = false
                    $('#ruke').css('opacity', '0.5').removeClass('tagsort-active');
                    $('#noge').addClass('tagsort-active').css('opacity', '1');
                }
      
            },
            async togglepolicy(tekst){
              $("#"+tekst).toggle();
            },
    
            async plati(){
            if(!document.getElementById('flexCheckDefault').checked){
              this.$root.toastnoti(this.$t('error'), "Confirm sales policy to proceed...", "greska")
              return;
            }
              $("#preloader").show();
              $('#rezervirajModal').modal('hide')
              await axios
              
              .get("placanje/stvorihash/sport/"+this.form.datum+"/"+this.form.terminsat)
              .then(response => {
                this.$root.toastnoti(this.$t('success'), "Redirecting...", "uspjeh")
                this.openPaymentForm(response.data.hash, response.data.cartid)
      
      
        
              })
              .catch(error => {
                this.$root.toastnoti(this.$t('error'), "There has been error with our Payment service, please try to contact us because app might not be working properly", "greska")
                $("#preloader").hide();
               
              })
            },

    
            methodThatForcesUpdate(key){
              
              this.mojiTermini()
              this.getSport()
        
          },
    
          async cancelpotvrda(id){
              this.id = id
    
              this.$root.otvoriform('deleteConfirmModal')
      
          },
    
    
           moguceodgoditi(datum, sat){
            const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
            const now = new Date();
            const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));
            
    
            if (diffInHrs > 2 && targetDate > now) {
              return true
            } else {
              return false
            }
    
      
          },
          async rezervirajpotvrda(sat){
              this.form.terminsat = sat
          
              this.$root.otvoriform('rezervirajModal')
      
          },
          async cancel(){
    
                var apilink = '/mpguest/odgodimanikura/'+this.id
               
    
      
              await axios
              
                .get(apilink)
                .then(response => {
                  this.$root.toastnoti(this.$t('success'), this.$t('odgodatermina'), "uspjeh")
                  this.rezervacije = this.rezervacije.filter(arr => arr[2] !== this.id);
                  $('#deleteConfirmModal').modal('hide')
        
          
                })
                .catch(error => {
                  this.$root.toastnoti(this.$t('error'), this.$t('greska'), "uspjeh")
                 
                })
      
          },
          async rezerviraj(){
            this.buttonDisabled = true
            
          
              axios.post('/mpguest/rezervirajmanikura/', this.form)
            .then((res) => {
    
              
                if(this.prijava){
                  this.rezervacije.push([this.form.terminsat, this.form.datum, res.data.id, this.form.vrsta,])
               
                  this.$root.toastnoti(this.$t('success'), this.$t('reserved'), "uspjeh")
                  this.$root.scrollToSection('rez')
                }
                this.mojiTermini()
                
                $("#termin"+this.form.terminsat).remove();
                this.buttonDisabled = false;
                this.getSport()
                    
              })
            .catch((error) => {
          
              this.buttonDisabled = false;
              if (error.response.status === 401) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
              this.$router.push('/bye/')
            }
            else{
              this.$root.toastnoti(this.$t('error'), this.$t('greska'), "error")
            }
             
              
            })
    
            $('#rezervirajModal').modal('hide')
      
          },
         
            async getSport() {
              $("#preloader").show()
              this.datoteke = {}
                  const inputDate = new Date(this.form.datum); // replace with your input date
                  const dayOfWeek = inputDate.getDay();
                  if(dayOfWeek !== 1  && dayOfWeek !== 4){
                    const targetDate = new Date('2024-06-19');
                    const targetDate2 = new Date('2024-07-20');
                    console.log()
                  if (inputDate.toDateString() == targetDate.toDateString() || inputDate.toDateString() == targetDate2.toDateString()) {
                  
                    }
                    else{
                      this.$root.toastnoti("Info", "Manicure and Pedicure works only on mondays and thursdays", "info")
                    $("#preloader").hide()
                    return false
                    }

                    
                  }
                  

// Check if it's Monday (dayOfWeek === 1)

        const now = new Date();
    
        const hasAlreadyPassed = inputDate < now &&
                                (inputDate.getFullYear() < now.getFullYear() ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));
    
    
              if (hasAlreadyPassed) {
              this.$root.toastnoti(this.$t('error'), this.$t('prosaodatum'), "error")
              $("#preloader").hide();
              return false;
            }
            if (!this.form.datum) {
              this.$root.toastnoti(this.$t('error'), this.$t('unesitedatum'), "error")
              return false;
            }
              //$("#preloader").show();
        
              await axios
                .get('/mpguest/manikuratermini/'+this.form.datum)
                .then(response => {
                  this.datoteke = response.data
                  console.log(this.datoteke)
                  $("#preloader").hide();
        
          
                })
                .catch(error => {
                  this.$root.toastnoti(this.$t('error'), this.$t('greska'), "greska")
                  $("#preloader").hide();
                
                 
                })
        
            },
            async mojiTermini() {
              await axios
                .get('/mpguest/mojiterminimanikura')
                .then(response => {
                  this.rezervacije = response.data.termini
                  console.log(this.rezervacije)
                  $("#preloader").hide();
        
          
                })
                .catch(error => {
                  //this.$root.toastnoti('Log in', "You are not logged in, but you can still", "greska")
                  $("#preloader").hide();
                 
                })
        
            },
            filter(vrsta){
  
        $('.kategorija').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
        
        console.log("check")

        this.form.vrsta = vrsta


    },
    minute(vrsta){
        $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
  if(vrsta=="trideset"){
    this.form.trideset = true
  }
  else{
    this.form.trideset=false
  }

  $('.'+vrsta ).show()


},
vrstaime(vrsta){
        switch (vrsta) {  

      case 0 : return 'Back, neck, head and shoulders';
      case 1 : return 'Foot Reflexolog';
      case 2: return 'Coconut Massage';
      case 3: return 'Aroma massage';
      case 5: return 'Thai oil Massage';
      case 4: return 'Thai traditional massage';
      case 6: return 'Coconut oil massage';
      default: return 'Foot Reflexolog';
    }
    





},
nemasdostupno(){
  if ([4, 5].includes(this.form.vrsta)){
    if(!this.datoteke.pola || this.datoteke.pola.length === 0){
      return true
    }
  }
  if ([3, 6, 7, 8].includes(this.form.vrsta)){
    if(!this.datoteke.jedan || this.datoteke.jedan.length === 0){
      return true
    }
  }
  if ([1, 2, 9, 10, 11, 12, 13].includes(this.form.vrsta)){
    if(!this.datoteke.jedanipo || this.datoteke.jedan.jedanipo === 0){
      return true
    }
  }

 
},
TimeString(time){

var time = new Date('2023-01-01T' + time);
var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
},
TimeString2(time, broj){

var time = new Date('2023-01-01T' + time);
time.setMinutes(time.getMinutes() + broj);

var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
}
          }
        }
        </script>
      
      
      <style>
  
  .image-container img {

    display: block;
    width: 100%;
    min-height: 300px;
    max-height: 5%;
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
  
  }
.darken-image {
  
    position: relative;
    display: inline-block;
  }

  .darken-image img {
    display: block;
  }
  .darken-image .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
      
      .btn-getstarted {
          font-size: 16px;
          color: var(--color-white);
          background: var(--color-primary);
          padding: 8px 23px;
          border-radius: 4px;
          transition: 0.3s;
          font-family: var(--font-secondary);
          border:none;
    
      
      
      }
    
      
      .order-card {
          color: #fff;
      }
      
      .bg-c-blue {
          background: linear-gradient(45deg,#4099ff,#73b4ff);
      }
      
      .bg-c-green {
      
          background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
          
      }
      
      .bg-c-yellow {
          background: linear-gradient(45deg,#FFB64D,#ffcb80);
      }
      
      .bg-c-pink {
          background: linear-gradient(45deg,#FF5370,#ff869a);
      }
      
      
      .card {
          border-radius: 5px;
          -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          border: none;
          margin-bottom: 30px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
      }
      
      .card .card-block {
          padding: 25px;
      }
      
      .order-card i {
          font-size: 26px;
      }
      
      .f-left {
          float: left;
      }
      
      .f-right {
          float: right;
      }
      
      
      .bg-c-tenis {
          background: linear-gradient(45deg,#bed6db,#44a3b6);
      }
      </style>
      
      <style scoped>
        
      .hero-animated {
          padding: 50px 0 0px; 
          min-height: 30vh;
      }
      
      section {
          padding: 0px 0; 
      
      }
    
      .hero-animated p {
        color: rgba(var(--color-secondary-rgb),.8);
        margin: 0 0 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    
    @media (max-width: 640px){
      .hero-animated p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    }
    .menu-tags span{
      display: inline-block;
    }
    
      
      </style>
      
    <style scoped>
    .btn-outline-light:hover {

    background-color: transparent;
    border-color: #f8f9fa;
}

    .oasisnaslov{
      font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
    }
    
    .sub-banner-sec {
  background: url(../assets/nail.jpg) no-repeat center;
  
  background-size: cover;
  height: 561px;


}

    .hero-animated{
      min-height: 15vh;
      padding: 0px
    }
    </style>