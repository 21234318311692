<template>


<Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
<template #atag><a></a></template>
<template #content>

<section id="hero-animated" class="hero-animated d-flex align-items-center">
    <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">


      <h2><span>Survey</span></h2>
      <p>We care about what you think</p>
   
    </div>
    

</section>
<section id="featured-services" class="featured-services">
      <div class="container" style="padding:30px;">
        <template v-for="i in ankete.pitanja">
            <label>{{i.pitanjetekst}}</label>
            <input type="text" class="form-control" placeholder="..." v-if="i.opis">
            <template v-if="i.ocjena">
<br>
            <img src="../assets/1ocjena.svg" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;">
            <img src="../assets/2ocjena.svg" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;">
            <img src="../assets/3ocjena.svg" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;">
            <img src="../assets/4ocjena.svg" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;">
            <img src="../assets/5ocjena.svg" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;">

            </template>
            
        </template>

    


    </div></section>

</template>
</Navigation>



</template>


<script>

import axios from 'axios'
import Navigation from '../components/Navigation.vue'
export default {

  name: 'Info',
  components: {
    Navigation
  },

  data(){
    return{
      ankete:[],
    }
  },

  mounted(){
    $('#preloader').remove();
  this.getAnkete()
  const preloader = document.querySelector('#preloader');
  },


  methods:{
    methodThatForcesUpdate(){
      this.getAnkete()
  },
    async getAnkete() {

      await axios
        .get('/anketa/pitanja/mh/')
        .then(response => {
          this.ankete = response.data
          console.log(this.ankete)
          
        })
        .catch(error => {
          this.$root.toastnoti(this.$t('error'), error, "greska")
         
        })

    },
  }
}
</script>

<style scoped>

.hero-animated {
    padding: 50px 0 0px; 
    min-height: 30vh;
}

section {
    padding: 0px 0; 

}

</style>
