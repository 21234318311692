<template>
<div>
<header id="header" class="header fixed-top" data-scrollto-offset="0">
    <div class="container-fluid d-flex align-items-center justify-content-between">

      <a @click="$router.push('/')" class="logo d-flex align-items-center scrollto me-auto me-lg-0">
        <!-- Uncomment the line below if you also wish to use an image logo -->
        <img src="../assets/logo.png" alt="">
     
        <h1>Guest.<span></span></h1>
      </a>

      <nav id="navbar" class="navbar">
        <ul id="navbarurl">

          
          <li @click="promijeniprozor('/')"><a class="nav-link scrollto" >{{ $t('home') }} <i class="fa fa-home navikona"></i></a></li>
          <li v-if="prijava" @click="promijeniprozor('/profile/')"><a class="nav-link scrollto">{{ $t('Account') }} <i class="fa fa-user navikona"></i></a></li>


          <li v-if="!prijava"  @click="promijeniprozor('/start/')"><a class="nav-link scrollto">{{ $t('login') }} <i class="fa fa-user navikona"></i></a></li>
          <li class="dropdown" v-show="true" ><a href="/restaurants/main"><span>{{ $t('x33') }} <i class="fa fa-pizza-slice" style="color:orange"></i></span><i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul>
              
            <li @click="promijeniprozor('/restaurants/breakfast/')" v-show="false"><a class="nav-link scrollto">{{ $t('x32') }} <i class="fa fa-bacon navikona"></i></a></li>
            <li @click="promijeniprozor('/restaurants/main/')"><a class="nav-link scrollto">{{ $t('x34') }} <i class="fa fa-utensils navikona"></i></a></li>
        
            <li @click="promijeniprozor('/delivery/')"><a class="nav-link scrollto">{{ $t('x35') }} <i class="fa fa-box navikona"></i></a></li>   
            
      
            </ul>
          </li>
        
          
          <li  class="dropdown"><a href="/info/" ><span>Info</span><i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul >
              
            <li @click="promijeniprozor('/info/')"><a class="nav-link scrollto">{{ $t('Booklet') }} <i class="fa fa-circle-info navikona"></i></a></li>
            <li v-if="prijava" @click="promijeniprozor('/obavijest/')"><a class="nav-link scrollto"> News <i class="fa fa-newspaper-o navikona"></i></a></li>
            <li @click="promijeniprozor('/experiences/')"><a class="nav-link scrollto">{{ $t('explore') }} <i class="fa fa-binoculars navikona"></i></a></li>
            <li @click="promijeniprozor('/souvenirs/')"><a class="nav-link scrollto" >{{ $t('Souvenirs') }} <i class="fa fa-puzzle-piece navikona"></i></a></li>
            <li v-if="prijava" @click="promijeniprozor('/ankete/')"><a class="nav-link scrollto" >{{ $t('surveynaslov') }} <i class="fa fa-circle-question navikona"></i></a></li>
            </ul>
          </li>
          

        
          
        

          


          
          
          <li class="dropdown" v-show="prijava"><a href="/sport/tenis"><span>Sport</span><i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul>
              
            <li @click="promijeniprozor('/sport/tenis/')"><a class="nav-link scrollto">{{ $t('x36') }} <i class="fa fa-futbol navikona"></i></a></li>
            <!--<li @click="promijeniprozor('/sport/multi/')"><a class="nav-link scrollto">{{ $t('x37') }} <i class="fa fa-futbol navikona"></i></a></li>-->
            
              
      
            </ul>
          </li>
       
          
         

        
          

          <li  @click="promijeniprozor('/oasis/')"><a class="nav-link scrollto">Saltwater Oasis Spa<i class="fa fa-spa navikona"> </i></a></li>
          <li  @click="promijeniprozor('/massage/sp/')" v-show="false"><a class="nav-link scrollto">Tent Massage <i class="fa-solid fa-tent navikona"></i></a></li>    
       <!--
       
          <li class="dropdown" v-show="prijava"><a href="/massage/sp"><span>{{ $t('x38') }}</span><i class="bi bi-chevron-down dropdown-indicator"></i></a>

            <ul>
              
            <li  @click="promijeniprozor('/massage/paleo/')"><a class="nav-link scrollto">Paleo Park <i class="fa fa-spa navikona"></i></a></li>
            <li  @click="promijeniprozor('/massage/sp/')"><a class="nav-link scrollto">San Polo <i class="fa fa-spa navikona"></i></a></li>       
            
              
      
            </ul>
          </li>
        -->
          
      
          <!--<li><a class="nav-link scrollto"  style="color:gray;opacity: 0.5;" disabled>Deliveries coming soon</a></li>-->
            
          
                   
          <li v-if="prijava" @click="promijeniprozor('/report/')"><a class="nav-link scrollto">{{ $t('Report') }} <i class="fa-solid fa-house-circle-exclamation navikona"></i></a></li>
         





        </ul>
        <i class="bi bi-list mobile-nav-toggle d-none" id="tricrte"></i>
      </nav><!-- .navbar -->

      <slot name="atag"></slot>

    </div>
  </header><!-- End Header -->

  <slot name="content">

</slot>

      <div class="fabs">
          <div class="chat">
            <div class="chat_header">
                <div class="chat_option">
                    <div class="header_img" style="background-color: white;">
                        <img src="../assets/logo.png" />
                    </div>
                    <span id="chat_head">{{ $t('x46') }}</span> <br> <span class="agent"></span> <span class="online"></span>
                    <span id="chat_fullscreen_loader" class="chat_fullscreen_loader"><img
                            class="rounded-circle rounded-circle-white-bg" alt="Circular Image"
                            src="../assets/logo.png" /></span>


                </div>

            </div>
          
         
      
         
            <div id="chat_fullscreen" class="chat_conversion chat_converse">
              <div class="chat_body chat_login">
<br>
<p>{{ $t('x14') }}</p>



</div>


                <template v-for="i in poruke.poruke" >
                 


                  <span class="chat_msg_item chat_msg_item_admin" v-if="i[1]=='staff' && !areStringsEqualWithoutSpaces(i[0], 'Dear guest, please rate your expirience with food delivery via link below. Thank you for your feedback. 	 	 https://shorturl.at/qsCIS')">
                    <div class="chat_avatar">
                   
                    </div>{{ i[0] }}
                </span>




                
                <span class="chat_msg_item chat_msg_item_user" v-if=" i[1] =='guest'  && !areStringsEqualWithoutSpaces(i[0], 'Dear guest, please rate your expirience with food delivery via link below. Thank you for your feedback. 	 	 https://shorturl.at/qsCIS')">
                  {{i[0]}}</span>
                
                  <span v-if="feedbacktrue(i[0])"></span>
             
                   
               
 
                </template>

                

                <div class="chat_body chat_login">

<div class="chat_category" v-if="this.feedback == true">

  <a  class="text-center"><i class="fa fa-utensils " style="font-size: 20px;"></i></a>
        <p>Rate your delivery.<br> Any specific information you would like to share with us? </p>
       
               <div class="stars" style="font-size: 35px;">
               <i class="fa-solid fa-star notactive-star" id="1" @click="ratestar(1, 0)"></i>
               <i class="fa-solid fa-star notactive-star" id="2" @click="ratestar(2, 0)"></i>
               <i class="fa-solid fa-star notactive-star" id="3" @click="ratestar(3, 0)"></i>
               <i class="fa-solid fa-star notactive-star" id="4" @click="ratestar(4, 0)"></i>
               <i class="fa-solid fa-star notactive-star" id="5" @click="ratestar(5, 0)"></i><br>
             </div><br>
             <p>Tell us more through short survey </p>
             <a href="https://survey.hospitalityinsights.eu/-/single/LxUv6mgFNHsf5ALNPziNbfbJdHxeM99?st=noBMNGQKiPspBQQlileL5O3Bt6sAripoWr5kmEAasSaGzBDXXGC0gVRsrb44jjO1 "><button class="btn btn-info" style="color:white;">Open survey</button></a>
       
       
               </div>

</div>



               
            </div>
            <br>
            <div class="fab_field" style="padding-top: 20px;">
 
                <a id="fab_send" class="fab" @click="posaljiporuku"><i class="fa fa-send"></i></a>
                <textarea id="chatSend" @focus="chatfocus" name="chat_message" placeholder="Send a message" v-model="form.poruka"
                    class="chat_field chat_message"></textarea>
            </div>
        </div>
    
        <a id="prime gumbchat" @click="this.openwhatsap()" class="fab"  :style="{ background: '#25d366' }" >
          <i class="prime fa fa-whatsapp " style="font-size: 25px;"></i>
        </a>

    </div>
   
   
      


</div>
<div id="preloader">

  <img src="../assets/loading3.gif" id="loadingimage">
</div>
</template>



<script>
 import axios from 'axios'
import { IonContent, IonHeader, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonPage } from '@ionic/vue';
export default{
  props: ['yvisina', 'keyProp'],
   data(){
    return {
      username:"",
      prijava:false,
      feedback:false,
      videno:false,
      ocjena:0,
      poruke:[],
      form:{
        poruka:""
      },
      initialColor:"#42a5f5"

    }
  },
  components: { IonContent, IonHeader, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonPage },
  computed: {
    dynamicColor() {
      console.log("dynamic")
      console.log(this.initialColor)
      return this.initialColor; // You can perform any calculations or logic here to determine the dynamic color
    },
    },


  
methods:{
  openwhatsap(){
window.location.href='https://wa.me/+385996095649 '
  },

promijeniprozor(url){

  document.querySelector('body').classList.toggle('mobile-nav-active');
  let navbarToggle = document.querySelector('.mobile-nav-toggle');
  navbarToggle.classList.toggle('bi-list');
  navbarToggle.classList.toggle('bi-x');
  this.$router.push(url)

},

   areStringsEqualWithoutSpaces(str1, str2) {
  // Remove all spaces from both strings and then compare them
  const strippedStr1 = str1.replace(/\s+/g, '');
  const strippedStr2 = str2.replace(/\s+/g, '');

  return strippedStr1 === strippedStr2;
},

  feedbacktrue(poruke){
    console.log(poruke)

    
    if(this.areStringsEqualWithoutSpaces(poruke, 'Dear guest, please rate your expirience with food delivery via link below. Thank you for your feedback. 	 	 https://shorturl.at/qsCIS')){

      this.feedback=true
      this.ratestar(this.ocjena, 1)
    }
    return false
  },

  ratestar(broj, status){
    if(broj==0){

      return;
    }
 
    $(".stars i").each(function(index, element) {
     
  
      if(index+1 <=broj){
        $(element).removeClass("notactive-star")
        $(element).addClass("active-star")

      }
      else{
        $(element).addClass("notactive-star")
        $(element).removeClass("active-star")
      }

     


    });
    if(status==0){
      axios.get('/mpguest/ocijeni/'+broj)
        .then((res) => {

          localStorage.setItem("ocjena", broj)
          this.$root.toastnoti("Success", "Thank you for your feedback", "uspjeh")
          
          

        })
        .catch((error) => {
          this.$root.toastnoti(this.$t('error'), "Error, please try again later", "greska")
        })
    }
   
  },
  getStatusStyle() {
    console.log(this.videno)
    if(!this.videno){
      return {
        background:'#42a5f5'
      };

    }
    else{
      return {
        background:'#FF3F34'
      };
    }
      
    
    },

  posaljiporuku() {
    if (this.form.poruka==""){
      this.$root.toastnoti(this.$t('error'), "Your message is empty", "greska")
      return;
    }
     
      axios.post('/chat/kreirajporuku/', this.form)
        .then((res) => {
        
        
          this.poruke.poruke.push([this.form.poruka, "guest", "now"])
          this.form.poruka = ""
          setTimeout(() => {
            this.scrollbottom()
    }, 200);
          

        })
        .catch((error) => {
          this.$root.toastnoti(this.$t('error'), "Error, please try again later", "greska")
        }).finally(() => {
          //Perform action in always
        });

    },

  calculateTimeDifference(timestamp) {
    if(timestamp=="now"){
      return "now"
    }
      const currentTime = new Date();
      const messageTime = new Date(timestamp);
      const timeDiff = currentTime.getTime() - messageTime.getTime();
      const minutes = Math.floor(timeDiff / 1000 / 60);

      if (minutes < 60) {
        return `${minutes} min ago`;
      } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} hours and ${remainingMinutes} min ago`;
      }
    },

  chatfocus(){
    const selectHeader = document.querySelector('#header');
    selectHeader.classList.add('sticked')

  },
  toggleFab() {

            $('.prime').toggleClass('zmdi-comment-outline');
            $('.prime').toggleClass('fa-close');
            $('.prime').toggleClass('is-activechat');
            $('.prime').toggleClass('is-visible');
            $('#prime').toggleClass('is-float');
            $('.chat').toggle();

            $('.fab').toggleClass('is-visible');
            this.scrollbottom()
            localStorage.setItem('result', false);
            this.videno = false
          

        },
        scrollbottom(){ 
          var element = document.getElementById("chat_fullscreen");
          
          
setTimeout(function() {
  const height = element.scrollHeight
  element.scrollTo({
  top: height,
  behavior: 'smooth'
});
}, 350);
        

        },



    handleRefresh(event) {

      this.$emit('reload-parent');
      setTimeout(() => {
        // When the data is done refreshing, complete the refresher
        event.target.complete();
      }, 1000);
    },


  methodThatForcesUpdate(){
    window.location.reload()
  },
  async getPoruke() {
      await axios
        .get('/chat/mojeporuke/')
        .then(response => {
          this.poruke = response.data

  
          if(this.poruke.poruke[this.poruke.poruke.length-1][1]=="guest"){
            this.videno = false
            this.initialColor = "#42a5f5"
            return false;
          }
    
          const newList = [];
    
        for (let i = 0; i < this.poruke.poruke.length; i++) {
      
          const innerArray = this.poruke.poruke[i];
       
          newList.push(innerArray[3]);
          
        }
  
        let videne;
        try {
          videne = JSON.parse(localStorage.getItem('poruke'));
          if (!Array.isArray(videne)) {
            videne = [];
          }
        } catch (error) {
          videne = [];
        }
        console.log(videne[videne.length-1])
        console.log(newList[newList.length-1])
        if(videne[videne.length-1] == newList[newList.length-1]){
          if(localStorage.getItem('result') == "true"){
      
            this.videno = true
            console.log("red12")
            this.initialColor = "#42a5f5"
         
            
            return;
          }
     
          this.videno = false
          console.log("red211")
          this.initialColor = "#42a5f5"
          localStorage.setItem('result', false);
        }
        else{
          console.log("red1")
         
          this.videno = true
          this.initialColor = "#FF3F34"
          localStorage.setItem('result', true);
          localStorage.setItem('poruke', JSON.stringify(newList));
        }
        console.log(newList)

     
      

        })
        .catch(error => {
          console.log(error)

        })

    },

},
 mounted(){

  this.ocjena = localStorage.getItem("ocjena")
  console.log("ocjena je " + this.ocjena)
  

 
  console.log(localStorage.getItem('result'))
  this.videno = localStorage.getItem('result');
 
  
  this.prijava = this.$store.getters.isAuth;

  var lang = localStorage.getItem('lang')

if(lang!=null){

   this.$i18n.locale = lang;
} 
else{

this.$i18n.locale = "en"

}

this.jezik = lang


   
     const selectHeader = document.querySelector('#header');
     window.scrollY >= this.yvisina ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
  if (selectHeader) {
    document.addEventListener('scroll', () => {
      window.scrollY > this.yvisina ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
    });
  }
  let navbarlinks = document.querySelectorAll('#navbar .scrollto');
  function navbarlinksActive() {
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return;
      let section = document.querySelector(navbarlink.hash);
      if (!section) return;
      let position = window.scrollY;
      if (navbarlink.hash != '#header') position += 200;
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active');
      } else {
        navbarlink.classList.remove('active');
      }
    })
  }
  window.addEventListener('load', navbarlinksActive);
  document.addEventListener('scroll', navbarlinksActive);
  /**
   * Function to scroll to an element with top ofset
   */
  function scrollto(el) {
    const selectHeader = document.querySelector('#header');
    let offset = 0;
    if (selectHeader.classList.contains('sticked')) {
      offset = document.querySelector('#header.sticked').offsetHeight;
    } else if (selectHeader.hasAttribute('data-scrollto-offset')) {
      offset = selectHeader.offsetHeight - parseInt(selectHeader.getAttribute('data-scrollto-offset'));
    }
    window.scrollTo({
      top: document.querySelector(el).offsetTop - offset,
      behavior: 'smooth'
    });
  }
  /**
   * Fires the scrollto function on click to links .scrollto
   */
  let selectScrollto = document.querySelectorAll('.scrollto');
  selectScrollto.forEach(el => el.addEventListener('click', function(event) {
    if (document.querySelector(this.hash)) {
      event.preventDefault();
      let mobileNavActive = document.querySelector('.mobile-nav-active');
      if (mobileNavActive) {
        mobileNavActive.classList.remove('mobile-nav-active');
        let navbarToggle = document.querySelector('.mobile-nav-toggle');
        navbarToggle.classList.toggle('bi-list');
        navbarToggle.classList.toggle('bi-x');
      }
      scrollto(this.hash);
    }
  }));
  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (document.querySelector(window.location.hash)) {
        scrollto(window.location.hash);
      }
    }
  });
  /**
   * Mobile nav toggle
   */
  const mobileNavToogle = document.querySelector('.mobile-nav-toggle');
  if (mobileNavToogle) {
    mobileNavToogle.addEventListener('click', function(event) {
      event.preventDefault();
      document.querySelector('body').classList.toggle('mobile-nav-active');
      this.classList.toggle('bi-list');
      this.classList.toggle('bi-x');
    });
  }
  /**
   * Toggle mobile nav dropdowns
   */
  const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');
  navDropdowns.forEach(el => {
    el.addEventListener('click', function(event) {
      if (document.querySelector('.mobile-nav-active')) {
        event.preventDefault();
        this.classList.toggle('active');
        this.nextElementSibling.classList.toggle('dropdown-active');
        let dropDownIndicator = this.querySelector('.dropdown-indicator');
        dropDownIndicator.classList.toggle('bi-chevron-up');
        dropDownIndicator.classList.toggle('bi-chevron-down');
      }
    })
  });
  },
 
 
 }

</script>

  
  
<style>

.navikona{
font-size: 15px !important;
padding-left: 5px;
}

ion-content {
  --keyboard-offset: 0px;
}



.fa-star{
  color: lightgray;
  padding-left: 5px;
  font-size: 27px;

}

.active-star {
  color: #ffb851;
  transform: scale(1.2);
  
}

.notactive-star {
  color: lightgray;
  transform: scale(1);
  
}


.prime:hover{
  color:white !important;
}
#chatSend{
    font-family: 'Poppins', sans-serif;
}


.fabs {
    bottom: 0px;
    position: fixed;
    margin: 1em;
    right: 0;
    z-index: 998;

}

.fab {
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    color: #f0f0f0;
    margin: 25px auto 0;
   
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
    cursor: pointer;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
    position: relative;
    z-index: 998;

    background: #42a5f5;
}





.fab>i {
    font-size: 2em;
    line-height: 55px;
    -webkit-transition: all .2s ease-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.fab:not(:last-child) {
    width: 0;
    height: 0;
    margin: 20px auto 0;
    opacity: 0;
    visibility: hidden;
    line-height: 40px;
}

.fab:not(:last-child)>i {
    font-size: 1.4em;
    line-height: 40px;
}

.fab:not(:last-child).is-visible {
    width: 40px;
    height: 40px;
    margin: 15px auto 10;
    opacity: 1;
    visibility: visible;
}

.fab:nth-last-child(1) {
    -webkit-transition-delay: 25ms;
    transition-delay: 25ms;
}

.fab:not(:last-child):nth-last-child(2) {
    -webkit-transition-delay: 20ms;
    transition-delay: 20ms;
}

.fab:not(:last-child):nth-last-child(3) {
    -webkit-transition-delay: 40ms;
    transition-delay: 40ms;
}

.fab:not(:last-child):nth-last-child(4) {
    -webkit-transition-delay: 60ms;
    transition-delay: 60ms;
}

.fab:not(:last-child):nth-last-child(5) {
    -webkit-transition-delay: 80ms;
    transition-delay: 80ms;
}

.fab(:last-child):active,
.fab(:last-child):focus,
.fab(:last-child):hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
} 

/*Chatbox*/

.chat {
 
    margin-left:20%;
    position: fixed;
    right: 85px;
    bottom: 20px;
    width: 400px;
    font-size: 12px;
    line-height: 22px;
    font-family: 'Roboto';
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    opacity: 1;
    box-shadow: 1px 1px 100px 2px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
    -webkit-transition: all .2s ease-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.chat_fullscreen {
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
}

.chat_header {
    /* margin: 10px; */
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 500;
    color: #f3f3f3;
    height: 55px;
    background: #42a5f5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 8px;
}

.chat_header2 {
    /* margin: 10px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.chat_header .span {
    float: right;
}

.chat_fullscreen_loader {
    display: none;
    float: right;
    cursor: pointer;
    /* margin: 10px; */
    font-size: 20px;
    opacity: 0.5;
    /* padding: 20px; */
    margin: -10px 10px;
}

.chat.is-visible {
    opacity: 1;
    -webkit-animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
    animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
}

.is-hide {
    opacity: 0
}

.chat_option {
    float: left;
    font-size: 15px;
    list-style: none;
    position: relative;
    height: 100%;
    width: 100%;
    text-align: relative;
    margin-right: 10px;
    letter-spacing: 0.5px;
    font-weight: 400
}


.chat_option img {
    border-radius: 50%;
    width: 55px;
    float: left;
    margin: -30px 20px 10px 20px;
    border: 4px solid rgba(25, 128, 212, 0.21);
    background-color: white;
}

.change_img img {
    width: 35px;
    margin: 0px 20px 0px 20px;
}

.chat_option .agent {
    font-size: 12px;
    font-weight: 300;
}

.chat_option .online {
    opacity: 0.4;
    font-size: 11px;
    font-weight: 300;
}

.chat_color {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 10px;
    float: left;
}


.chat_body {
    background: #fff;
    width: 100%;

    display: inline-block;
    text-align: center;
    overflow-y: auto;

}

#chat_body {
    height: 450px;
}

.chat_login p,
.chat_body li,
p,
a {
    -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
    animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
}

.chat_body p {
    padding: 5px;
    color: #888
}

.chat_body a {
    width: 10%;
    text-align: center;
    border: none;
    box-shadow: none;
    line-height: 40px;
    font-size: 15px;
}



.chat_field {
    position: relative;
    margin: 5px 0 5px 0;
    width: 50%;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
    color: #4b4b4b;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    border: none;
    outline: none;
    display: inline-block;
}

.chat_field.chat_message {
    height: 30px;
    resize: none;
    font-size: 13px;
    font-weight: 400;
}

.chat_category {
    text-align: left;
}

.chat_category {
    margin: 20px;
    background: rgba(0, 0, 0, 0.03);
    padding: 10px;
}

.chat_category ul li {
    width: 80%;
    height: 30px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    font-size: 13px;
    cursor: pointer;
    line-height: 30px;
    color: #888;
    text-align: center;
}

.chat_category li:hover {
    background: #83c76d;
    color: #fff;
}

.chat_category li.active {
    background: #83c76d;
    color: #fff;
}

.tags {
    margin: 20px;
    bottom: 0px;
    display: block;
    width: 120%
}

.tags li {
    padding: 5px 10px;
    border-radius: 40px;
    border: 1px solid rgb(3, 117, 208);
    margin: 5px;
    display: inline-block;
    color: rgb(3, 117, 208);
    cursor: pointer;

}

.fab_field {
    width: 100%;
    display: inline-block;
    text-align: center;
    background: #fff;
    border-top: 1px solid #eee;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}

.fab_field2 {
    bottom: 0px;
    position: absolute;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    z-index: 999;
}

.fab_field a {
    display: inline-block;
    text-align: center;
}

#fab_camera {
    float: left;
    background: rgba(0, 0, 0, 0);
}

#fab_send {
    float: right;
    background: rgba(0, 0, 0, 0);
}

.fab_field .fab {
    width: 35px;
    height: 35px;
    box-shadow: none;
    margin: 5px;
}

.fab_field .fab>i {
    font-size: 1.6em;
    line-height: 35px;
    color: #bbb;
}

.fab_field .fab>i:hover {
    color: #42a5f5;
}

.chat_conversion {}

.chat_converse {
    position: relative;
    background: #fff;
    
    height: 300px;
    min-height: 0;
    font-size: 12px;
    line-height: 18px;
    overflow-y: auto;
    width: 100%;
    float: right;
    padding-bottom: 100px;
}

.chat_converse2 {
    height: 100%;
    max-height: 800px
}

.chat_list {
    opacity: 0;
    visibility: hidden;
    height: 0;
}

.chat_list .chat_list_item {
    opacity: 0;
    visibility: hidden;
}

.chat .chat_converse .chat_msg_item {
    position: relative;
    margin: 8px 0 15px 0;
    padding: 8px 10px;
    max-width: 60%;
    display: block;
    word-wrap: break-word;
    border-radius: 3px;
    -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
    animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
    clear: both;
    z-index: 999;
}
.chat{
  display: none;
}

.status {
    margin: 45px -50px 0 0;
    float: right;
    font-size: 11px;
    opacity: 0.3;
}

.status2 {
    margin: -10px 20px 0 0;
    float: right;
    display: block;
    font-size: 11px;
    opacity: 0.3;
}

.chat .chat_converse .chat_msg_item .chat_avatar {
    position: absolute;
    top: 0;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin .chat_avatar {
    left: -52px;
    background: rgba(44, 112, 168, 0.5);
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user .chat_avatar {
    right: -52px;
    background: rgba(0, 0, 0, 0.6);
}

.chat .chat_converse .chat_msg_item .chat_avatar,
.chat_avatar img {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin {
    margin-left: 60px;
    float: left;
    background: rgba(0, 0, 0, 0.03);
    color: #666;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user {
    margin-right: 20px;
    float: right;
    background: #42a5f5;
    color: #eceff1;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin:before {
    content: '';
    position: absolute;
    top: 15px;
    left: -12px;
    z-index: 998;
    border: 6px solid transparent;
    border-right-color: rgba(255, 255, 255, .4);
}

.chat_form .get-notified label {
    color: #077ad6;
    font-weight: 600;
    font-size: 11px;
}

input {
    position: relative;
    width: 90%;
    line-height: 20px;
    font-weight: 500;
    color: #4b4b4b;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    outline: none;
    background: #fff;
    display: inline-block;
    resize: none;
    padding: 5px;
    border-radius: 3px;
}

.chat_form .get-notified input {
    margin: 2px 0 0 0;
    border: 1px solid #83c76d;
}

.chat_form .get-notified i {
    background: #83c76d;
    width: 30px;
    height: 32px;
    font-size: 18px;
    color: #fff;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    margin: 2px 0 0 -30px;
    position: absolute;
    border-radius: 3px;
}

.chat_form .message_form {
    margin: 10px 0 0 0;
}

.chat_form .message_form input {
    margin: 5px 0 5px 0;
    border: 1px solid #e0e0e0;
}

.chat_form .message_form textarea {
    margin: 5px 0 5px 0;
    border: 1px solid #e0e0e0;
    position: relative;
    width: 90%;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #4b4b4b;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    outline: none;
    background: #fff;
    display: inline-block;
    resize: none;
    padding: 5px;
    border-radius: 3px;
}

.chat_form .message_form button {
    margin: 5px 0 5px 0;
    border: 1px solid #e0e0e0;
    position: relative;
    width: 95%;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    outline: none;
    background: #fff;
    display: inline-block;
    resize: none;
    padding: 5px;
    border-radius: 3px;
    background: #83c76d;
    cursor: pointer;
}

strong.chat_time {
    padding: 0 1px 1px 0;
    font-weight: 500;
    font-size: 8px;
    display: block;
}

/*Chatbox scrollbar*/

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    margin: 2px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

/*Element state*/

.is-activechat {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.is-float {
    box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
}

.is-loading {
    display: block;
    -webkit-animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
    animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
}

/*Animation*/

@-webkit-keyframes zoomIn {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0.0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0.0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0.0;
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes load {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0.0;
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

/* SMARTPHONES PORTRAIT */

@media only screen and (min-width: 300px) {
    .chat {
        width: 250px;
    }
}

/* SMARTPHONES LANDSCAPE */

@media only screen and (min-width: 480px) {
    .chat {
        width: 300px;
    }

    .chat_field {
        width: 65%;
    }
}

/* TABLETS PORTRAIT */

@media only screen and (min-width: 768px) {
    .chat {
        width: 300px;
    }

    .chat_field {
        width: 65%;
    }
}

/* TABLET LANDSCAPE / DESKTOP */

@media only screen and (min-width: 1024px) {
    .chat {
        width: 300px;
    }

    .chat_field {
        width: 65%;
    }
}

/*Color Options*/



.blue .fab {
    background: #42a5f5;
    color: #fff;
}



.blue .chat {
    background: #42a5f5;
    color: #999;
}


/* Ripple */

.ink {
    display: block;
    position: absolute;
    background: rgba(38, 50, 56, 0.4);
    border-radius: 100%;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    webkit-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
}

/*animation effect*/

.ink.animate {
    -webkit-animation: ripple 0.5s ease-in-out;
    animation: ripple 0.5s ease-in-out;
}

@-webkit-keyframes ripple {
    /*scale the element to 250% to safely cover the entire link and fade it out*/

    100% {
        opacity: 0;
        -moz-transform: scale(5);
        -ms-transform: scale(5);
        webkit-transform: scale(5);
        -webkit-transform: scale(5);
        transform: scale(5);
    }
}

@keyframes ripple {
    /*scale the element to 250% to safely cover the entire link and fade it out*/

    100% {
        opacity: 0;
        -moz-transform: scale(5);
        -ms-transform: scale(5);
        webkit-transform: scale(5);
        -webkit-transform: scale(5);
        transform: scale(5);
    }
}

::-webkit-input-placeholder {
    /* Chrome */
    color: #bbb;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #bbb;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #bbb;
}

:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #bbb;
}</style>


<style>
@media (min-width: 576px){
.modal-dialog {
    max-width: 1200px;

}
.modal-dialog {
    position: relative;
    width: 700px;
}}

@media only screen and (min-width : 1200px) {

.scroll-y::-webkit-scrollbar {
  width: 20px;
 }
.scroll-y::-webkit-scrollbar-track {
    background-color: transparent;
}

.scroll-y::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}
.scroll-y::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
.scroll-y::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.scroll-y::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}


}


  .sl-nav {
  display: inline;
}
.sl-nav ul {
  margin:0;
  padding:0;
  list-style: none;
  position: relative;
  display: inline-block;
}
.sl-nav li {
  cursor: pointer;
  padding-bottom:10px;
}
.sl-nav li ul {
  display: none;
}
.sl-nav li:hover ul {
  position: absolute;
  top:29px;
  right:-15px;
  display: block;
  background: #fff;
  width: 120px;
  padding-top: 0px;
  z-index: 1;
  border-radius:5px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}
.sl-nav li:hover .triangle {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index:10;
  height: 14px;
  overflow:hidden;
  width: 30px;
  background: transparent;
}
.sl-nav li:hover .triangle:after {
  content: '';
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius:2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}
.sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom:0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
}
.sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}
.sl-nav li ul li span {
  padding-left: 5px;
}
.sl-nav li ul li span:hover, .sl-nav li ul li span.active {
  color: #146c78;
}
.sl-flag {
  display: inline-block;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.4);
  width: 15px;
  height: 15px;
  background: #aaa;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}

.nav-wrapper{
  padding-right: 70px;
}
</style>

