<template>
  <Navigation yvisina=0>

    <template #atag>
      <a class="btn-getstarted scrollto" id="podsjetnik" style="color:White; cursor: pointer;" href="/start/">{{ $t('register') }}</a>
    </template>

    <template #content>

      
<section>
  <br><br>
    <div class="wrapper fadeInDown">
        <div id="formContent">
              <br>
         
          <!-- Tabs Titles -->
          <h2 class="active" style="font-size:20px; color:gray;"> {{ $t('signin') }} </h2><br>

      
          <!-- Icon -->
          <div class="fadeIn first">
            <i id='icon' class="fa fa-user fa-lg" style="color:gray; " aria-hidden="true"></i>
          </div>
      <span>
          <!-- Login Form -->
          <form @submit.prevent="submitgetToken">
          <input type="text" name="ime" placeholder="Stay number (Id)" class="fadeIn third logininput" maxlength="50" id="id_ime" autocomplete="off" v-model="username">
          <input type="password" name="password" placeholder="Location" class="fadeIn third logininput" required="" id="id_password" oninvalid="this.setCustomValidity('Unesite Lozinku')" autocomplete="off"  v-model="password">

            <div style="padding-top:15px !important;"></div>
            <input  type="submit" class="fadeIn fourth" value="Sign In" style="border-width:0px;">
            <div style="padding-top:10px !important;"></div>


         
          </form>
          </span>
      
          <!-- Remind Passowrd -->
          <div id="formFooter">
            <a class="underlineHover" href="/#cta">{{ $t('logininfo') }}</a>
          </div>
      
        </div>
      </div>
</section>


    </template>
   
</Navigation>


</template>


<script>
import axios from 'axios'
import { PushNotifications } from '@capacitor/push-notifications';
import Navigation from '../components/Navigation.vue'

export default {
  name: 'Login',
  components: {
    Navigation
  },
  data() {
        return {
            prijeusername:"",
            username: this.$route.params.username,
            device:"",
            password: this.$route.params.password,
            usrtoken:{
              token:'',
              vrsta:'guest',
              device:''
            },
            formToken:{
              fcmtoken:'',
              vrsta:'guest',
              
            },
            
       
            
  
        }
    },


  mounted(){
    $('#preloader').remove();

    //if($route.params.username){
    this.prijeusername = localStorage.getItem('username')

    const preloader = document.querySelector('#preloader');
    this.device = Capacitor.getPlatform();
    if(this.device == "web"){
        this.usrtoken.device = "web"
      }
    else{
        this.usrtoken.device = "mobile"
    }

    if(this.device!="web"){
      
      PushNotifications.addListener('registration', async (token) => {
        
        
          this.formToken.fcmtoken = token.value
          
        });
          
          PushNotifications.requestPermissions().then(result => {

            if(result.receive=="granted"){
                    PushNotifications.register()

          }
                  

        
        });

        

     
        }
    
        

  },

  methods:{
   
    
    methodThatForcesUpdate(){
    window.location.reload()
  },


      async logout() {

        this.usrtoken.token = localStorage.getItem('token')

        if (this.usrtoken.token){
          

          await axios
                .post("/api/odjava/", this.usrtoken)
                .then(response => {
      
                this.$root.toastnoti(this.$t('success'), this.$t('logout'), "greska")
                })
                .catch(error => {
                  
                  
                    this.$root.toastnoti(this.$t('success'), this.$t('logout'), "greska")
                })
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
         
                this.$store.commit('removeToken')
                
      

        }
    
       
            
        },

        submitgetToken(){
          this.submitForm()


        },


    async submitForm() {
      this.usrtoken.token = localStorage.getItem('token')

      if (this.usrtoken.token){
        

        await axios
              .post("/api/odjava/", this.usrtoken)
              .catch(error => {
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
           
                this.$store.commit('removeToken')
                axios.defaults.headers.common["Authorization"] = ""
                
                })
               
      }

            const formData = {
                username: this.username,
                password: this.password,
                fcmtoken:this.formToken.fcmtoken,
                vrsta:'guest',
            }
   
            await axios
                .post("/api/api-token-auth/", formData)
                .then(response => {
                  
                    const token = response.data.token
                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    
                    localStorage.setItem("username", this.username)
                    localStorage.setItem("token", token)
                    localStorage.setItem("mh", response.data.mh)
              
                    const toPath = this.$route.query.to || '/'
                 
                    this.$router.push(toPath)
                    this.$root.toastnoti(this.$t('success'), this.$t('loggedsucess'), "uspjeh")

                })
                .catch(error => {
           
                  
                    this.$root.toastnoti(this.$t('error'), this.$t('loggeror'), "greska")
                })
        }


  },
}
</script>

<style scoped>

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #cccccc;
}
/* STRUCTURE */
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}
#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}
#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
/* TABS */
h2.inactive {
  color: #cccccc;
}
h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}
/* FORM TYPOGRAPHY*/
input{
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--color-white);
    background: var(--color-primary);
    font-family: var(--font-secondary);
}
input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
}
input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}
.logininput {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}
input[type=text]:placeholder {
  color: #cccccc;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;
  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;
  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}
.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}
.underlineHover:hover {
  color: #0d0d0d;
}
.underlineHover:hover:after{
  width: 100%;
}
/* OTHERS */
*:focus {
    outline: none;
} 
#icon {
  width:90%;
}
* {
  box-sizing: border-box;
}
</style>