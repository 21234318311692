<template>

    <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
        <template #atag> <a class="btn-getstarted scrollto position-relative" href="/cart/" id="podsjetnik" style="color:White;background-color: #17a2b8;">
            <i class="fa fa-shopping-cart"></i>
            
    
    </a>
</template>
        <template #content> <br><br><br><br>
        <p class="text-center" style="color:gray;padding: 8px;" v-if="dostave.dostave && !dostave.dostave.length">{{ $t('emptydeliveries') }}</p>
      <div class="card" id="deliverydetail" v-for="delivery in dostave.dostave" style="margin-bottom: 20px;">
                <div class="title">{{ $t('deliverydetails') }} {{new Date(delivery.zavrijeme).toLocaleString('uk', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false})}}</div>
                <div class="title" style="color:green;" v-if="delivery.placeno">{{ $t(delivery.status) }}</div>
                <div class="title" style="color:red;" v-if="!delivery.placeno">Delivery will start when we receive payement, if you already payed, try refreshing.</div>
                <div class="info">
                    <div class="row">
                        <div class="col-7">
                            
                            <span id="heading">Type</span><br>
                            <span id="details"><span v-if="delivery.kategorija=='molpicio'">Breakfast</span> <span v-else>Lunch</span> </span>
                        </div>
                        <div class="col-5 pull-right">
                            <span id="heading">Location</span><br>
                            <span id="details"> Mol Picio -> {{delivery.gost__broj__lokacija}}</span>
                        </div>
                 
                    </div>      
                </div>      
                <div class="pricing">
                    <div class="row">
                      <template v-for="i in dostave.kosarice[delivery.id]">
                       <div class="col-9">
                            <span id="name"> <i class="fa-solid fa-circle" style="font-size:5px;color:#0dcaf0;"></i> {{i[1]}}</span>  
                        </div>
                       
                        <div class="col-3">
                            <span id="price">  ({{i[2]}}&euro; x {{i[3]}}) &euro;{{ (i[2]*i[3]).toFixed(2) }}</span>
                        </div>
                      </template>
                       
    
              
                    </div>
                </div>
                <div class="total">
                    <div class="row">
                        <div class="col-9"></div>
                        {{ dostave.kosarica }}
                        <div class="col-3"><big>&euro;{{cartTotal(dostave.kosarice[delivery.id])}}</big></div>
                    </div>
                </div>
                <div class="tracking">
                    <div class="title">{{ $t('deliveryinformation') }}</div>
                </div>
                <div class="progress-track">
                    <ul id="progressbar">
                        <li  :class="CheckStep(delivery.status, 'Preparing')" id="step1">{{ $t('Preparing') }}</li>
                        <li  :class="CheckStep(delivery.status, 'Ready')" id="step2">{{ $t('Ready') }}</li>
                        <li  :class="CheckStep(delivery.status, 'Taken')"  id="step3">{{ $t('Taken') }}</li>
                        <li  :class="CheckStep(delivery.status, 'Delivered')" id="step5">{{ $t('Delivered') }}</li>
                    </ul>
                </div>
    
                <div class="tracking">
                    <div class="title">{{ $t('extrainfo2') }}</div>
                    <p class="info2" style="color:gray;">{{delivery.opis}}</p>
                </div>

           

                
    
            </div>
    </template>

    </Navigation>
    
   
    

      </template>
    
    
    
    <script>
    import axios from 'axios'
    import Navigation from '../components/Navigation.vue'
    export default {
      name: 'Details',
      components: {
        Navigation
      },
      data(){
        return {
          form:{
            od:"",
            do:"",
            broj:""
    
          },
          dostave:[],

        }
      },
 
      methods:{
        cartTotal(kosarica) {

            if(kosarica){
                let total = 0
            for (let i = 0; i < kosarica.length; i++) {
               total += kosarica[i][2] * parseInt(kosarica[i][3])
            }
            return total.toFixed(2);
            }
            
            
  
        },
        methodThatForcesUpdate(){
            this.getDelivery();
        },
        
 
         CheckStep(status, tocka) {
          const poredak = ["Preparing", "Ready", "Taken", "Delivered"]
          if(poredak.indexOf(tocka)<=poredak.indexOf(status)){
             return 'step0 active'
          }
        },
    
       delteDelivery(){
      
                
                axios.post('/mpguest/deleteobj/', this.formdelete)
                     .then((res) => {
    
    
                          $('#deliverydetail').hide(); 
                          this.$root.toastnoti(this.$t('success'), "Delivery is deleted", "uspjeh")
                          this.delivery.total = 0
                          
                     })
                     .catch((error) => {
               
                     }).finally(() => {
           
                     });
                     
          },
      async getDelivery() {
    
          await axios   
            .get('/mpguest/narudzba/')
            .then(response => {
              this.dostave = response.data
              console.log("DOSTAVE", this.dostave)
              preloader.remove();
    
            
            })
            .catch(error => {

                if (error.response.status === 401) {
                    axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
                this.$router.push('/bye/')
            }
                
            })
    
        },
    },
    
      mounted(){
        window.scrollTo(0, 0)
        this.getDelivery();
        const preloader = document.querySelector('#preloader');
        
      },
    }
    </script>
    
    
    
    
    
      <style scoped>
      .col-3{
        width:45%;
      }
      .col-9 {
        width: 55%;
      }
     .card{
        margin: auto;
        width: 38%;
        max-width:600px;
        padding: 4vh 0;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-top: 3px solid var(--color-primary);
        border-bottom: 3px solid var(--color-primary);
        border-left: none;
        border-right: none;
    }
    @media(max-width:768px){
        .card{
            width: 90%;
        }
    }
    .title{
        color: var(--color-primary);
        font-weight: 600;
        margin-bottom: 2vh;
        padding: 0 8%;
        font-size: initial;
    }
    #details{
        font-weight: 400;
    }
    .info{
        padding: 5% 8%;
    }
    .info2{
        padding: 0% 8%;
    }
    .info .col-5{
        padding: 0;
    }
    #heading{
        color: grey;
        line-height: 6vh;
    }
    .pricing{
        background-color: #ddd3;
        padding: 2vh 8%;
        font-weight: 400;
        line-height: 2.5;
    }
    .pricing .col-3{
        padding: 0;
    }
    .total{
        padding: 2vh 8%;
        color: var(--color-primary);
        font-weight: bold;
    }
    .total .col-3{
        padding: 0;
    }
    .footer{
        padding: 0 8%;
        font-size: x-small;
        color: black;
    }
    .footer img{
        height: 5vh;
        opacity: 0.2;
    }
    .footer a{
        color: rgb(252, 103, 49);
    }
    .footer .col-10, .col-2{
        display: flex;
        padding: 3vh 0 0;
        align-items: center;
    }
    .footer .row{
        margin: 0;
    }
    #progressbar {
        margin-bottom: 3vh;
        overflow: hidden;
        color: rgb(252, 103, 49);
        padding-left: 0px;
        margin-top: 3vh
    }
    
    #progressbar li {
        list-style-type: none;
        font-size: x-small;
        width: 20%;
        float: left;
        position: relative;
        font-weight: 400;
        color: rgb(160, 159, 159);
    }
    
    #progressbar #step1:before {
        content: "";
        color: rgb(252, 103, 49);
        width: 5px;
        height: 5px;
        margin-left: 0px !important;
        /* padding-left: 11px !important */
    }
    
    #progressbar #step2:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-left: 32%;
    }
    
    #progressbar #step3:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 32% ; 
        /* padding-right: 11px !important */
    }

    #progressbar #step4:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 32% ; 
        /* padding-right: 11px !important */
    }
    
    #progressbar #step5:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 0px !important;
        /* padding-right: 11px !important */
    }
    
    #progressbar li:before {
        line-height: 29px;
        display: block;
        font-size: 12px;
        background: #ddd;
        border-radius: 50%;
        margin: auto;
        z-index: -1;
        margin-bottom: 1vh;
    }
    
    #progressbar li:after {
        content: '';
        height: 2px;
        background: #ddd;
        position: absolute;
        left: 0%;
        right: 0%;
        margin-bottom: 2vh;
        top: 1px;
        z-index: 1;
    }
    .progress-track{
        padding: 0 8%;
    }
    #progressbar li:nth-child(2):after {
        margin-right: auto;
    }
    
    #progressbar li:nth-child(1):after {
        margin: auto;
    }
    
    #progressbar li:nth-child(3):after {
        float: left;
        width: 68%;
    }
    #progressbar li:nth-child(4):after {
        margin-left: auto;
        width: 132%;
    }
    
    #progressbar  li.active{
        color: black;
    }
    
    #progressbar li.active:before,
    #progressbar li.active:after {
        background: #91d16f;
    }
    
      </style>
    
    
      <style scoped>
      
        .header .btn-getstarted,
      .header .btn-getstarted:focus {
        font-size: 16px;
        color: var(--color-white);
        background: var(--color-red);
        padding: 8px 23px;
        border-radius: 4px;
        transition: 0.3s;
        font-family: var(--font-secondary);
      }
      
      .header .btn-getstarted:hover,
      .header .btn-getstarted:focus:hover {
        color: var(--color-white);
        background: #b83341;
      }
      
    
    
      </style>