<template>


    <Navigation yvisina=-200>


    <template #atag><a @click="$router.push('/cart/')" class="btn-getstarted scrollto position-relative" id="podsjetnik" style="color:White;">
            <i class="fa fa-shopping-cart"></i>
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-dark">
            {{ cartTotalLength }}
        <span class="visually-hidden"></span>
      </span>
    
    </a>
</template>
    <template #content>

        <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con  text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative lead oasisnaslov text-white" style="font-size: 2.5rem !important;font-weight: 700 !important;">Menu</h1>
               <p class="position-relative oasisnaslov text-white" style="    font-size: 1.25rem;font-weight: 700 !important;"><br><br>
                <button type="button" class="btn btn-outline-light btn-sm"  style="border-color: white;" @click="$router.push('/cart/')"><i class="fa fa-shopping-cart"></i> My Cart</button></p>
        
    
               


            </div>
         </div>
      </div>
        

   </section>

        <div class="darken-image  image-container">
          
        
  <div class="text-overlay">



  </div>
  </div>

     
        <div class="darken-image  image-container">
        
       

</div>
        
    <br><br>
    
    <section class="menu" v-if="true">
    <div class="container">
    <div class="row">
    <div class="col-md-12">
    <div class="page-header wow fadeInDown">
    <h1 class="testh1"><small style="color:#97a4bd; font-size:70%;">{{ $t('deliveryopis') }}</small></h1>

    </div>
    </div>
    </div>
    <div class="food-menu wow fadeInUp">

        <div class="row">
    <div class="col-md-12"> 
    <div class="menu-tags">
    <span @click="this.currentFilter='starter'" :class="{'tagsort-active': currentFilter === 'starter'}">Starters</span>
<span @click="this.currentFilter='pasta'" :class="{'tagsort-active': currentFilter === 'pasta'}">Pasta</span>
<span @click="this.currentFilter='kids'" :class="{'tagsort-active': currentFilter === 'kids'}">Kids</span>
<span @click="this.currentFilter='main'" :class="{'tagsort-active': currentFilter === 'main'}">Main Courses</span>
<span @click="this.currentFilter='soup'" :class="{'tagsort-active': currentFilter === 'soup'}">Soup</span>
<span @click="this.currentFilter='salad'" :class="{'tagsort-active': currentFilter === 'salad'}">Salads</span>
<span @click="this.currentFilter='veggie'" :class="{'tagsort-active': currentFilter === 'veggie'}">Veggie</span>
<span @click="this.currentFilter='dessert'" :class="{'tagsort-active': currentFilter === 'dessert'}">Desserts</span>
<span @click="this.currentFilter='breakfast'" :class="{'tagsort-active': currentFilter === 'breakfast'}">Breakfast</span>


    </div>
    </div>
    </div>
    
    <input type="text" class="form-control" :placeholder="$t('searchjela')" id="searchjelainput">
    
    <div class="row menu-items">

      
        <template v-for="j in podvrste">
        <span v-show="j[1]=='STARTER'" :class="'testh4 podvrstanaslov ' + j[1] "  v-if="j[0]" style="color:rgb(14, 162, 189) !important; padding-top: 20px;">
            <h1 :class="'testh4 podvrstanaslov ' + j[1] "  v-if="j[0]" style="color:rgb(14, 162, 189) !important; padding-top: 20px;">{{j[0]}}</h1><div v-if="j[0]" style="padding: 0 20px 0 20px;"></div><hr>

        </span>
  
    <br>
    <template  v-for="i in jela">
      
        <div :class="'menu-item JELO col-sm-6 col-xs-12' + ' ' + i[5] + ' ' + i[4]" v-show="i[5]==currentFilter" v-if="i[7] == j[0]">
           <div class="clearfix menu-wrapper">
               <div class="menu-img rounded-circle" style="border-radius: 50%!important; padding-bottom: 3%;">
           <img class="containjelo img--coverjelo imgjelo" :src="i[0]" onerror="this.style.display='none'" >
       </div>
       <h4 class="testh4">
      
           <i class="fa fa-plus-circle" id="dodaj" style="cursor: pointer;font-size:30px;color:#0ea2bd;" @click="addToCart(i[4], i[2], i[6], i[3])"></i>&nbsp;&nbsp;
       
       {{i[4]}}
       </h4>
       <span class="price">€{{i[2]}}</span>
       <div class="dotted-bg"></div>
       </div>
       <p class="menup">{{i[1]}}</p>
       </div>
    </template>
       
</template>
    
    
    </div>
    </div>
    </div>
    </section>
    <footer id="footer" class="footer" style="padding-top:50%;">

<div class="footer-content">
  <div class="container">
    <div class="row">
      <h1>Contact area:</h1><br>
  


      <div class="col-lg-6 col-md-6">
        <div class="footer-info">
          <h3>{{ $t('x2') }}  - Borgo Obrt za ugostiteljstvo</h3>
          <p>
   
        <p>San Pol 3 52211 Bale Croatia</p><br>
        
      
            <strong>Telephone:</strong> +385994009009<br>
            <strong>Email:</strong> uomila2020@gmail.com<br>
            <strong>Oib:</strong> 14967929073<br>
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 footer-newsletter">





</div>




    </div>
  </div>
</div>

<div class="footer-legal text-center">
  <div
    class="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">





  </div>

</div>

</footer><!-- End Footer -->

    </template>
    </Navigation>
    

    </template>
    
    <script>
    import axios from 'axios'
    import Navigation from '../components/Navigation.vue'
    export default {
      name: 'Restaurant',
      components: {
        Navigation
      },
    
      data(){
        return{
            currentFilter: "starter",
            jela:[],
            jelo:{
                id:"",
                vrsta:"1",
            },
            cart: {
            items: [],
            od:12,
            do:20
            
          },
          podvrste:[],
        }
      },
    
      beforeCreate() {
        this.$store.commit('initializeStore')
        const token = this.$store.state.token
        if (token) {
            axios.defaults.headers.common['Authorization'] = "Token " + token
        } else {
            axios.defaults.headers.common['Authorization'] = ""
        }
      },
      mounted(){
        window.scrollTo(0, 0)
        this.cart = this.$store.state.cart

        
        
      
        this.searchJela();
    
    
        this.getJela()
        
        const preloader = document.querySelector('#preloader');
      },
      computed: {
          cartTotalLength() {
              let totalLength = 0
              for (let i = 0; i < this.cart.items.length; i++) {
               
                
                  totalLength += parseInt(this.cart.items[i].quantity)
              }
              console.log(totalLength)
              return totalLength;
    
          }
      },
    
    
      methods:{

        formatTime(hour) {
  if (hour === 0 || hour === 24) {
    return '12am';
  } else if (hour === 12) {
    return '12pm';
  } else if (hour < 12) {
    return hour + 'am';
  } else {
    return (hour - 12) + 'pm';
  }
},

        methodThatForcesUpdate(){
        window.location.reload()
      },
    
      addToCart(jelo, price, vrsta, id) {
            const item = {
                jelo: jelo,
                price: price,
                quantity: "1",
                vrsta:vrsta,
                id:id,
            }
           
            this.$store.commit('addToCart', item)
            this.$root.toastnoti(this.$t('success'), this.$t('Dodano'), "info")
               
        },
    
    
      searchJela(){
        $("#searchjelainput").keyup(function(){
            var inputText = $("#searchjelainput").val().toLowerCase();
            
            if ((inputText.length) > 0) {            
            
                $('.JELO').each(function() {
          
                        var $this = $(this);
                      
                    
                      if($this.attr('class').toLowerCase().match(inputText)) {
                        $this.show()
                      }
                      else{
                        $this.hide()
                      }
    
                      
                
          
            }); 
        }
        else{
            $(".JELO").show()
        }
    
      });
    
      },
    
    
         async getJela() {
    
          await axios
            .get('/mpguest/jelanovo/paleopark')
            .then(response => {
              this.jela = response.data.jela
              this.od = response.data.lunchod
              this.do = response.data.lunchdo
              var vrstejela = []
              for (let i = 0; i < this.jela.length; i++) {
                if (!vrstejela.includes(this.jela[i][7])) {
                    this.podvrste.push([this.jela[i][7], this.jela[i][5]])
                    vrstejela.push(this.jela[i][7])
                    }
            

           }
           console.log(this.podvrste)
           var temp;
           temp = this.podvrste[0]
           this.podvrste[0] = this.podvrste[this.podvrste.length-1]
           this.podvrste[this.podvrste.length-1] = temp

              console.log(this.jela)
              console.log(this.podvrste)
              preloader.remove();
              
    
            
            })
            .catch(error => {
             if (error.response.status === 401) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
              this.$router.push('/bye/')
            }
            })
    
        },
    
    
      },
    
    }
    </script>
    <style>

    #dodaj:hover {
    color: #28b9d4;
} 
    
    
    .price{
        color:#858fa1!important;
    }
    @media (max-width: 767px){
    h1, .h1 {
        font-size: 32px;
    }
    
    }
    
    .imgjelo {
      width: 100%;
      height: 300px;
    }
    
    .img--coverjelo {
      object-fit: cover;
    }
    
    .img--containjelo {
      object-fit: contain;
    }
    
    
    .JELO {
      opacity: 1;
      transition: opacity 1s; 
    }
    
    .JELO.fade {
      opacity: 0;
    }
    
    .menup {
        font-family: josefin sans,sans-serif;
        color: #828281;
        font-size: 22px;
    }
    
    
    .testh4{
        color:#848b99 !important;
        font-size:120%;
        font-family: raleway, sans-serif;
        text-transform: uppercase;
        color: #4b4741;
        text-align: left;
        margin: 5px 5px;
    
        padding-right: 10px;
        display: inline;
        background: #fff;
        font-weight: 800
    }
    
    .small, small {
        font-size: 65%;
        font-family: josefin sans,sans-serif;
        font-weight: 700;
        display: block!important;
        text-transform: none;
        text-align: center;
        margin: 10px 0 15px;
    }
    .testh1{
        font-family: raleway, sans-serif;
        text-transform: uppercase;
        color: var(--color-primary);
        text-align: center;
        font-weight: 800
    }
    
    .menu {
        padding-bottom: 30px
    }
    .space60 {
        margin-bottom: 60px
    }
    .menu-tags {
        text-align: center;
        margin-bottom: 20px
    }
    .menu-tags2, .menu-tags3, .menu-tags4 {
        text-align: center;
        margin-bottom: 50px;
        margin-top: 20px
    }
    .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
        font-family: raleway, sans-serif;
        font-size: 18px;
        color: #828281;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 20px;
        padding-bottom: 2px;
        padding-top: 5px;
        cursor: pointer;
        transition: all .5s ease;
        border-bottom: 3px solid transparent
    }
    .menu-tags .tagsort-active, .menu-tags2 .tagsort2-active, .menu-tags3 .tagsort3-active, .menu-tags4 .tagsort4-active {
        border-bottom: 3px solid #5fbae9;
        color: #4b4741;
    
    }
    .menu .food-menu .menu-item, .menu .food-menu .menu-item3 {
        overflow: hidden;
        margin: 15px 0;
        padding-left: 10px;
        border-left: 3px solid #fff
    }
    .menu .food-menu .menu-item.featured {
        border-left: 3px solid #f9c56a
    }
    .menu .food-menu .menu-item .dotted-bg, .menu .food-menu .menu-item3 .dotted-bg {
        border-top: dotted 2px #ccc;
        left: 0;
        top: 15px;
        width: 100%;
        z-index: -1
    }
    .menu .food-menu .menu-item .menu-wrapper, .menu .food-menu .menu-item3 .menu-wrapper {
        position: relative;
        z-index: 1
    }
    .menu .food-menu .menu-item h4 class="testh4", .menu .food-menu .menu-item3 h4 class="testh4" {
        text-align: left;
        margin: 5px 0;
        float: left;
        padding-right: 10px;
        display: inline;
        background: #fff
    }
    .menu .food-menu .menu-item p, .menu .food-menu .menu-item3 p {
        font-size: 18px
    }
    .menu .food-menu .menu-item .price, .menu .food-menu .menu-item3 .price {
        float: right;
        padding-left: 10px;
        line-height: 19.8px;
        margin: 5px 0;
        background: #fff none repeat scroll 0% 0%;

        font-size: 22px;
        font-weight: 700
    }
    .menu .food-menu .menu-item-active {
        border-left: 3px solid #f9c56a
    }
    .menu .food-menu .menu-btn {
        text-align: center;
        padding: 20px 0
    }
    
    .menu-more, .menu-more:hover {
        width: 55px;
        height: 55px;
        -webkit-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        -moz-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        background-color: #f9c56a;
        -webkit-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        -moz-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        text-shadow: 0 5px 5px #c1913e;
        border: solid 1px #f2ba57;
        color: #fff;
        line-height: 0;
        font-weight: 300;
        font-size: 65px;
        text-align: center;
        display: table;
        position: absolute;
        z-index: 48;
        bottom: -27.5px;
        right: 45px;
        font-family: Raleway;
        padding-top: 27px;
        text-decoration: none
    }
    .menu2-overlay {
        background: rgba(19, 19, 19, .7);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: .4s
    }
    .menu-info:hover .menu2-overlay {
        opacity: 1;
        visibility: visible;
        transition: .4s
    }
    .menu2-overlay h4 class="testh4" {
        font-size: 19px;
        color: #fff;
        margin: 46px 0 5px
    }
    .menu2-overlay p {
        color: #fff;
        font-size: 19px;
        line-height: 24px
    }
    .menu2-overlay .price {
        font-family: Josefin Sans;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        border-top: 1px solid #3e3d3b;
        width: 100%;
        display: table;
        margin: 17px 0 0;
        padding-top: 17px
    }
    .menu-item3 img {
        width: 83px;
        float: left
    }
    .menu-item3 .menu-wrapper {
        margin-left: 115px
    }
    .menu-items3 p {
        float: left
    }
    .menu-items4 {
        margin-bottom: 75px
    }
    .menu-item4 {
        margin-bottom: 30px
    }
    .menu-item4 a {
        text-decoration: none !important
    }
    .menu4-overlay {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 5px 5px #e2e2e2;
        margin-bottom: 0;
        margin-top: -10px;
        text-align: center;
        text-decoration: none !important;
        display: table
    }
    .menu4-overlay h4 class="testh4" {
        font-size: 19px;
        color: #4b4741;
        margin: 46px 0 15px
    }
    .menu4-overlay p {
        color: #4b4741;
        font-size: 18px;
        line-height: 24px
    }
    .menu4-overlay .price {
        font-family: Josefin Sans;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        color: #e7ad48;
        width: 100%;
        display: table;
        margin: 0;
        padding: 13px 0 45px
    }
    
    @media(max-width:992px) {
        .list {
            padding-left: 0;
            margin-bottom: 0
        }
        .navbar .navbar-nav>li>a {
            padding: 9px 15px
        }
        .subscribe .btn-default {
            padding: 0 15px;
            height: 46px;
            border: 1px solid #f2ba57;
            margin-left: 0 px;
            margin-top: 0
        }
        .team-staff {
            border-bottom: 1px solid #dcdddd;
            padding-bottom: 30px;
            margin-bottom: 30px
        }
        .contact-social {
            margin-bottom: 60px
        }
        .menu-item2 {
            margin-bottom: 50px;
            width: 50%
        }
        .menu .food-menu .menu-item3 h4 class="testh4" {
            font-size: 14px
        }
        .menu-item4 {
            width: 50%
        }
        .rc-info {
            margin-left: 0
        }
        .recipie-content img {
            float: none;
            width: 100%;
            margin-bottom: 25px
        }
        .recipie-content hr {
            margin: 20px 0 50px
        }
        .rc-info {
            margin-bottom: 30px
        }
    }
    @media(max-width:767px) {
        .reservation .reservation-form .reservation-btn a {
            padding: 15px 14px;
            font-size: 15px
        }
        .table-responsive {
            padding: 10px 19px
        }
        .fp-content img {
            width: 100%
        }
        .recipie-content hr {
            margin: 20px 0 50px
        }
        .rc-info {
            margin-bottom: 30px
        }
        .menu-item2 {
            width: 100%
        }
        .menu-item2 img, .menu-item4 img {
            min-width: 100%
        }
        .post-date {
            margin: 0 0 30px;
            float: none
        }
        article h4 class="testh4" {
            margin: 40px 0 10px
        }
        h1, .h1 {
            font-size: 32px
        }
        .subscribe .btn-default {
            paddwing: 0 15px;
            height: 46px;
            border: 1px solid #f2ba57;
            margin-left: 0 px;
            margin-top: 0
        }
        .subscribe input {
            width: auto !important
        }
        .trusted-quote .quote-body {
            font-size: 19px
        }
        .trusted-quote .quote-author {
            font-size: 18px
        }
        .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
            font-size: 15px;
            margin: 0 8px 4px;
            display: inline-block
        }
        .page_header h2 {
            font-size: 61px
        }
        .menu .food-menu .menu-item3 h4 class="testh4" {
            font-size: 14px
        }
        .menu-item4 {
            width: 100%
        }
    }
    @media(max-width:480px) {
        .reservation .reservation-form {
            padding: 10px
        }
        .special-slider .slider-content .btn {
            margin: 17px 5px 20px 0;
            font-weight: 700;
            font-size: 10px;
            letter-spacing: normal;
            padding: 10px 20px;
            float: left
        }
        .element-tab .nav-tabs>li>a {
            color: #4b4741;
            font-size: 12px;
            font-family: Raleway;
            padding: 19px 14px
        }
        .cart-table tbody tr td a {
            font-size: 11px
        }
        .cart-table {
            font-size: 8px
        }
        .cart-table img {
            max-width: 50px;
            height: auto
        }
        .menu .food-menu .menu-item3 .price {
            
            float: none;
            padding-left: 0;
            margin: 6px 0 11px;
            font-size: 22px;
            display: table;
            padding-top: 10px
        }
        .contact-info p {
            font-size: 15px
        }
        .menu2-overlay h4 class="testh4" {
            font-size: 17px;
            margin: 22px 0 5px
        }
        .table>tbody>tr>td {
            padding: 8px 2px
        }
        .shop-grid select {
            float: none;
            display: block;
            margin-bottom: 15px
        }
        .sg-list {
            margin-left: -12px
        }
    }
    
    .menu-info {
        position: relative;
        overflow: hidden
    }
    .menu-item4 img {
        transform: scale(1);
        transition: .4s;
        opacity: 1
    }
    .menu-item4:hover img {
        transform: scale(1.1);
        transition: .4s;
        opacity: .9
    }
    .menu4-overlay {
        position: relative;
        z-index: 444
    }
    .menu-item2 img {
        transform: scale(1);
        transition: .4s;
        opacity: 1
    }
    .menu-item2:hover img {
        transform: scale(1.1);
        transition: .4s;
        opacity: .9
    }
    
    
    </style>

<style scoped>
.btn-outline-light:hover {

background-color: transparent;
border-color: #f8f9fa;
}

.oasisnaslov{
  font-family: "Playfair Display", serif;
font-optical-sizing: auto;
font-style: normal;
}

.sub-banner-sec {
background: url(../assets/food2.jpg) no-repeat center;

background-size: cover;
height: 561px;


}

.hero-animated{
  min-height: 15vh;
  padding: 0px
}
</style>