<template>
  <br>

  <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">
    <template #atag>
      <a></a>
    </template>

    <template #content>


      <div style="padding-top: 80px;"></div>
      <div class="darken-image  image-container">
        
        <img src="../assets/sport.jpg" class="img-fluid darkenimg" style="">
<div class="text-overlay">
  <h2><span>{{ $t('sportnaslov') }}</span></h2>
</div>
</div>
        


      <section id="hero-animated" class="hero-animated d-flex align-items-center" style="min-height:15vh  !important;">
        <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
          data-aos="zoom-out">



         <!--<p v-if="this.prijava">{{ $t('sport1') }}<br>{{ $t('sport2') }}</p>

          <p v-html="$t('cijenik1')" v-if="prijava"></p>
        <p v-else style="font-weight:bold;">You are not logged in, but you can still make reservations for sport fields with online Payment<br>The price per hour is 13€</p>-->
        <p style="font-size: 16px;">{{ $t('x13') }}</p>
        <button  class="btn btn-outline-info" style="font-size: 18px;margin-bottom: 0px;border-radius: 20px;" @click="  this.$root.scrollToSection('rez')">{{ $t('myrez') }} &nbsp;<i class="fa fa-arrow-down"></i></button>
        
   

        </div>

      </section>


      <div class="container">
        <form style="padding:50px;">
          <input type="date" class="form-control" name="date" :min="new Date().toISOString().substr(0, 10)"
            v-model="reserveform.datum" v-on:input="getSport"><br>
   
        </form>

        <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message">{{ $t('availabletimes') }}</div>


        <div class="row">
          <div class="col-md-4 col-xl-3" v-if="!Object.values(this.datoteke).some(list => list.length > 0)">
            <div class="card bg-c-tenis order-card">


              <div class="card-block">
                <i class=" f-right" style="cursor:pointer;"></i>
                <span>

                  <h2 class="text-right">{{ $t('x45') }}</h2>


                </span>

              </div>
            </div>
          </div>


          <template v-for="(item, index) in datoteke">
            <div class="col-md-4 col-xl-3" style="padding:15px;" :id="'termin' + i" v-if="item.length">

              <div class="card bg-c-tenis order-card">


                <div class="card-block">
                  <i class="fa fa-arrow-right f-right" @click='rezervirajpotvrda(item, index)'
                    style="cursor:pointer;"></i>
                  <span>

                    <h2 class="text-right">
                      <span style="color:rgb(184, 255, 213);">Free</span>&nbsp;&nbsp;
                      {{ index }}-{{ parseInt(index) + 1 }}h</h2>


                  </span>

                </div>
              </div>

            </div>
          </template>


          <section id="rez">
          <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message" v-if="this.prijava">{{ $t('rezervacijesporta') }}
          </div>
        </section>
          <div class="col-md-4 col-xl-3" v-if="!rezervacije.length && prijava" style="padding-top:5px;" >
            <div class="card bg-c-green order-card">


              <div class="card-block">
                <i class=" f-right" style="cursor:pointer;"></i>
                <span>

                  <h2 class="text-right">{{ $t('nemarezerviranog') }}</h2>


                </span>

              </div>
            </div>
          </div>

          <div class="col-md-4 col-xl-3" v-for="i in rezervacije" :id="'rez' + i[2]" style="padding-top:5px;" v-if="prijava">
            <div class="card bg-c-green order-card">


              <div class="card-block">
                <i class="fa fa-times-circle f-right"
                  @click="cancelpotvrda(i[2], i[1], i[0], i[3])" style="cursor:pointer;color:rgb(197, 135, 135);font-size:35px;padding-right: 0px;padding-left: 20px;"></i>
                  
                  <i class="fa fa-barcode f-right" style="font-size:50px;color: white" @click="otvoribarkodform(i[4])"> </i>
                <span>

                  <h2 class="text-right"><img :src="require(`@/assets/${i[3]}.svg`)"
                      style="widht:35px;height:35px;padding-right:10px;"> {{ new Date(i[1]).toLocaleDateString('en-UK',
                        { day: 'numeric', month: 'short' }) }}<br> {{ i[0] }}h-{{ parseInt(i[0]) + 1 }}h</h2>


                </span>

              </div>
            </div>
          </div>
        
          <div style="height:200px;"></div>
        </div>
      </div>


<!-- ======= Footer ======= -->
<footer id="footer" class="footer">

<div class="footer-content">
  <div class="container">
    <div class="row">

      <div class="col-lg-12 col-md-12">
        <div class="footer-info">
          <h3>Mon Perin</h3>
          <p>
   
        <p>Mon Perin d.d. Trg La Musa 2 HR-52211 Bale, Croatia</p><br>
        <p>Oib: 06374155285</p><br>
        
      
            <strong>Reservations & Info:</strong> +385 52 824 338<br>
            <strong>Reception:</strong> +385 52 824 338<br>
            <strong>Email:</strong> info@monperin.hr<br>
          </p>
        </div>
      </div>


      

      <div class="col-lg-4 col-md-12 footer-newsletter">




</div>




    </div>
  </div>
</div>


</footer><!-- End Footer -->


    </template>


  </Navigation>

  <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel"
    aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('cancelres') }}</h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>
        </div>
        <div class="modal-body">
          <p>{{ $t('cancelres2') }}<br></p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
        <button type="button" @click="cancel" class="btn btn-danger f-left modalbutton" 
            style="border:none;background-color: #dc3545;">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="barkodform" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('x41') }}</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('barkodform')" ></i>
          </div>
          <div class="modal-body">
         
            <svg id="barkod"></svg>
          </div>
 
        </div>
      </div>
    </div>


  <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel"
    aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">{{ $t('reservesport') }}</h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')" ></i>
        </div>
        <div class="modal-body">

          <img src="../assets/sportslika.png" style="width:100%">
          <p>{{ $t('multi1') }}</p>

          <img src="../assets/1.svg" class="brojevi" style="width:35px;height:35px;cursor: pointer;" id="teren1"
            @click="selectfield(1)">
          <img src="../assets/2.svg" class="brojevi" id="teren2" @click="selectfield(2)"
            style="widht:35px;height:35px;padding-left: 10px;padding-right:10px;cursor: pointer;">
          <img src="../assets/3.svg" class="brojevi" id="teren3" @click="selectfield(3)"
            style="widht:35px;height:35px;cursor: pointer;">
          <br><br>
         
          <p><span>{{ $t('x31') }}</span> </p>
       
     
        </div>
        <div class="modal-footer d-flex justify-content-center">
         <button v-if="prijava" type="button" :disabled="buttonDisabled" @click="rezerviraj" class="btn btn-info f-left modalbutton" style="border:none;">Submit</button>

        <div style="padding-top:120px;" class="prazanprostor"></div>

          
        </div>
      </div>
    </div>
  </div>


</template>
      
<style scoped>
.hero-animated {
  padding: 50px 0 0px;
  min-height: 30vh;
}

section {
  padding: 0px 0;

}

.hero-animated p {
  color: rgba(var(--color-secondary-rgb), .8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 640px) {
  .hero-animated p {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
</style>
      
<script>

import axios from 'axios'
import JsBarcode from 'jsbarcode';
import Navigation from '../components/Navigation.vue'
export default {
  name: 'Info',
  components: {
    Navigation
  },

  data() {
    return {
      prijava:false,
      total: 0,
      datoteke: [],
      rezervacije: [],
      id: "",
      broj: "",

      canceldatum: "",
      cancelsat: "",
      reserveform: {
        terminsat: "",
        datum: new Date().toISOString().substr(0, 10),
        brojevi: [],
        email:"",
        qnt:1,

      },
      platform:"",
      buttonDisabled: false,
      placanje:true,
    }
  },
  mounted() {

    this.platform = Capacitor.getPlatform()
    const preloader = document.querySelector('#preloader');
    this.prijava = this.$store.getters.isAuth;
    if(this.prijava){
      this.mojiTermini()
    }
    this.getSport()
    this.getRadnoVrijeme()

  },

  methods: {
    otvoribarkodform(uid){
      this.$root.otvoriform('barkodform')
          
            const barcodeOptions = {
              format: 'EAN8',
              displayValue: false,
              width: 5,
              height: 150,
              lineColor:"black",

            };
            
            var uidElement = document.getElementById("barkod");
            JsBarcode(uidElement, uid, barcodeOptions);
          },

    async getRadnoVrijeme(){
        await axios
            .get('/mpguest/radnovrijeme')
            .then(response => {
             

              this.placanje = response.data.multi
   
            
            })
            .catch(error => {
             if (error.response.status === 401) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
              this.$router.push('/bye/')
            }
            })
      },

    async togglepolicy(tekst){
          $("#"+tekst).toggle();
        },
    async plati(){
      if(this.total==0){
          this.$root.toastnoti(this.$t('error'), "You haven't selected any field", "greska")
          return;
        }
      if(!document.getElementById('flexCheckDefault').checked){
          this.$root.toastnoti(this.$t('error'), "Confirm sales policy to proceed...", "greska")
          return;
        }
      
          $("#preloader").show();
  
          this.$root.zatvoriform('rezervirajModal')
          if (!this.reserveform.brojevi.length) {
          this.$root.toastnoti(this.$t('error'), this.$t('pickfield'), "greska")
          $("#preloader").hide();
          return;
        }
          axios.post('/placanje/gethashmulti/', this.reserveform)
        .then((res) => {

          
          this.$root.toastnoti(this.$t('success'), "Redirecting...", "uspjeh")
          
          this.openPaymentForm(res.data.hash, res.data.cartid, res.data.total)
          
        
                
          })
        .catch((error) => {
          this.$root.toastnoti(this.$t('error'), this.$t('greska'), "error")
        })

        },
        openPaymentForm(signature, cartid, total) {
          this.total = 0
      const formURL = 'https://formtest.wspay.biz/authorization.aspx';
      const formParams = {
        ShopID: 'MPGUEST',
        ShoppingCartID: cartid,
        Version: '2.0',
        TotalAmount: total,
        Signature: signature,
        ReturnURL: 'https://mpguest.com/uspjeh/'+this.platform+'/multi/'+this.reserveform.qnt,
        CancelURL: 'https://mpguest.com/cancel/'+this.platform,
        ReturnErrorURL: 'https://mpguest.com/error/'+this.platform,
      };

      const form = document.createElement('form');
      form.name = 'pay';
      form.method = 'POST';
      form.action = formURL;

      for (const paramName in formParams) {
        if (formParams.hasOwnProperty(paramName)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = paramName;
          input.value = formParams[paramName];
          form.appendChild(input);
        }
      }

      $("#preloader").hide();
      document.body.appendChild(form);
      form.submit();
    },
    methodThatForcesUpdate() {
      this.mojiTermini()
      this.getSport()
    },
    selectfield(broj) {
      console.log(broj)
      var slika = $('#teren' + broj);
      console.log(slika)
      console.log(slika.css("opacity"))
      
      if (slika.css('opacity') == 0.3) {
        slika.css('opacity', 1);
        this.reserveform.brojevi.push(broj)
        this.total += 13
        console.log("crno")
      } else {
        slika.css('opacity', 0.3);
        this.reserveform.brojevi.pop(broj)
        this.total -= 13
        console.log("plavo")
      }
      



    },
    async cancelpotvrda(id, datum, sat, broj) {
      this.id = id
      this.canceldatum = datum
      this.cancelsat = sat
      this.broj = broj

      this.$root.otvoriform('deleteConfirmModal')

    },
    async rezervirajpotvrda(item, sat) {
      for (var i = 1; i <= 3; i++) {
        if (item.includes(i)) {
          $('#teren' + i).show();
          console.log(i)
          $('#teren' + i).css('opacity', 0.3);
        } else {
          $('#teren' + i).hide();
        }
      }

      this.reserveform.terminsat = sat
    
      this.$root.otvoriform('rezervirajModal')

    },
    moguceodgoditi(datum, sat) {
      const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
      const now = new Date();
      const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));

      if (diffInHrs > 2 && targetDate > now) {
        console.log("trueee")

        return true
      } else {
        console.log("falsee")
        return false
      }
      

    },
    async cancel() {

      var apilink = '/mpguest/odgoditermin/multi/' + this.id + '/' + this.broj
      this.canceldatum = this.canceldatum + " " + this.cancelsat + ":00:00"


      await axios

        .get(apilink)
        .then(response => {
          this.$root.toastnoti(this.$t('success'), this.$t('odgodatermina'), "uspjeh")
          $("#rez" + this.id).remove();
  
          this.$root.zatvoriform('deleteConfirmModal')



        })
        .catch(error => {
          this.$root.toastnoti(this.$t('error'), this.$t('greska'), "uspjeh")

        })

    },
    async rezerviraj() {
      this.buttonDisabled = true

      if (!this.reserveform.brojevi.length) {
        this.$root.toastnoti(this.$t('error'), this.$t('pickfield'), "greska")
        this.buttonDisabled = false
        return;
      }

      axios.post('/mpguest/rezervirajmulti2/', this.reserveform)
        .then((res) => {

          
          this.$root.toastnoti(this.$t('success'), this.$t('reserved'), "uspjeh")
          const ids = res.data.ids
          
          
          for (var i = 0; i < res.data.ids.length; i++) {

            this.rezervacije.push([this.reserveform.terminsat, this.reserveform.datum, ids[i], this.reserveform.brojevi[i], res.data.uids[i]])
            this.datoteke[this.reserveform.terminsat].pop(this.reserveform.brojevi[i])
            $(".brojevi").css('opacity', 0.3);


          }
          $("#termin" + this.reserveform.terminsat).remove(); 
          this.otvoribarkodform(res.data.uids[0])
          
          $('#rezervirajModal').modal('hide')
          this.reserveform.brojevi = []
          this.buttonDisabled = false
          this.total = 0
                
          })
        .catch((error) => {
          console.log(error)
          if(error.response.status==400){
            this.$root.toastnoti(this.$t('error'), error.response.data, "error")
            return;
          }
          this.$root.toastnoti(this.$t('error'), this.$t('greska'), "error")
          this.buttonDisabled = false
        })




    },

    async getSport() {
      this.datoteke = {}
      const inputDate = new Date(this.reserveform.datum); // replace with your input date
      const now = new Date();

      const hasAlreadyPassed = inputDate < now &&
        (inputDate.getFullYear() < now.getFullYear() ||
          (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
          (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));


      if (hasAlreadyPassed) {
        this.$root.toastnoti(this.$t('error'), this.$t('prosaodatum'), "error")
        $("#preloader").hide();
        return false;
      }
      if (!this.reserveform.datum) {
        this.$root.toastnoti(this.$t('error'), this.$t('unesitedatum'), "error")
        $("#preloader").hide();
        return false;
      }
  


      await axios
        .get('/mpguest/sporttermini/multi/' + this.reserveform.datum)
        .then(response => {
          if(response.data.poruka=="ne"){
      
      this.$root.toastnoti(this.$t('error'), "Pick a date before " + response.data.limit, "error")
      this.datoteke = []
      return;

    }
          this.datoteke = response.data.termini
          
          $("#preloader").hide();


        })
        .catch(error => {
          this.$root.toastnoti(this.$t('error'), this.$t('greska'), "greska")


        })

    },
    async mojiTermini() {
      await axios
        .get('/mpguest/mojitermini/multi/')
        .then(response => {
          this.rezervacije = response.data.termini
          console.log(this.rezervacije)
          
          $("#preloader").hide();


        })
        .catch(error => {
          this.$root.toastnoti("Log in", this.$t('loginbitno'), "greska")
          $("#preloader").hide();

        })

    },
  }
}
</script>
    
    
