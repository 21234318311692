<template>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.0.96/css/materialdesignicons.min.css" integrity="sha512-fXnjLwoVZ01NUqS/7G5kAnhXNXat6v7e3M9PhoMHOTARUMCaf5qNO84r5x9AFf5HDzm3rEZD8sb/n6dZ19SzFA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
  
  <Navigation yvisina=15 @reload-parent="methodThatForcesUpdate">

    <template #atag> 
      <a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" id="podsjetnik" @click="otvorizd" ><i class="fa fa-plus-circle" style="color:rgb(14, 162, 189);font-size: 25px;"></i></a>
</template>
    <template #content>
      <div style="padding-top: 90px;"></div>
  <div class="main" >
  
  
  
  <div id="tasks_container" class="container">
  
  <div class="row">
  <div style="color: rgba(var(--color-secondary-rgb),.8);font-size: 14px;" class="text-center"  v-if="reported.zadaci && !reported.zadaci.length" v-html="$t('noreported')"></div><br><br>
  <p class="text-center" style="color: rgba(var(--color-secondary-rgb),.8);font-size: 14px;">If you haven't already, fill in your telephone number at account page so we can get back to you in case of any additional info</p>
                  


  
  <div class="col col-sm-12 col-md-4" v-for="i in reported.zadaci" :id='i.id'>
  <div class="two">
          <div class="d-flex justify-content-end px-3 pt-1" @click="deletemodal(i.id)"><i class="mdi mdi-trash-can pr-1 star" id="izbrisi" style="cursor:pointer;"></i></div>
          <div class="px-3"><div class="round"><img :src='i.vrsta__img_path' width="23"/></div></div>
          <div class="px-3 pt-3">
            <h3 class="name">{{i.vrsta__eng_ime}}</h3>
            <p class="quote2">{{i.opis}}</p>
          </div>
          <div class="d-flex justify-content-start px-3 align-items-center">
  
            <span class="quote2 pl-2">Status: <template v-if="i.status==0">{{ $t('zdstatus1') }}</template> <template v-if="i.status == 1">{{ $t('zdstatus2') }}</template> <template v-if="i.status == 2">{{ $t('zdstatus3') }}</template> <template v-if="i.status == 3">{{ $t('zdstatus4') }}</template></span>
          </div>
          <div class="d-flex justify-content-between  px-3 align-items-center pb-3">
            <div class="d-flex justify-content-start align-items-center">
            <i class="mdi mdi-calendar-clock date"></i>
            <span class="quote2 pl-2">{{ $t('time') }}: {{new Date(i.vrijemeizrade).toLocaleString('en-US', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false})}}</span>
          </div>
          <div class="d-flex justify-content-end">
            <img src="https://img.icons8.com/bubbles/50/000000/short-curly-hair-girl-musical-notes.png" width="20" class="img1" />
            <img src="https://img.icons8.com/bubbles/50/000000/girl-and-playing-card.png" width="20" class="img2" />
            <img src="https://img.icons8.com/bubbles/50/000000/short-hair-girl-question-mark.png" width="20" class="img3" />
          </div>
          </div>
  
        </div>
  </div>
  
  
  
  
  
  
  </div>	
  </div>
  </div>
  
  <!-------------------->

  </template>
 
  </Navigation>
  
  
  <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 1rem 1rem 0 0;">
        <div class="modal-header">
          <h2 class=" modelheading">{{$t('izbrisi')}}</h2>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>
        </div>
        <div class="modal-body">
          <p>{{$t('izbrisizadatak')}}</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" @click="izbrisiPost" class="btn btn-info f-left modalbutton" style="background-color: #dc3545;">Confirm</button>
        </div>
      </div>
    </div>
  </div>


  
  
  
  <div class="modal fade" id="exampleModal"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog  kreiraj1" role="document">
          <div class="modal-content kreiraj2">
            <div class="modal-header" id="mdheader">
              <h2 class="modelheading" style="color:gray;">{{ $t('reportmodal1') }}</h2>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('exampleModal')" ></i>
  
          </div>

            
            <div class="modal-body " style="overflow-y: auto;">
              
              <ul class="ftco-footer-social p-0 text-center">
               
              </ul>
              <form  v-on:submit.prevent="submitReport" class="signup-form">
      
            
              <label for="email" style="color:gray;">{{ $t('reportmodal2') }} </label><br><div style="height:7px;"></div>
                  <span id="vrsta"><select id="choices-multiple-remove-button" class="inputVrsta" placeholder="Search task type..." v-model="form.vrste">
                      <template v-for="vrsta in reported.vrste" >
                        <option :value="vrsta[4]" v-if="gostportobus(vrsta[6])">{{vrsta[result]}}</option>
                      </template>
                      
           
              
                </select></span><br>
  
  
                <div class="form-group mb-2">
                
                  <label for="email" style="color:gray;">{{$t('reportinfo')}}</label>
                  <textarea rows="15" style="height:70px;" class="form-control" :placeholder="$t('reportmodal3')" v-model="formreport.info"  id="infoinput"  required></textarea>
                 
                </div>
                
                  <br>
                  <p style="color: rgba(var(--color-secondary-rgb),.8);font-size: 14px;">{{$t('reportnapomena')}}</p>
     
                <br>

                <div class="form-check form-switch">
                <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-top:0.25em;"> Enter if im not at home</label>
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="formreport.ulazak">
    
  </div><br>
  
                  <div class="mb-3">

<label for="message" class="form-label" style="color:gray;font-size: .9rem;font-weight: bold;">*Optional Share a photo</label>

<input type="file" class="form-control fileinput"  @change="handleFileChange">
</div><br>
<p v-show=false style="color: rgba(var(--color-secondary-rgb),.8);font-size: 14px;">If you haven't already, fill in your telephone number at account page so we can get back to you in case of any additional info</p>
                  

                <div class="form-group mb-2">
                  <button type="submit" class="form-control submitbtn submit " style="border-radius: 15px;" :disabled="buttonDisabled">{{$t('reportbutton')}}</button>
  
      
           
                </div>
  
              </form>
            </div>
          </div>
        </div>
      </div>
  
  
  <!-------------------------->
  

  
  
  </template>
  
  <style scoped>

  @media only screen and (max-width: 768px) {
  
  .modal-content {
  height: 100%;
  border-radius: 0;
  position:relative;
  }
  
  /*
  #mdheader{
    padding-top: 45px !important;
    
  }
  */
  
  }
  
  
@media only screen and (max-width: 768px) {




.kreiraj2{
      border-radius: 0rem !important;
      height: 100%;
}
.kreiraj1{
  height: 100%;
}
}</style>

  
  
  <script>
  import axios from 'axios'
  import Navigation from '../components/Navigation.vue'
  export default {
    name: 'Report',
    components: {
      Navigation,
    },
  
    data(){
      return{
        formreport:{
          type:"test",
          info:"",
          img:null,
          ulazak:false,
  
        },
        form:{
          id:"",
          vrsta:"task",
          razlog: ""
        },
        reported:[],
        vrste:[],
        pokazatelj:false,
        choices:null,
        buttonDisabled:false,
        result:0,
        gostlokacija:false
      }
    },
  
    methods: {
      methodThatForcesUpdate(){
        this.getTasks()
    },

    handleFileChange(event) {
      this.formreport.img = event.target.files[0];
    },
    gostportobus(portobus){

      if(!portobus){
        return true
      }
      else{
        var num = parseInt(this.gostlokacija, 10); 
        if (num >= 1 && num <= 35) {
    return true;
      } else {
        return false;
      }
          }

    },
     
  
       izbrisiPost(){
    
              
              axios.post('/mpguest/deleteobj/', this.form)
                   .then((res) => {
                        
                        this.reported.zadaci = this.reported.zadaci.filter(obj => obj.id !== this.form.id);
                        $("#deleteConfirmModal").modal('hide');  
                        this.$root.toastnoti(this.$t('success'), this.$t('taskdeleted'), "uspjeh")
                        
                   })
                   .catch((error) => {
             
                   }).finally(() => {
         
                   });
                   
        },
  
  
      deletemodal(id){
        $('#deleteConfirmModal').modal('show') 
        this.form.id = id
      },
  
      async getTasks() {
        var lang = localStorage.getItem('lang')
        if (lang === "en") {
          this.result = 0;
        } else if (lang === "de") {
          this.result = 0;
        } else if (lang === "it") {
          this.result = 0;
        } else if (lang === "hr") {
          this.result = 3;
        }
      
        await axios
          .get('/mpguest/reportedtasks/'+lang)
          .then(response => {
            this.reported = response.data
            console.log(response.data)
            this.gostlokacija = response.data.gostlokacija
            
          
            $("#podsjetnik").css("visibility", "visible");
            preloader.remove();
          })
          .catch(error => {
            
            if (error.response.status === 401) {
              axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
            this.$router.push('/bye/')
          }
          })
      },
  
  
      submitReport(){
        this.buttonDisabled = true

            this.formreport.type = $('#vrsta .choices__item ').attr('data-value')
            console.log(this.formreport)
        
 
              axios.post('/mpguest/report_problem/', this.formreport, {headers: {
        'Content-Type': 'multipart/form-data',
      },})
                   .then((res) => {
                    
                        this.reported.zadaci.unshift({id: res.data.id, vrsta__eng_ime: res.data.vrsta, vrsta__img_path: res.data.img, vrijemeizrade: new Date(), opis: this.formreport.info, status:0})
                         $('#typeinput').val('');
                         $('#infoinput').val('');
                         this.formreport.info = ""
  
                        $("#exampleModal").modal('hide');  
                        this.$root.toastnoti(this.$t('success'), this.$t('kreiranzd'), "uspjeh")
                        this.buttonDisabled = false
                   })
                   .catch((error) => {
                      this.$root.toastnoti(this.$t('error'), this.$t('greska'), "greska")
                      this.buttonDisabled = false
                   }).finally(() => {
                       //Perform action in always
                   });
                   
        },

        otvorizd(){
          
      if(!this.pokazatelj){
  
  this.choices = new Choices('#choices-multiple-remove-button', {
      removeItemButton: true,
      maxItemCount:3,
      searchResultLimit:5,

    }); 
    this.pokazatelj=true

  }
  this.$root.otvoriform('exampleModal')

        }
  
  
    },
    mounted(){
      this.getTasks()
      

  
       $("#noviizbrisi").click(function(){
          
  
       }); 
  
      const preloader = document.querySelector('#preloader');
   
    
      
    },
    beforeDestroy() {

      this.choices.destroy();

  }
  
  
  }
  </script>
  
  
  
  <style>
  
  .fileinput{
  height: 35px !important;
  border: 1px solid #ced4da !important;
  padding-left: 10px !important;
}


  .col-sm-12{
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
  
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
  
  
      padding-bottom:15px;
  }
  
  .modalTask{
   
      /*   font-size: 48px;
      font-weight: 300;
      margin-bottom: 20px;
      color: var(--color-secondary);*/
      font-weight: 600;
      margin-bottom: 15px;
      font-size: 24px;
      color: var(--color-secondary);
      transition: ease-in-out 0.3s;
  }
  #tasks_container{
      width:50%;
  }
   @media (max-width: 992px) {
      .container{
      width:100% !important;
      }
  
      .col-sm-12{
      
      padding-left:40px;
      padding-right:40px;
      padding-bottom:15px;
      }
  
      .two{
          width:100% !important;
      }
  
      .main{
          padding-top:10% !important;
      }
    }
  
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  
  
  .main{
      padding-top:100px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .one{
      width: 270px;
      background-color: #000;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .pic1{
      position: relative;
      right: 22px;
      top: 23px;
  }
  .pic2{
      position: relative;
      left: 25px;
      top: 17px;
  }
  .pic3{
      position: relative;
      right: 13px;
      bottom: 18px;
  }
  .pic4{
      position: relative;
      left: 14px;
      bottom: 21px;
  }
  .project{
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      color: #fd4040;
  }
  .quote{
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
  
  }
  
  
  
  
  .two{
  
      width:100%;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);    
  }
  .star{
      font-size: 20px !important;
      color: #b1b4b7;
  }
  .dot{
      font-size: 20px !important;
      color: #b1b4b7;
  }
  .round{
      border-radius: 50%;
      background-color: #eee;
      width: 33px;
      height: 33px;
      margin-bottom: -10px;
      align-items: center;
      justify-content: center;
  }
  .name{
      font-size: 22px;
      color: #464e56;
      font-weight: 600;
      text-align: left;
  }
  .quote2{
       font-size: 12px;
       font-weight: 500;
       color: #868e94;
       text-align: left;
  }
  .img1{
      position: relative;
      left: 20px;
      z-index: 28;
      border: 0.5px solid #6ebde4;
      border-radius: 50%;
      background-color: #bcd8e6;
  }
  .img2{
      position: relative;
      left: 10px;
      z-index: 29;
      border: 0.5px solid #6ebde4;
      border-radius: 50%;
      background-color: #bcd8e6;
  }
  .img3{
      z-index: 30;  
      border: 0.5px solid #6ebde4;
      border-radius: 50%; 
      background-color: #bcd8e6;
  }
  .task{
       color: #727475;
  }
  .date{
       color: #727475;
  }
  .imgfix{
      align-content: center;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      top: 2px;
  }
  
  
  </style>
  <style scoped>
  
  
  .submitbtn{
      font-weight: 400;
      display: inline-block;
      padding: 10px 28px;
      border-radius: 4px;
      transition: 0.5s;
      color: var(--color-white);
      background: var(--color-primary);
      font-family: var(--font-secondary);
  
  }
  
  
  </style>