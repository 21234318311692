export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to "])},
  "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your stay even better"])},
  "reportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a Task"])},
  "reporttekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the create button on report tab, you will be able to report a task. Our staff will get notified immediately and will reach you as soon as possible"])},
  "infonaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "infotekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the latest information about upcoming events. View all the available activities in the camp and the surrounding area. Check the touristic train schedule and the opening hours of all Camping Resort facilities. Receive notifications from the staff."])},
  "deliverynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliveries"])},
  "deliverytekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose items from restaurants and we will deliver it right to your mobile home. Currently in preparation. Will be available in a short time."])},
  "rezervacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations"])},
  "rezervacijetekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a sport, pick your time and enjoy"])},
  "morenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And More..."])},
  "moretekst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us what you think through surveys or take a look at our souvenirs"])},
  "getaccnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t have your account yet?"])},
  "getaccopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the Get Started tab, fill in the required information to get a username and password. All information is on the check in paper."])},
  "getaccbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "footercontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagess"])},
  "Souvenirs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenirs"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "Restaurnats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurants"])},
  "Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "poruke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "startmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile home"])},
  "startpc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping pitch"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
  "logininfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t have your log in details?"])},
  "downloadare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Area"])},
  "downloadopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information are just a click away"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movie nights, pool parties and more"])},
  "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stations and timetable for touristic train which goes around the camp and to Bale"])},
  "trainnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Train Schedule"])},
  "workingtimenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Times"])},
  "workingtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities opening hours "])},
  "freshmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetable and more information about Fresh market"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map of the camp"])},
  "farewell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See you!"])},
  "farewell_opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like your stay has come to an end.<br>Thank you once again for staying with us and we hope to see you again in the future."])},
  "farewell_gumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "profiledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Details"])},
  "notifikacijenaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New notifications will appear here"])},
  "suveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy souvenirs at the main camp reception or at Paleo Park"])},
  "noreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reported tasks will appear here<br>Something isn't working properly?<br>Create a task for our staff and they will get to you as soon as possible"])},
  "searchjela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name(all categories)..."])},
  "restoranopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free and fast delivery to your mobile home while you enjoy your holiday"])},
  "cartgumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "deliveryinformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Information"])},
  "deliverydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery for"])},
  "Preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing"])},
  "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
  "Taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taken"])},
  "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
  "sportnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multifunctional sport field"])},
  "sportopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a date and choose the best time for you."])},
  "extrainfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "reportnapomena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will take your desired timing into consideration, and will do the best to accommodate your request."])},
  "extrainfosuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra information (e.g time or different location)"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucess!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "loggedsucess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged in"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged out"])},
  "loggeror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect data"])},
  "greska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, there has been an error, try later or contact us"])},
  "profilesave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile information saved"])},
  "incorrectnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if phone number is correct"])},
  "verifynumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your phone number"])},
  "profilerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occured while confirming the phone number"])},
  "krivikod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong code, please repeat or check the phone number"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "availabletimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available times for the chosen date:"])},
  "nemaslobodnog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available times..."])},
  "nemarezerviranog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reserved times..."])},
  "rezervacijesporta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Reservations:"])},
  "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved, see you later"])},
  "prosaodatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date you entered has already passed"])},
  "odgodatermina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation is cancelled"])},
  "zdstatus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
  "zdstatus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taken"])},
  "zdstatus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working on it"])},
  "zdstatus4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "izbrisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "izbrisizadatak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this task?"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a task"])},
  "reportinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
  "reportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to get newsletters on email"])},
  "jelokategorija1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starters"])},
  "jelokategorija2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast"])},
  "jelokategorija3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "jelokategorija4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
  "jelokategorija5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beverage"])},
  "razumijem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Understand"])},
  "extrainfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra information"])},
  "suvenirdetalji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir delivery details"])},
  "nemasuvenira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active delivery, pick souvenirs from the shop"])},
  "taskreported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are on our way"])},
  "checkboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the box"])},
  "dostavavecpostoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your already have an active delivery"])},
  "uspjehsuveniri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your delivery is on its way"])},
  "emptycart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty"])},
  "taskdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task deleted"])},
  "unesitedatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put in the date first"])},
  "breakfast1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal"])},
  "breakfast2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevarage"])},
  "breakfast3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add on"])},
  "cartsuvenir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir cart"])},
  "cancelres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel reservation"])},
  "cancelres2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel it"])},
  "reservesport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve sport pitch"])},
  "reservesport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reserve it"])},
  "tenisnaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenis field"])},
  "tenissport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a date and choose the best time for you."])},
  "main1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starters"])},
  "main2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "main3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
  "main4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevarage"])},
  "credentialsmh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Credentials for mobile home"])},
  "credentialspc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Credentials for camping pitch"])},
  "howcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information can be found on check in paper"])},
  "mh1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile home number *"])},
  "pc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping pitch number *"])},
  "zona1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone *"])},
  "camp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp *"])},
  "od1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date *"])},
  "do1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure date *"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set password or leave it blank to get automatically generated one *"])},
  "submitcred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "logout1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "tel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number *"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "notifikacije": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "notifikacijeopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hear first about exciting events and get news in the resort   "])},
  "reportmodal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a task"])},
  "reportmodal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task type"])},
  "reportmodal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g Desired timing"])},
  "sport1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations can be cancelled up to 2 hours before they start"])},
  "sport2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost can be paid at check out with the rest of your stay"])},
  "multi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one or more fields"])},
  "multi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "kreiranzd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task created"])},
  "surveynaslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])},
  "surveyopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback is important to ensure that we are providing the service you need.<br> We would appreciate if you would take part in a survey about your stay with us."])},
  "s1naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile home stay"])},
  "s1opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us more about your stay in mobile home."])},
  "s2naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping pitch stay"])},
  "s2opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us more about your stay on camping pitch."])},
  "s3naslov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post stay"])},
  "s3opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us more about your stay in general."])},
  "Booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booklet"])},
  "payement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery will start when the payment is received"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "Dodano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal added to cart"])},
  "loginbitno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to make reservations"])},
  "pickfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a field first"])},
  "Maknuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed"])},
  "foodcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food cart"])},
  "emptydeliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your deliveries will appear here. Create one by selecting items from restaurants and following steps in cart"])},
  "deliveryopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also schedule delivery for later"])},
  "meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal"])},
  "bevarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevarage"])},
  "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
  "addons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side dish"])},
  "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starters"])},
  "passwordunos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional* Choose password or get an automatically generated one"])},
  "activity1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "activity2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fun"])},
  "activity3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture"])},
  "activity4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "activity5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "activity6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
  "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiences"])},
  "exploreactivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore activities we offer at Mon Perin and in Bale"])},
  "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more..."])},
  "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less..."])},
  "profil1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation*"])},
  "profil2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
  "bikepaths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bike Paths"])},
  "monperin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Perin"])},
  "bale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bale/Valle"])},
  "paleopark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paleo Park"])},
  "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping - Camping Home and Camping Villa"])},
  "bale1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Bale, it will amaze you with untouched nature, peace, crystal clear sea, but also by numerous events"])},
  "camping1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore our Villas"])},
  "paleopark1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list for Paleo park (free for campsite guests)"])},
  "bikepaths1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bale are a real challenge or adventure for all those who want to enjoy active recreation."])},
  "monperin1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about Mon Perin"])},
  "cijenik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: 13€ per hour<br>Tennis reckets and balls available for rent on site"])},
  "cijenik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: 13€ per hour<br>Equipment available for rent on site"])},
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a time when you want to start the delivery. Depending on the time of the day, only one restaurant will be available"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurants are not working at this time"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick future time"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick a time between 7AM and 9PM"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max days in advance for reservation is 1 day"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in your phone information at profile page first"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may be contacted on the phone number you filled in at profile page."])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will deliver your meal to your mobile home"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No lunch selected in your cart"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No breakfast selected in your cart"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When do you want to start the delivery?"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule for later?"])},
  "salespolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer must make a reservation of the sports field through the appropriate channel, such as a phone call, an internet platform or a personal visit to the sales facility. The reservation of the sports field must be confirmed by the seller or the responsible person in charge of reservations. The buyer undertakes to pay the appropriate amount for the sport reservation field according to the agreed price. Payment methods may include cash payment, card payment or electronic payment according to the seller's rules. The buyer must make payment in advance or within the agreed time before the date of the sports field reservation. In case of lack of payment within the agreed time, the sports field reservation may be canceled .Description of the method and term of product delivery or service provision: The sports field will be available for use on the agreed date and time. The customer will receive a reservation confirmation that will contain accurate information about the date, time and barcode for the opening of the sports field. Delivery costs: In case sports field reservations, delivery costs do not apply because the reservation refers to the use of the space, not the physical delivery of the product. Contract provisions: The reservation of the sports field is considered a valid contractual obligation between the buyer and the seller. The buyer undertakes to comply with the rules and conditions of use of the sports field stated by the seller. General business conditions: General business conditions, if they are an integral part of the contract, apply to the reservation of the sports field and are binding for both parties."])},
  "conversionpolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All payments will be made in Croatian currency. The amount for which your credit card will be charged is obtained by converting the price in another currency into EUR according to the current exchange rate of the Croatian National Bank. When debiting your credit card, the same amount is converted into your local currency according to the exchange rate of the card associations. As a result of this conversion, there is a possibility of a slight difference compared to the original price listed on our website."])},
  "datapolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest undertakes to keep the personal data of customers, in such a way that it collects only necessary, basic data about customers/users that are necessary for the fulfillment of our obligations; informs customers about the use of collected data, regularly gives customers the opportunity to choose the use of their data, including the ability to decide whether or not they want their name removed from lists used for marketing campaigns. All user data is strictly kept and is available only to employees who need this data to perform their work. All employees of Terra Vallis and business partners are responsible for respecting the principles of privacy protection"])},
  "securitypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mp Guest uses Monri WSPay for online payments. Monri WSPay is a secure system for online payments, real-time payments, credit and debit cards and other payment methods. Monri WSPay provides customers and merchants with secure entry and transfer of entered card data, which is confirmed by the PCI DSS certificate that Monri WSPay has. Monri WSPay uses an SSL certificate of 256-bit encryption and TLS 1.2 cryptographic protocol as the highest level of protection when registering and transferring data."])},
  "salespolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales policy"])},
  "conversionpolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion Statement"])},
  "datapolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Privacy Notice"])},
  "securitypolicy1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WSPay Usage Statement"])},
  "tenisopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in, but you can still make reservations for sport fields with online Payment <br>The price per hour for tennis court is 13€"])},
  "multiopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in, but you can still make reservations for sport fields with online Payment<br>The price for 1 pitch per hour is 13€"])},
  "protection1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement about the Protection of Personal Data Transfer"])},
  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection pursuant to the General Data Protection Regulation of the European Parliament and the Council no. 2016/679- Regulation and implementation of the GDPR WSPay, being the processor of authorization and payment made by credit cards, uses personal data as the processor pursuant to the General Data Protection Regulation of the European Parliament and the Council no. 2016/679, and compliant with PCI DSS Level 1 Regulations for data transfers. WSPay uses 256-bit SSL encryption and TLS 1.2 cryptographic protocol as the highest protection standards for data entry and transfer. Personal data used for the purposes of authorization and payment are deemed to be confidential data. The following customer's personal data are necessary to fulfil the Agreement (authorization and payment): Name and Surname E-mail Telephone number Address City Post Code Country Type of credit card Credit card number Expiry date (credit card) CVV number for credit card WSPay does not process or use these personal data except for the purpose of fulfilling the Agreement, the authorization and the payment. WSPay ensures to meet the requirements determined by applicable personal data protection regulations, for the processors of personal data, especially taking all necessary technical, organizational or security measures confirmed by PCI DSS Level 1 certificate."])},
  "salespolicy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Ordering: Breakfast and lunch can be ordered at a given time. Deliveries can be pre-scheduled for a future time. 2. Delivery time: The delivery will be delivered no later than one hour from the delivery start time. If deliveries are scheduled for a future time, we will endeavor to deliver them then. 3. Payment methods: We accept payment by cards (debit/credit) when starting the delivery. It is possible to pay via mobile applications or internet banking. 4. Delivery description: Food will be delivered inside the camp directly to the guest's mobile home. Delivery will be done by our staff. 5. Delivery costs: Delivery costs will be clearly indicated when ordering. In some cases, shipping may be free depending on the minimum order amount. 6. Contract provisions: Each order represents a concluded contract between the customer and the delivery service. The customer accepts the terms of delivery when confirming the order. 7. Buyer's right to terminate the contract: The buyer does not have the right to terminate the contract, if there is an error during delivery, the money will be returned to the buyer."])},
  "x1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delicious food delivered to your accommodation. Sit back and enjoy! Order it now or schedule it at some exact time."])},
  "x2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
  "x3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliveries"])},
  "x10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to  be redirected to our menu!"])},
  "x11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast"])},
  "x4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thinking of a great breakfast for tomorrow? Have it easy by pre-ordering it now! Enjoy!"])},
  "x5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to  be redirected to breakfast box preorder!"])},
  "x6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book your sports session now and get ready to create unforgettable memories with every swing, shot, or goal. Click on this link to secure your preferred time!"])},
  "x7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport Reservations"])},
  "x8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage Reservations"])},
  "x9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let all your worries fade away. We offer a variety of massage types tailored to your needs. To experience the ultimate relaxation, make your reservation here!"])},
  "x12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13€ per hour, will be added to stay total and can be payed at check-out. Some are free<br><span >Can be cancelled up to 2 hours before start. <br>Scroll down to your reservations and click the X button to cancel it</span>"])},
  "x13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our multi-pitch offers a wide range of activities to suit all interests. Badminton, basketball to football and even more await to challenge and entertain you."])},
  "x14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We make it simple and seamless for you to reach out. Ask us anything"])},
  "x15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After a day spent doing sports and having fun, relax with a nice massage. Choose between a relaxing oil massage in the San Polo area<br>Fee can be paid at the spot"])},
  "x16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage tent located in San Polo next to Babaloo bar. After a day spent doing sports and having fun, relax with a nice massage.<br>Fee can be paid at the spot"])},
  "x17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thinking of a great breakfast for tomorrow? Have it easy by pre-ordering it now!"])},
  "x18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast box consists of:"])},
  "x19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preorders for the next morning available every day until"])},
  "x20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery 8am-9am."])},
  "x21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many boxes would you like to order? "])},
  "x22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only cash payments to personnel are available for now."])},
  "x23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking create you confirm to receive "])},
  "x24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["breakfast box"])},
  "x25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tomorrow, on morning of"])},
  "x26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Feel free to contact us in chat for any changes."])},
  "x27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Breakfast Delivery"])},
  "x28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Schedule later button to preorder it for later"])},
  "x29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thinking of a great meal? Have it easy by pre-ordering it now! "])},
  "x30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the"])},
  "x31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down to your reservations and click the barcode icon to get a unique barcode. Use it for entrance"])},
  "x32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast Preorder"])},
  "x33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Delivery"])},
  "x34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch Menu"])},
  "x35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliveries"])},
  "x36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennis"])},
  "x37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi sport field"])},
  "x38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage"])},
  "x39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available appointments:"])},
  "x40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservations:"])},
  "x41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode for entrance"])},
  "x42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13€ will be addedd to your stay total"])},
  "x43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preorder for "])},
  "x44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Delivery"])},
  "x45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No availability"])},
  "x46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception"])},
  "freetenis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free for guests"])},
  "myrez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Reservations"])}
}